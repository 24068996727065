/* eslint-disable */
export const asyncRouterMap = [
  {
    path: "/bigData",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "big-data",
    alwaysShow: true,
    meta: {
      title: "big-data",
      icon: "menu-icon el-icon-yrt-kehufenpei",
      menu_Id: 1554,
      menuName: "大数据",
      isOpenWindow: false
    },
    children: [
      {
        path: "/bigData/3d-garden",
        component: () => import("@/views/bigData/3d-garden"),
        name: "bigData-3d-garden",
        meta: {
          title: "bigData-3d-garden",
          icon: "",
          menu_Id: 416,
          menuName: "3D物流园区",
          isOpenWindow: true
        }
      },
      {
        path: "/bigData/storage-performance",
        component: () => import("@/views/bigData/storage-performance"),
        name: "bigData-storage-performance",
        meta: {
          title: "bigData-storage-performance",
          icon: "",
          menu_Id: 1587,
          menuName: "工作绩效表",
          isOpenWindow: true
        }
      },
      {
        path: "/bigData/storage-ai",
        component: () => import("@/views/bigData/storage-ai"),
        name: "bigData-storage-ai",
        meta: {
          title: "bigData-storage-ai",
          icon: "",
          menu_Id: 1589,
          menuName: "智能仓配数据平台",
          isOpenWindow: true
        }
      },
      {
        path: "/bigData/storage-progress",
        component: () => import("@/views/bigData/storage-progress"),
        name: "bigData-storage-progress",
        meta: {
          title: "bigData-storage-progress",
          icon: "",
          menu_Id: 1590,
          menuName: "仓储岗位进度表",
          isOpenWindow: true
        }
      },
      {
        path: "/bigData/tms-runtime",
        component: () => import("@/views/bigData/tms-runtime"),
        name: "bigData-tms-runtime",
        meta: {
          title: "bigData-tms-runtime",
          icon: "",
          menu_Id: 1591,
          menuName: "物流实时监控",
          isOpenWindow: true
        }
      },
      {
        path: "/bigData/tms-playback",
        component: () => import("@/views/bigData/tms-playback"),
        name: "bigData-tms-playback",
        meta: {
          title: "bigData-tms-playback",
          icon: "",
          menu_Id: 1593,
          menuName: "物流轨迹回放",
          isOpenWindow: true
        }
      }
    ]
  },
  {
    path: "/erp",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "erp",
    alwaysShow: true,
    meta: {
      title: "erp",
      icon: "menu-icon el-icon-yrt-cangchuguanli",
      menu_Id: 160,
      menuName: "ERP",
      isOpenWindow: false
    },
    children: [
      {
        path: "/erp/sale",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-sale-base",
        alwaysShow: true,
        meta: {
          title: "erp-sale-base",
          icon: "",
          menu_Id: 406,
          menuName: "销售管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/plan-tob",
            component: () => import("@/views/erp/sale/plan-tob"),
            name: "erp-sale-plan-tob",
            meta: {
              title: "erp-sale-plan-tob",
              icon: "",
              menu_Id: 407,
              menuName: "TOB销售计划",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/plan-toc",
            component: () => import("@/views/erp/sale/plan-toc"),
            name: "erp-sale-plan-toc",
            meta: {
              title: "erp-sale-plan-toc",
              icon: "",
              menu_Id: 408,
              menuName: "TOC销售计划",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-tob",
            component: () => import("@/views/erp/sale/order-tob"),
            name: "erp-sale-order-tob",
            meta: {
              title: "erp-sale-order-tob",
              icon: "",
              menu_Id: 426,
              menuName: "TOB销售单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-audit-charge",
            component: () => import("@/views/erp/sale/order-audit-charge"),
            name: "erp-sale-order-audit-charge",
            meta: {
              title: "erp-sale-order-audit-charge",
              icon: "",
              menu_Id: 428,
              menuName: "经管审核",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-audit-affairs",
            component: () => import("@/views/erp/sale/order-audit-affairs"),
            name: "erp-sale-order-audit-affairs",
            meta: {
              title: "erp-sale-order-audit-affairs",
              icon: "",
              menu_Id: 451,
              menuName: "TOB商务审核",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-audit-finance",
            component: () => import("@/views/erp/sale/order-audit-finance"),
            name: "erp-sale-order-audit-finance",
            meta: {
              title: "erp-sale-order-audit-finance",
              icon: "",
              menu_Id: 457,
              menuName: "TOB财务审核",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/by-order-tob",
            component: () => import("@views/erp/sale/by-order-tob"),
            name: "erp-sale-by-order-tob",
            meta: {
              title: "erp-sale-by-order-tob",
              icon: "",
              menu_Id: 459,
              menuName: "下发TOB出库单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-produce",
            component: () => import("@/views/erp/sale/order-produce"),
            name: "erp-sale-order-produce",
            meta: {
              title: "erp-sale-order-produce",
              icon: "",
              menu_Id: 462,
              menuName: "生成TOB出口单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-detail",
            component: () => import("@views/erp/sale/order-detail"),
            name: "erp-sale-order-detail",
            meta: {
              title: "erp-sale-order-detail",
              icon: "",
              menu_Id: 352,
              menuName: "TOB销售单明细",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/sinosure-order",
            component: () => import("@/views/erp/sale/sinosure-order"),
            name: "erp-sale-sinosure-order",
            meta: {
              title: "erp-sale-sinosure-order",
              icon: "",
              menu_Id: 1843,
              menuName: "信保订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-detail-item",
            component: () => import("@/views/erp/sale/order-detail-item"),
            name: "erp-sale-order-detail-item",
            meta: {
              title: "erp-sale-order-detail-item",
              icon: "",
              menu_Id: 1811,
              menuName: "TOC销售单明细",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/sort-pool",
            component: () => import("@/views/erp/sale/sort-pool"),
            name: "erp-sale-sort-pool",
            meta: {
              title: "erp-sale-sort-pool",
              icon: "",
              menu_Id: 1824,
              menuName: "自动分拣池",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/sale-c",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-sale-c",
        alwaysShow: true,
        meta: {
          title: "erp-sale-c",
          icon: "",
          menu_Id: 197,
          menuName: "TOC订单管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/order-all-toc",
            component: () => import("@/views/erp/sale/order-all-toc"),
            name: "erp-sale-order-all-toc",
            meta: {
              title: "erp-sale-order-all-toc",
              icon: "",
              menu_Id: 198,
              menuName: "订单总表",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-manual-toc",
            component: () => import("@/views/erp/sale/order-manual-toc"),
            name: "erp-sale-order-manual-toc",
            meta: {
              title: "erp-sale-order-manual-toc",
              icon: "",
              menu_Id: 1578,
              menuName: "TOC手工订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-toc",
            component: () => import("@/views/erp/sale/order-toc"),
            name: "erp-sale-order-toc",
            meta: {
              title: "erp-sale-order-toc",
              icon: "",
              menu_Id: 452,
              menuName: "待下发订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-toc-plate",
            component: () => import("@/views/erp/sale/order-toc-plate"),
            name: "erp-sale-order-toc-plate",
            meta: {
              title: "erp-sale-order-toc-plate",
              icon: "",
              menu_Id: 1644,
              menuName: "平台状态回更",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-toc-multi",
            component: () => import("@/views/erp/sale/order-toc-multi"),
            name: "erp-sale-order-toc-multi",
            meta: {
              title: "erp-sale-order-toc-multi",
              icon: "",
              menu_Id: 276,
              menuName: "一买家多订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/platform-delivery-order",
            component: () => import("@/views/erp/sale/platform-delivery-order"),
            name: "erp-sale-platform-delivery-order",
            meta: {
              title: "erp-sale-platform-delivery-order",
              icon: "",
              menu_Id: 347,
              menuName: "平台发货订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/merchant-self-delivery",
            component: () => import("@/views/erp/sale/merchant-self-delivery"),
            name: "erp-sale-merchant-self-delivery",
            meta: {
              title: "erp-sale-merchant-self-delivery",
              icon: "",
              menu_Id: 1585,
              menuName: "商家自发货",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/order",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "null",
        alwaysShow: true,
        meta: {
          title: "null",
          icon: "",
          menu_Id: 1510,
          menuName: "订单处理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/order-message",
            component: () => import("@/views/erp/sale/order-message"),
            name: "erp-sale-order-message",
            meta: {
              title: "erp-sale-order-message",
              icon: "",
              menu_Id: 1513,
              menuName: "留言待处理",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/address-error",
            component: () => import("@/views/erp/sale/address-error"),
            name: "erp-sale-address-error",
            meta: {
              title: "erp-sale-address-error",
              icon: "",
              menu_Id: 1514,
              menuName: "地址错误",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/product-unknown",
            component: () => import("@/views/erp/sale/product-unknown"),
            name: "erp-sale-product-unknown",
            meta: {
              title: "erp-sale-product-unknown",
              icon: "",
              menu_Id: 1516,
              menuName: "商品未知",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/storage-noallot",
            component: () => import("@/views/erp/sale/storage-noallot"),
            name: "erp-sale-storage-noallot",
            meta: {
              title: "erp-sale-storage-noallot",
              icon: "",
              menu_Id: 1518,
              menuName: "无法分配仓库",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/mail-error",
            component: () => import("@/views/erp/sale/mail-error"),
            name: "erp-sale-mail-error",
            meta: {
              title: "erp-sale-mail-error",
              icon: "",
              menu_Id: 1522,
              menuName: "邮寄方式错误",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/human-review",
            component: () => import("@/views/erp/sale/human-review"),
            name: "erp-sale-human-review",
            meta: {
              title: "erp-sale-human-review",
              icon: "",
              menu_Id: 1848,
              menuName: "需人工审核",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-stockout",
            component: () => import("@/views/erp/sale/order-stockout"),
            name: "erp-sale-order-stockout",
            meta: {
              title: "erp-sale-order-stockout",
              icon: "",
              menu_Id: 1855,
              menuName: "缺货订单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/logistics",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "logistics-base",
        alwaysShow: true,
        meta: {
          title: "logistics-base",
          icon: "",
          menu_Id: 434,
          menuName: "物流估价审核",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/order-valuation",
            component: () => import("@/views/erp/sale/order-valuation"),
            name: "erp-sale-order-valuation",
            meta: {
              title: "erp-sale-order-valuation",
              icon: "",
              menu_Id: 225,
              menuName: "销售物流估价",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/sale-valuation",
            component: () => import("@/views/erp/sale/sale-valuation"),
            name: "erp-sale-sale-valuation",
            meta: {
              title: "erp-sale-sale-valuation",
              icon: "",
              menu_Id: 2178,
              menuName: "销售估价导出",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/valuation-order",
            component: () => import("@/views/erp/allocate/valuation-order"),
            name: "erp-allocate-valuation-order",
            meta: {
              title: "erp-allocate-valuation-order",
              icon: "",
              menu_Id: 436,
              menuName: "调拨物流估价",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/order-detail",
            component: () => import("@/views/erp/allocate/order-detail"),
            name: "erp-allocate-order-detail",
            meta: {
              title: "erp-allocate-order-detail",
              icon: "",
              menu_Id: 2179,
              menuName: "调拨出库明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/export",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "export-base",
        alwaysShow: true,
        meta: {
          title: "export-base",
          icon: "",
          menu_Id: 286,
          menuName: "关务管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/export/demand-tob",
            component: () => import("@views/erp/export/demand-tob"),
            name: "erp-export-demand-tob",
            meta: {
              title: "erp-export-demand-tob",
              icon: "",
              menu_Id: 287,
              menuName: "TOB出口需求单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/export/demand",
            component: () => import("@views/erp/export/demand"),
            name: "erp-export-demand",
            meta: {
              title: "erp-export-demand",
              icon: "",
              menu_Id: 435,
              menuName: "调拨出口需求单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/purchase",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-purchase-base",
        alwaysShow: true,
        meta: {
          title: "erp-purchase-base",
          icon: "",
          menu_Id: 281,
          menuName: "采购管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/purchase/budget",
            component: () => import("@/views/erp/purchase/budget"),
            name: "erp-purchase-budget",
            meta: {
              title: "erp-purchase-budget",
              icon: "",
              menu_Id: 2194,
              menuName: "采购预算",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/budget-detail",
            component: () => import("@/views/erp/purchase/budget-detail"),
            name: "erp-purchase-budget-detail",
            meta: {
              title: "erp-purchase-budget-detail",
              icon: "",
              menu_Id: 2195,
              menuName: "预算转采购申请",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/purchase-plan",
            component: () => import("@views/erp/purchase/purchase-plan"),
            name: "erp-purchase-purchase-plan",
            meta: {
              title: "erp-purchase-purchase-plan",
              icon: "",
              menu_Id: 354,
              menuName: "采购计划需求",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/un-plan",
            component: () => import("@views/erp/purchase/un-plan"),
            name: "erp-purchase-un-plan",
            meta: {
              title: "erp-purchase-un-plan",
              icon: "",
              menu_Id: 333,
              menuName: "计划外需求",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/purchase-parts",
            component: () => import("@/views/erp/purchase/purchase-parts"),
            name: "erp-purchase-purchase-parts",
            meta: {
              title: "erp-purchase-purchase-parts",
              icon: "",
              menu_Id: 2191,
              menuName: "配件采购需求",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/plan-submit",
            component: () => import("@views/erp/purchase/plan-submit"),
            name: "erp-purchase-plan-submit",
            meta: {
              title: "erp-purchase-plan-submit",
              icon: "",
              menu_Id: 437,
              menuName: "采购提交",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/plan-detail-submit",
            component: () => import("@/views/erp/purchase/plan-detail-submit"),
            name: "erp-purchase-plan-detail-submit",
            meta: {
              title: "erp-purchase-plan-detail-submit",
              icon: "",
              menu_Id: 1864,
              menuName: "采购明细提交",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/order",
            component: () => import("@views/erp/purchase/order"),
            name: "erp-purchase-order",
            meta: {
              title: "erp-purchase-order",
              icon: "",
              menu_Id: 438,
              menuName: "采购订单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/return",
            component: () => import("@/views/erp/purchase/return"),
            name: "erp-purchase-return",
            meta: {
              title: "erp-purchase-return",
              icon: "",
              menu_Id: 439,
              menuName: "采购退货",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/exchange",
            component: () => import("@/views/erp/purchase/exchange"),
            name: "erp-purchase-exchange",
            meta: {
              title: "erp-purchase-exchange",
              icon: "",
              menu_Id: 443,
              menuName: "采购换货",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/sale-orderdetail-lack",
            component: () => import("@/views/erp/purchase/sale-orderdetail-lack"),
            name: "erp-purchase-sale-orderdetail-lack",
            meta: {
              title: "erp-purchase-sale-orderdetail-lack",
              icon: "",
              menu_Id: 1957,
              menuName: "缺货转采购",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/plan-detail",
            component: () => import("@/views/erp/purchase/plan-detail"),
            name: "erp-purchase-plan-detail",
            meta: {
              title: "erp-purchase-plan-detail",
              icon: "",
              menu_Id: 2355,
              menuName: "采购申请明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/purchase-order-detail",
            component: () => import("@/views/erp/purchase/purchase-order-detail"),
            name: "erp-purchase-purchase-order-detail",
            meta: {
              title: "erp-purchase-purchase-order-detail",
              icon: "",
              menu_Id: 2356,
              menuName: "采购订单明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/promotion",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-promotion-base",
        alwaysShow: true,
        meta: {
          title: "erp-promotion-base",
          icon: "",
          menu_Id: 1870,
          menuName: "营销管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/promotion/promotionmanage/promotion-product",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-product"),
            name: "erp-promotion-promotionmanage-promotion-product",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-product",
              icon: "",
              menu_Id: 1872,
              menuName: "商品促销",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-order",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-order"),
            name: "erp-promotion-promotionmanage-promotion-order",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-order",
              icon: "",
              menu_Id: 1873,
              menuName: "订单促销",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-merge",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-merge"),
            name: "erp-promotion-promotionmanage-promotion-merge",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-merge",
              icon: "",
              menu_Id: 1874,
              menuName: "组合促销",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-freecharge",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-freecharge"),
            name: "erp-promotion-promotionmanage-promotion-freecharge",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-freecharge",
              icon: "",
              menu_Id: 1875,
              menuName: "免费领活动",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-makegroup",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-makegroup"),
            name: "erp-promotion-promotionmanage-promotion-makegroup",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-makegroup",
              icon: "",
              menu_Id: 1876,
              menuName: "拼团活动",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-bargaining",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-bargaining"),
            name: "erp-promotion-promotionmanage-promotion-bargaining",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-bargaining",
              icon: "",
              menu_Id: 1877,
              menuName: "砍价活动",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-flashSale",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-flashSale"),
            name: "erp-promotion-promotionmanage-promotion-flashSale",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-flashSale",
              icon: "",
              menu_Id: 1878,
              menuName: "限时特卖活动",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-cilentvip",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-cilentvip"),
            name: "erp-promotion-promotionmanage-promotion-cilentvip",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-cilentvip",
              icon: "",
              menu_Id: 1881,
              menuName: "C端会员",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-cilentvip-b",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-cilentvip-b"),
            name: "erp-promotion-promotionmanage-promotion-cilentvip-b",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-cilentvip-b",
              icon: "",
              menu_Id: 432,
              menuName: "B端会员",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-integral",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-integral"),
            name: "erp-promotion-promotionmanage-promotion-integral",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-integral",
              icon: "",
              menu_Id: 1882,
              menuName: "积分",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-turntable",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-turntable"),
            name: "erp-promotion-promotionmanage-promotion-turntable",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-turntable",
              icon: "",
              menu_Id: 1883,
              menuName: "大转盘",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-coupon",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-coupon"),
            name: "erp-promotion-promotionmanage-promotion-coupon",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-coupon",
              icon: "",
              menu_Id: 1884,
              menuName: "优惠券",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-advertise",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-advertise"),
            name: "erp-promotion-promotionmanage-promotion-advertise",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-advertise",
              icon: "",
              menu_Id: 1885,
              menuName: "广告发布",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-notice",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-notice"),
            name: "erp-promotion-promotionmanage-promotion-notice",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-notice",
              icon: "",
              menu_Id: 1886,
              menuName: "通知公告",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-recharge",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-recharge"),
            name: "erp-promotion-promotionmanage-promotion-recharge",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-recharge",
              icon: "",
              menu_Id: 1887,
              menuName: "短信充值",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/promotion/promotionmanage/promotion-noticeSetup",
            component: () => import("@/views/erp/promotion/promotionmanage/promotion-noticeSetup"),
            name: "erp-promotion-promotionmanage-promotion-noticeSetup",
            meta: {
              title: "erp-promotion-promotionmanage-promotion-noticeSetup",
              icon: "",
              menu_Id: 1888,
              menuName: "通知设置",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/allocate",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-allocate-base",
        alwaysShow: true,
        meta: {
          title: "erp-allocate-base",
          icon: "",
          menu_Id: 444,
          menuName: "调拨管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/allocate/apply",
            component: () => import("@/views/erp/allocate/apply"),
            name: "erp-allocate-apply",
            meta: {
              title: "erp-allocate-apply",
              icon: "",
              menu_Id: 485,
              menuName: "调拨申请单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/apply-export",
            component: () => import("@/views/erp/allocate/apply-export"),
            name: "erp-allocate-apply-export",
            meta: {
              title: "erp-allocate-apply-export",
              icon: "",
              menu_Id: 1674,
              menuName: "生成调拨出口单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/apply-export-detail",
            component: () => import("@views/erp/allocate/apply-export-detail"),
            name: "erp-allocate-apply-export-detail",
            meta: {
              title: "erp-allocate-apply-export-detail",
              icon: "",
              menu_Id: 360,
              menuName: "调拨申请明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/enter",
            component: () => import("@/views/erp/allocate/enter"),
            name: "erp-allocate-enter",
            meta: {
              title: "erp-allocate-enter",
              icon: "",
              menu_Id: 133,
              menuName: "调拨入库单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/order",
            component: () => import("@/views/erp/allocate/order"),
            name: "erp-allocate-order",
            meta: {
              title: "erp-allocate-order",
              icon: "",
              menu_Id: 2198,
              menuName: "调拨出库单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/enter-detail",
            component: () => import("@/views/erp/allocate/enter-detail"),
            name: "erp-allocate-enter-detail",
            meta: {
              title: "erp-allocate-enter-detail",
              icon: "",
              menu_Id: 2357,
              menuName: "调拨入库明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/service-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-service-base",
        alwaysShow: true,
        meta: {
          title: "erp-service-base",
          icon: "",
          menu_Id: 331,
          menuName: "客服中心",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/order-return-cost",
            component: () => import("@/views/erp/sale/order-return-cost"),
            name: "erp-sale-order-return-cost",
            meta: {
              title: "erp-sale-order-return-cost",
              icon: "",
              menu_Id: 2353,
              menuName: "售后费用单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/return-tob",
            component: () => import("@/views/erp/sale/return-tob"),
            name: "erp-sale-return-tob",
            meta: {
              title: "erp-sale-return-tob",
              icon: "",
              menu_Id: 2199,
              menuName: "TOB销售退货",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/return",
            component: () => import("@/views/erp/sale/return"),
            name: "erp-sale-return",
            meta: {
              title: "erp-sale-return",
              icon: "",
              menu_Id: 1715,
              menuName: "销售退货单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/exchange",
            component: () => import("@/views/erp/sale/exchange"),
            name: "erp-sale-exchange",
            meta: {
              title: "erp-sale-exchange",
              icon: "",
              menu_Id: 292,
              menuName: "销售换货单",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/message",
            component: () => import("@/views/api/ebay/message"),
            name: "api-ebay-message",
            meta: {
              title: "api-ebay-message",
              icon: "",
              menu_Id: 1671,
              menuName: "好评",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/message-neutral",
            component: () => import("@/views/api/ebay/message-neutral"),
            name: "api-ebay-message-neutral",
            meta: {
              title: "api-ebay-message-neutral",
              icon: "",
              menu_Id: 2302,
              menuName: "中评",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/message-negative",
            component: () => import("@/views/api/ebay/message-negative"),
            name: "api-ebay-message-negative",
            meta: {
              title: "api-ebay-message-negative",
              icon: "",
              menu_Id: 2303,
              menuName: "差评",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/channel",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "channel-base",
        alwaysShow: true,
        meta: {
          title: "channel-base",
          icon: "",
          menu_Id: 369,
          menuName: "渠道分货管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/channel/cargo",
            component: () => import("@/views/erp/channel/cargo"),
            name: "erp-channel-cargo",
            meta: {
              title: "erp-channel-cargo",
              icon: "",
              menu_Id: 370,
              menuName: "渠道分货管理",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-placeholder-channel",
            component: () => import("@/views/storage/base/product-placeholder-channel"),
            name: "storage-base-product-placeholder-channel",
            meta: {
              title: "storage-base-product-placeholder-channel",
              icon: "",
              menu_Id: 393,
              menuName: "渠道分货查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-channel",
            component: () => import("@/views/storage/base/product-position-channel"),
            name: "storage-base-product-position-channel",
            meta: {
              title: "storage-base-product-position-channel",
              icon: "",
              menu_Id: 394,
              menuName: "渠道库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-area",
            component: () => import("@/views/storage/base/product-position-area"),
            name: "storage-base-product-position-area",
            meta: {
              title: "storage-base-product-position-area",
              icon: "",
              menu_Id: 2181,
              menuName: "区域库存查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-base",
        alwaysShow: true,
        meta: {
          title: "erp-base",
          icon: "",
          menu_Id: 1863,
          menuName: "基础设置",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/base/channel",
            component: () => import("@/views/erp/base/channel"),
            name: "erp-base-channel",
            meta: {
              title: "erp-base-channel",
              icon: "",
              menu_Id: 400,
              menuName: "渠道管理",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/area-manage",
            component: () => import("@/views/erp/base/area-manage"),
            name: "erp-base-area-manage",
            meta: {
              title: "erp-base-area-manage",
              icon: "",
              menu_Id: 2180,
              menuName: "区域管理",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/pick-self-info",
            component: () => import("@/views/erp/base/pick-self-info"),
            name: "erp-base-pick-self-info",
            meta: {
              title: "erp-base-pick-self-info",
              icon: "",
              menu_Id: 2151,
              menuName: "自提点管理",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/channel/store-name",
            component: () => import("@/views/erp/channel/store-name"),
            name: "erp-channel-store-name",
            meta: {
              title: "erp-channel-store-name",
              icon: "",
              menu_Id: 1895,
              menuName: "渠道店铺",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/plat-storeInfo",
            component: () => import("@/views/erp/base/plat-storeInfo"),
            name: "erp-base-plat-storeInfo",
            meta: {
              title: "erp-base-plat-storeInfo",
              icon: "",
              menu_Id: 2310,
              menuName: "未对接平台",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/rate",
            component: () => import("@views/erp/base/rate"),
            name: "erp-base-rate",
            meta: {
              title: "erp-base-rate",
              icon: "",
              menu_Id: 460,
              menuName: "汇率设置",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/order-auto-rule",
            component: () => import("@/views/erp/base/order-auto-rule"),
            name: "erp-base-order-auto-rule",
            meta: {
              title: "erp-base-order-auto-rule",
              icon: "",
              menu_Id: 1861,
              menuName: "订单自动规则",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/dest-Info",
            component: () => import("@/views/erp/base/dest-Info"),
            name: "erp-base-dest-Info",
            meta: {
              title: "erp-base-dest-Info",
              icon: "",
              menu_Id: 2245,
              menuName: "国家地区信息",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/sale-area",
            component: () => import("@/views/erp/base/sale-area"),
            name: "erp-base-sale-area",
            meta: {
              title: "erp-base-sale-area",
              icon: "",
              menu_Id: 2312,
              menuName: "销售区域",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/inbound",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "inbound",
    alwaysShow: true,
    meta: {
      title: "inbound",
      icon: "menu-icon el-icon-yrt-ruku1",
      menu_Id: 101,
      menuName: "收货",
      isOpenWindow: false
    },
    children: [
      {
        path: "purchase",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "purchase-base",
        alwaysShow: true,
        meta: {
          title: "purchase-base",
          icon: "",
          menu_Id: 212,
          menuName: "收货单据",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/quotation",
            component: () => import("@/views/inbound/purchase/quotation"),
            name: "inbound-purchase-quotation",
            meta: {
              title: "inbound-purchase-quotation",
              icon: "",
              menu_Id: 301,
              menuName: "入库计划单",
              isOpenWindow: false
            }
          },
          {
            path: "damaged-order",
            component: () => import("@/views/inbound/purchase/damaged-order"),
            name: "inbound-purchase-damaged-order",
            meta: {
              title: "inbound-purchase-damaged-order",
              icon: "",
              menu_Id: 1603,
              menuName: "残品入库单",
              isOpenWindow: false
            }
          },
          {
            path: "order",
            component: () => import("@/views/inbound/purchase/order"),
            name: "inbound-purchase-order",
            meta: {
              title: "inbound-purchase-order",
              icon: "",
              menu_Id: 102,
              menuName: "预到货单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/stat/orderList-lack",
            component: () => import("@/views/outbound/stat/orderList-lack"),
            name: "outbound-stat-orderList-lack",
            meta: {
              title: "outbound-stat-orderList-lack",
              icon: "",
              menu_Id: 1803,
              menuName: "缺货转预到货",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/adviseqty",
            component: () => import("@/views/inbound/purchase/adviseqty"),
            name: "inbound-purchase-adviseqty",
            meta: {
              title: "inbound-purchase-adviseqty",
              icon: "",
              menu_Id: 1592,
              menuName: "建议采购转预到货",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/import/quotation",
            component: () => import("@/views/inbound/import/quotation"),
            name: "inbound-import-quotation",
            meta: {
              title: "inbound-import-quotation",
              icon: "",
              menu_Id: 200,
              menuName: "入库计划批量导入",
              isOpenWindow: false
            }
          },
          {
            path: "purchase-order-import",
            component: () => import("@/views/inbound/import/purchase-order"),
            name: "inbound-import-purchase-order",
            meta: {
              title: "inbound-import-purchase-order",
              icon: "",
              menu_Id: 265,
              menuName: "预到货批量导入",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "scan-enter",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "scan-enter",
        alwaysShow: true,
        meta: {
          title: "scan-enter",
          icon: "",
          menu_Id: 313,
          menuName: "收货作业",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/check/qualityCheck",
            component: () => import("@/views/inbound/check/qualityCheck"),
            name: "/inbound/scan/qualityCheck",
            meta: {
              title: "/inbound/scan/qualityCheck",
              icon: "",
              menu_Id: 1519,
              menuName: "质检管理",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/check/arrivalProcess",
            component: () => import("@/views/inbound/check/arrivalProcess"),
            name: "/inbound/check/qualityarrivalProcess",
            meta: {
              title: "/inbound/check/qualityarrivalProcess",
              icon: "",
              menu_Id: 1520,
              menuName: "到货加工管理",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/order",
            component: () => import("@/views/inbound/scan/order"),
            name: "inbound-scan-order",
            meta: {
              title: "inbound-scan-order",
              icon: "",
              menu_Id: 1502,
              menuName: "按单扫描入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/order-case",
            component: () => import("@/views/inbound/scan/order-case"),
            name: "inbound-scan-order-case",
            meta: {
              title: "inbound-scan-order-case",
              icon: "",
              menu_Id: 466,
              menuName: "装箱收货入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/inLpn",
            component: () => import("@/views/inbound/scan/inLpn"),
            name: "/inbound/scan/inLpn",
            meta: {
              title: "/inbound/scan/inLpn",
              icon: "",
              menu_Id: 1724,
              menuName: "LPN号扫描入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/inPai",
            component: () => import("@/views/inbound/scan/inPai"),
            name: "/inbound/scan/inPai",
            meta: {
              title: "/inbound/scan/inPai",
              icon: "",
              menu_Id: 180,
              menuName: "按拍扫描入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/order-pai-scan",
            component: () => import("@/views/inbound/scan/order-pai-scan"),
            name: "inbound-scan-order-pai-scan",
            meta: {
              title: "inbound-scan-order-pai-scan",
              icon: "",
              menu_Id: 1685,
              menuName: "按单码盘扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/noBillscan",
            component: () => import("@/views/inbound/scan/noBillscan"),
            name: "inbound-scan-noBillscan",
            meta: {
              title: "inbound-scan-noBillscan",
              icon: "",
              menu_Id: 219,
              menuName: "无单扫描入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/order-light",
            component: () => import("@/views/inbound/scan/order-light"),
            name: "inbound-scan-order-light",
            meta: {
              title: "inbound-scan-order-light",
              icon: "",
              menu_Id: 345,
              menuName: "灯光分拣入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/enter-stacking-scan",
            component: () => import("@/views/inbound/scan/enter-stacking-scan"),
            name: "inbound-scan-enter-stacking-scan",
            meta: {
              title: "inbound-scan-enter-stacking-scan",
              icon: "",
              menu_Id: 125,
              menuName: "码盘扫描入库",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/enter",
            component: () => import("@/views/inbound/purchase/enter"),
            name: "inbound-purchase-enter",
            meta: {
              title: "inbound-purchase-enter",
              icon: "",
              menu_Id: 103,
              menuName: "入库记录查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "scan-shelve",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "scan-shelve",
        alwaysShow: true,
        meta: {
          title: "scan-shelve",
          icon: "",
          menu_Id: 124,
          menuName: "上架作业",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/create-shelve",
            component: () => import("@/views/inbound/purchase/create-shelve"),
            name: "create-shelve",
            meta: {
              title: "create-shelve",
              icon: "",
              menu_Id: 479,
              menuName: "生成上架单",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/shelveLpn",
            component: () => import("@/views/inbound/scan/shelveLpn"),
            name: "/inbound/scan/shelveLpn",
            meta: {
              title: "/inbound/scan/shelveLpn",
              icon: "",
              menu_Id: 1725,
              menuName: "LPN号扫描上架",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/shelve-order",
            component: () => import("@/views/inbound/scan/shelve-order"),
            name: "inbound-scan-shelve-order",
            meta: {
              title: "inbound-scan-shelve-order",
              icon: "",
              menu_Id: 223,
              menuName: "待上架单扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/shelve-stacking-scan",
            component: () => import("@/views/inbound/scan/shelve-stacking-scan"),
            name: "inbound-scan-shelve-stacking-scan",
            meta: {
              title: "inbound-scan-shelve-stacking-scan",
              icon: "",
              menu_Id: 199,
              menuName: "上架码盘扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/shelvePai",
            component: () => import("@/views/inbound/scan/shelvePai"),
            name: "/inbound/scan/shelvePai",
            meta: {
              title: "/inbound/scan/shelvePai",
              icon: "",
              menu_Id: 184,
              menuName: "按拍扫描上架",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/scan/shelve-nobill-scan",
            component: () => import("@/views/inbound/scan/shelve-stacking-scan"),
            name: "inbound-scan-shelve-nobill-scan",
            meta: {
              title: "inbound-scan-shelve-nobill-scan",
              icon: "",
              menu_Id: 218,
              menuName: "无单扫描上架",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/shelve",
            component: () => import("@/views/inbound/purchase/shelve"),
            name: "inbound-purchase-shelve",
            meta: {
              title: "inbound-purchase-shelve",
              icon: "",
              menu_Id: 269,
              menuName: "上架记录查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "return",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "return-base",
        alwaysShow: true,
        meta: {
          title: "return-base",
          icon: "",
          menu_Id: 314,
          menuName: "到货退换货",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/return",
            component: () => import("@/views/inbound/purchase/return"),
            name: "inbound-purchase-return",
            meta: {
              title: "inbound-purchase-return",
              icon: "",
              menu_Id: 104,
              menuName: "到货退货单",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/exchange",
            component: () => import("@/views/inbound/purchase/exchange"),
            name: "inbound-purchase-exchange",
            meta: {
              title: "inbound-purchase-exchange",
              icon: "",
              menu_Id: 105,
              menuName: "到货换货单",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/refuse",
            component: () => import("@/views/inbound/purchase/refuse"),
            name: "inbound-purchase-refuse",
            meta: {
              title: "inbound-purchase-refuse",
              icon: "",
              menu_Id: 2161,
              menuName: "到货拒收单",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/outbound",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "outbound",
    alwaysShow: true,
    meta: {
      title: "outbound",
      icon: "menu-icon el-icon-yrt-chuku1",
      menu_Id: 110,
      menuName: "出库",
      isOpenWindow: false
    },
    children: [
      {
        path: "sale",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "sale-base",
        alwaysShow: true,
        meta: {
          title: "sale-base",
          icon: "",
          menu_Id: 316,
          menuName: "出库单据",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/order/retail",
            component: () => import("@/views/outbound/order/retail"),
            name: "outbound-order-retail",
            meta: {
              title: "outbound-order-retail",
              icon: "",
              menu_Id: 247,
              menuName: "出库订货单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/order/retail-check",
            component: () => import("@/views/outbound/order/retail-check"),
            name: "outbound-order-retail-check",
            meta: {
              title: "outbound-order-retail-check",
              icon: "",
              menu_Id: 1640,
              menuName: "订货单验收",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-plan",
            component: () => import("@/views/outbound/sale/order-plan"),
            name: "outbound-sale-order-plan",
            meta: {
              title: "outbound-sale-order-plan",
              icon: "",
              menu_Id: 302,
              menuName: "出库计划单",
              isOpenWindow: false
            }
          },
          {
            path: "order",
            component: () => import("@/views/outbound/sale/order"),
            name: "outbound-sale-order",
            meta: {
              title: "outbound-sale-order",
              icon: "",
              menu_Id: 112,
              menuName: "出库订单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-send-out",
            component: () => import("@/views/outbound/sale/order-send-out"),
            name: "outbound-sale-order-send-out",
            meta: {
              title: "outbound-sale-order-send-out",
              icon: "",
              menu_Id: 1823,
              menuName: "发货记录",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-toc",
            component: () => import("@/views/outbound/sale/order-toc"),
            name: "outbound-sale-order-toc",
            meta: {
              title: "outbound-sale-order-toc",
              icon: "",
              menu_Id: 2238,
              menuName: "2C发货单",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-order-list-oms",
            component: () => import("@/views/stat/out/vsale-order-list-oms"),
            name: "stat-out-vsale-order-list-oms",
            meta: {
              title: "stat-out-vsale-order-list-oms",
              icon: "",
              menu_Id: 2354,
              menuName: "2C发货单明细",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "operation",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "operation-out",
        alwaysShow: true,
        meta: {
          title: "operation-out",
          icon: "",
          menu_Id: 317,
          menuName: "常规出库操作",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/operation/create-order-batch",
            component: () => import("@/views/outbound/operation/create-order-batch"),
            name: "outbound-operation-create-order-batch",
            meta: {
              title: "outbound-operation-create-order-batch",
              icon: "",
              menu_Id: 380,
              menuName: "生成波次",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/operation/create-order-batch-plan",
            component: () => import("@/views/outbound/operation/create-order-batch-plan"),
            name: "outbound-operation-create-order-batch-plan",
            meta: {
              title: "outbound-operation-create-order-batch-plan",
              icon: "",
              menu_Id: 2149,
              menuName: "波次计划",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/operation/order-batch",
            component: () => import("@/views/outbound/operation/order-batch"),
            name: "outbound-operation-order-batch",
            meta: {
              title: "outbound-operation-order-batch",
              icon: "",
              menu_Id: 248,
              menuName: "波次查询",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/operation/order-printer",
            component: () => import("@/views/outbound/operation/order-printer"),
            name: "outbound-operation-order-printer",
            meta: {
              title: "outbound-operation-order-printer",
              icon: "",
              menu_Id: 491,
              menuName: "单据补打",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/out-light",
            component: () => import("@/views/outbound/scan/out-light"),
            name: "outbound-scan-out-light",
            meta: {
              title: "outbound-scan-out-light",
              icon: "",
              menu_Id: 1735,
              menuName: "灯光校验出库",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-picking",
            component: () => import("@/views/outbound/scan/order-picking"),
            name: "outbound-scan-order-picking",
            meta: {
              title: "outbound-scan-order-picking",
              icon: "",
              menu_Id: 249,
              menuName: "出库拣货下架",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-plate-picking",
            component: () => import("@/views/outbound/scan/order-plate-picking"),
            name: "outbound-scan-order-plate-picking",
            meta: {
              title: "outbound-scan-order-plate-picking",
              icon: "",
              menu_Id: 1643,
              menuName: "出库按拍下架",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-picking-shelve",
            component: () => import("@/views/outbound/scan/order-picking-shelve"),
            name: "outbound-scan-order-picking-shelve",
            meta: {
              title: "outbound-scan-order-picking-shelve",
              icon: "",
              menu_Id: 129,
              menuName: "出库下架回拣",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-matching",
            component: () => import("@/views/outbound/scan/order-matching"),
            name: "outbound-scan-order-matching",
            meta: {
              title: "outbound-scan-order-matching",
              icon: "",
              menu_Id: 250,
              menuName: "出库单常规配货",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-matching-batch",
            component: () => import("@/views/outbound/scan/order-matching-batch"),
            name: "outbound-scan-order-matching-batch",
            meta: {
              title: "outbound-scan-order-matching-batch",
              icon: "",
              menu_Id: 130,
              menuName: "出库单批量配货",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-matching-light",
            component: () => import("@/views/outbound/scan/order-matching-light"),
            name: "outbound-scan-order-matching-light",
            meta: {
              title: "outbound-scan-order-matching-light",
              icon: "",
              menu_Id: 469,
              menuName: "出库单灯光配货",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/out",
            component: () => import("@/views/outbound/scan/out"),
            name: "outbound-scan-out",
            meta: {
              title: "outbound-scan-out",
              icon: "",
              menu_Id: 113,
              menuName: "出库单打包校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/lineOut",
            component: () => import("@/views/outbound/scan/lineOut"),
            name: "outbound-scan-line-out",
            meta: {
              title: "outbound-scan-line-out",
              icon: "",
              menu_Id: 1836,
              menuName: "线路打包校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/out-scan-batch",
            component: () => import("@/views/outbound/scan/out-scan-batch"),
            name: "outbound-scan-out-scan-batch",
            meta: {
              title: "outbound-scan-out-scan-batch",
              icon: "",
              menu_Id: 217,
              menuName: "波次打包校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/no-bill-scan",
            component: () => import("@/views/outbound/scan/no-bill-scan"),
            name: "outbound-scan-no-bill-scan",
            meta: {
              title: "outbound-scan-no-bill-scan",
              icon: "",
              menu_Id: 1751,
              menuName: "无单打包校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/peiout",
            component: () => import("@/views/outbound/scan/peiout"),
            name: "outbound-scan-peiout",
            meta: {
              title: "outbound-scan-peiout",
              icon: "",
              menu_Id: 1814,
              menuName: "配货打包校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/outer",
            component: () => import("@/views/outbound/sale/outer"),
            name: "outbound-sale-outer",
            meta: {
              title: "outbound-sale-outer",
              icon: "",
              menu_Id: 268,
              menuName: "打包校验查询",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-print-pick",
            component: () => import("@/views/outbound/sale/order-print-pick"),
            name: "outbound-sale-order-print-pick",
            meta: {
              title: "outbound-sale-order-print-pick",
              icon: "",
              menu_Id: 1729,
              menuName: "拣配单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/outer-everyDayStatistics",
            component: () => import("@/views/stat/out/outer-everyDayStatistics"),
            name: "outer-everyDayStatistics",
            meta: {
              title: "outer-everyDayStatistics",
              icon: "",
              menu_Id: 1869,
              menuName: "每日打包统计",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "operation-tms",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "operation-tms",
        alwaysShow: true,
        meta: {
          title: "operation-tms",
          icon: "",
          menu_Id: 1835,
          menuName: "装车出库操作",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/plan/create-plan",
            component: () => import("@/views/tms/plan/create-plan"),
            name: "tms-plan-create-plan",
            meta: {
              title: "tms-plan-create-plan",
              icon: "",
              menu_Id: 1624,
              menuName: "生成装车计划单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/operation/create-order-batch-entrucking",
            component: () => import("@/views/outbound/operation/create-order-batch-entrucking"),
            name: "outbound-operation-create-order-batch-entrucking",
            meta: {
              title: "outbound-operation-create-order-batch-entrucking",
              icon: "",
              menu_Id: 2160,
              menuName: "城配装车计划",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-package",
            component: () => import("@/views/outbound/scan/order-package"),
            name: "outbound-scan-order-package",
            meta: {
              title: "outbound-scan-order-package",
              icon: "",
              menu_Id: 2203,
              menuName: "零拣打包扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-package",
            component: () => import("@/views/outbound/sale/order-package"),
            name: "outbound-sale-order-package",
            meta: {
              title: "outbound-sale-order-package",
              icon: "",
              menu_Id: 134,
              menuName: "零拣打包记录",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/operation/create-order-batch-map",
            component: () => import("@/views/outbound/operation/create-order-batch-map"),
            name: "outbound-operation-create-order-batch-map",
            meta: {
              title: "outbound-operation-create-order-batch-map",
              icon: "",
              menu_Id: 449,
              menuName: "地图生成装车计划",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/plan/load-plan",
            component: () => import("@/views/tms/plan/load-plan"),
            name: "tms-load-plan",
            meta: {
              title: "tms-load-plan",
              icon: "",
              menu_Id: 1631,
              menuName: "装车计划单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-plate-group",
            component: () => import("@/views/outbound/scan/order-plate-group"),
            name: "outbound-scan-order-plate-group",
            meta: {
              title: "outbound-scan-order-plate-group",
              icon: "",
              menu_Id: 2204,
              menuName: "组托复核校验",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "send",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "send-base",
        alwaysShow: true,
        meta: {
          title: "send-base",
          icon: "",
          menu_Id: 1506,
          menuName: "发货操作",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/scan/order-send",
            component: () => import("@/views/outbound/scan/order-send"),
            name: "outbound-scan-order-send",
            meta: {
              title: "outbound-scan-order-send",
              icon: "",
              menu_Id: 326,
              menuName: "发货校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-send-fast",
            component: () => import("@/views/outbound/scan/order-send-fast"),
            name: "outbound-scan-order-send-fast",
            meta: {
              title: "outbound-scan-order-send-fast",
              icon: "",
              menu_Id: 425,
              menuName: "快速发货校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/order-send-batch",
            component: () => import("@/views/outbound/scan/order-send-batch"),
            name: "outbound-scan-order-send-batch",
            meta: {
              title: "outbound-scan-order-send-batch",
              icon: "",
              menu_Id: 1508,
              menuName: "闪电发货校验",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/sendlist",
            component: () => import("@/views/outbound/sale/sendlist"),
            name: "outbound-sale-sendlist",
            meta: {
              title: "outbound-sale-sendlist",
              icon: "",
              menu_Id: 325,
              menuName: "发货历史记录",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/manufacture/orderMatching",
            component: () => import("@/views/outbound/manufacture/orderMatching"),
            name: "outbound-manufacture-orderMatching",
            meta: {
              title: "outbound-manufacture-orderMatching",
              icon: "",
              menu_Id: 119,
              menuName: "配货历史记录",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/outWeight-import",
            component: () => import("@/views/outbound/scan/outWeight-import"),
            name: "outbound-scan-outWeight-import",
            meta: {
              title: "outbound-scan-outWeight-import",
              icon: "",
              menu_Id: 1761,
              menuName: "发货重量导入",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/scan/outInformat-import",
            component: () => import("@/views/outbound/scan/outInformat-import"),
            name: "outbound-scan-outInformat-import",
            meta: {
              title: "outbound-scan-outInformat-import",
              icon: "",
              menu_Id: 315,
              menuName: "发货信息导入",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "sale-other",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "sale-other",
        alwaysShow: true,
        meta: {
          title: "sale-other",
          icon: "",
          menu_Id: 1586,
          menuName: "出库其他业务",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/require/goods",
            component: () => import("@/views/outbound/require/goods"),
            name: "outbound-require-goods",
            meta: {
              title: "outbound-require-goods",
              icon: "",
              menu_Id: 1763,
              menuName: "要货管理",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/require/allocation",
            component: () => import("@/views/outbound/require/allocation"),
            name: "outbound-require-allocation",
            meta: {
              title: "outbound-require-allocation",
              icon: "",
              menu_Id: 1742,
              menuName: "调拨单管理",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/require/voucher",
            component: () => import("@/views/outbound/require/voucher"),
            name: "outbound-require-voucher",
            meta: {
              title: "outbound-require-voucher",
              icon: "",
              menu_Id: 1625,
              menuName: "客户购物券",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "service-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "service-base",
        alwaysShow: true,
        meta: {
          title: "service-base",
          icon: "",
          menu_Id: 318,
          menuName: "售后操作",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/service/return",
            component: () => import("@/views/outbound/service/return"),
            name: "outbound-service-return",
            meta: {
              title: "outbound-service-return",
              icon: "",
              menu_Id: 114,
              menuName: "出库退货单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/exchange",
            component: () => import("@/views/outbound/service/exchange"),
            name: "outbound-service-exchange",
            meta: {
              title: "outbound-service-exchange",
              icon: "",
              menu_Id: 115,
              menuName: "出库换货单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/return-apply",
            component: () => import("@/views/outbound/service/return-apply"),
            name: "outbound-service-return-apply",
            meta: {
              title: "outbound-service-return-apply",
              icon: "",
              menu_Id: 1581,
              menuName: "客户售后单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/package",
            component: () => import("@/views/outbound/service/package"),
            name: "outbound-service-package",
            meta: {
              title: "outbound-service-package",
              icon: "",
              menu_Id: 1928,
              menuName: "退货包裹单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/inven-service",
            component: () => import("@/views/outbound/service/inven-service"),
            name: "outbound-service-inven-service",
            meta: {
              title: "outbound-service-inven-service",
              icon: "",
              menu_Id: 1929,
              menuName: "库存维修单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/repair-return",
            component: () => import("@/views/outbound/service/repair-return"),
            name: "outbound-service-repair-return",
            meta: {
              title: "outbound-service-repair-return",
              icon: "",
              menu_Id: 2184,
              menuName: "维修返还单",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/inven-service-detail",
            component: () => import("@/views/outbound/service/inven-service-detail"),
            name: "outbound-service-inven-service-detail",
            meta: {
              title: "outbound-service-inven-service-detail",
              icon: "",
              menu_Id: 2192,
              menuName: "维修明细",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/inven-service-apply",
            component: () => import("@/views/outbound/service/inven-service-apply"),
            name: "outbound-service-inven-service-apply",
            meta: {
              title: "outbound-service-inven-service-apply",
              icon: "",
              menu_Id: 2291,
              menuName: "库存维修申请单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "sale-his",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "sale-his",
        alwaysShow: true,
        meta: {
          title: "sale-his",
          icon: "",
          menu_Id: 339,
          menuName: "历史单据",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/sale/orderPicking",
            component: () => import("@/views/outbound/sale/orderPicking"),
            name: "outbound-sale-orderPicking",
            meta: {
              title: "outbound-sale-orderPicking",
              icon: "",
              menu_Id: 116,
              menuName: "拣货下架记录",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/storage",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "storage",
    alwaysShow: true,
    meta: {
      title: "storage",
      icon: "menu-icon el-icon-yrt-kucunchaxun",
      menu_Id: 135,
      menuName: "仓储",
      isOpenWindow: false
    },
    children: [
      {
        path: "base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "storage-base",
        alwaysShow: true,
        meta: {
          title: "storage-base",
          icon: "",
          menu_Id: 154,
          menuName: "库存状况",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/base/product-position",
            component: () => import("@/views/storage/base/product-position"),
            name: "storage-base-product-position",
            meta: {
              title: "storage-base-product-position",
              icon: "",
              menu_Id: 385,
              menuName: "库存明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-plate",
            component: () => import("@/views/storage/base/product-position-plate"),
            name: "storage-base-product-position-plate",
            meta: {
              title: "storage-base-product-position-plate",
              icon: "",
              menu_Id: 1851,
              menuName: "托盘号库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-sn",
            component: () => import("@/views/storage/base/product-position-sn"),
            name: "storage-base-product-position-sn",
            meta: {
              title: "storage-base-product-position-sn",
              icon: "",
              menu_Id: 1837,
              menuName: "库存SN查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-group",
            component: () => import("@/views/storage/base/product-position-group"),
            name: "storage-base-product-position-group",
            meta: {
              title: "storage-base-product-position-group",
              icon: "",
              menu_Id: 155,
              menuName: "商品库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-name",
            component: () => import("@/views/storage/base/product-position-name"),
            name: "storage-base-product-position-name",
            meta: {
              title: "storage-base-product-position-name",
              icon: "",
              menu_Id: 145,
              menuName: "货位库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-history",
            component: () => import("@/views/storage/base/product-position-history"),
            name: "storage-base-product-position-history",
            meta: {
              title: "storage-base-product-position-history",
              icon: "",
              menu_Id: 271,
              menuName: "库存监测记录",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-placeholder",
            component: () => import("@/views/storage/base/product-placeholder"),
            name: "storage-base-product-placeholder",
            meta: {
              title: "storage-base-product-placeholder",
              icon: "",
              menu_Id: 272,
              menuName: "库存占位查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/position-viewer",
            component: () => import("@/views/storage/setting/position-viewer"),
            name: "storage-setting-position-viewer",
            meta: {
              title: "storage-setting-position-viewer",
              icon: "",
              menu_Id: 1546,
              menuName: "库存可视化查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/vstorage-area",
            component: () => import("@/views/storage/setting/vstorage-area"),
            name: "storage-setting-vstorage-area",
            meta: {
              title: "storage-setting-vstorage-area",
              icon: "",
              menu_Id: 1608,
              menuName: "库容实时查询",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev-tools/x6-viewer-position",
            component: () => import("@/views/sys/dev-tools/x6-designer"),
            name: "sys-dev-tools-x6-viewer-position",
            meta: {
              title: "sys-dev-tools-x6-viewer-position",
              icon: "",
              menu_Id: 1539,
              menuName: "库存可视化查询2.0",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "operation",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "operation",
        alwaysShow: true,
        meta: {
          title: "operation",
          icon: "",
          menu_Id: 117,
          menuName: "库存操作",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/scan/position-transfer",
            component: () => import("@/views/storage/scan/position-transfer"),
            name: "storage-scan-position-transfer",
            meta: {
              title: "storage-scan-position-transfer",
              icon: "",
              menu_Id: 381,
              menuName: "货位转移扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/position-transfer",
            component: () => import("@/views/storage/transfer/position-transfer"),
            name: "storage-transfer-position-transfer",
            meta: {
              title: "storage-transfer-position-transfer",
              icon: "",
              menu_Id: 450,
              menuName: "货位转移查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/create-check",
            component: () => import("@/views/storage/check/create-check"),
            name: "storage-check-create-check",
            meta: {
              title: "storage-check-create-check",
              icon: "",
              menu_Id: 1528,
              menuName: "生成盘点单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/check",
            component: () => import("@/views/storage/check/check"),
            name: "storage-check-check",
            meta: {
              title: "storage-check-check",
              icon: "",
              menu_Id: 147,
              menuName: "库存盘点查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/profit-loss",
            component: () => import("@/views/storage/check/profit-loss"),
            name: "storage-check-profit-loss",
            meta: {
              title: "storage-check-profit-loss",
              icon: "",
              menu_Id: 1565,
              menuName: "盘点盈亏单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/enter",
            component: () => import("@/views/storage/operation/enter"),
            name: "storage-operation-enter",
            meta: {
              title: "storage-operation-enter",
              icon: "",
              menu_Id: 141,
              menuName: "其他入库单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/outer",
            component: () => import("@/views/storage/operation/outer"),
            name: "storage-operation-outer",
            meta: {
              title: "storage-operation-outer",
              icon: "",
              menu_Id: 142,
              menuName: "其他出库单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/purchase-price-adjust",
            component: () => import("@/views/storage/operation/purchase-price-adjust"),
            name: "storage-operation-purchase-price-adjust",
            meta: {
              title: "storage-operation-purchase-price-adjust",
              icon: "",
              menu_Id: 143,
              menuName: "库存成本调整单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/assemble",
            component: () => import("@/views/storage/check/assemble"),
            name: "storage-check-assemble",
            meta: {
              title: "storage-check-assemble",
              icon: "",
              menu_Id: 144,
              menuName: "商品拆装单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/storage-adjust",
            component: () => import("@/views/storage/operation/storage-adjust"),
            name: "storage-operation-storage-adjust",
            meta: {
              title: "storage-operation-storage-adjust",
              icon: "",
              menu_Id: 228,
              menuName: "库存调整",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/consignor-transfer",
            component: () => import("@/views/storage/transfer/consignor-transfer"),
            name: "storage-transfer-consignor-transfer",
            meta: {
              title: "storage-transfer-consignor-transfer",
              icon: "",
              menu_Id: 1686,
              menuName: "货主过户",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/valid-adjust",
            component: () => import("@/views/storage/operation/valid-adjust"),
            name: "storage-operation-valid-adjust",
            meta: {
              title: "storage-operation-valid-adjust",
              icon: "",
              menu_Id: 1688,
              menuName: "效期信息调整",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/adjustSn",
            component: () => import("@/views/storage/transfer/adjustSn"),
            name: "storage-transfer-adjustSn",
            meta: {
              title: "storage-transfer-adjustSn",
              icon: "",
              menu_Id: 2239,
              menuName: "库存SN调整",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "replenishment",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "replenishment",
        alwaysShow: true,
        meta: {
          title: "replenishment",
          icon: "",
          menu_Id: 202,
          menuName: "补货管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/replenishment/vbaseproductInfo-storagelower-replenishment",
            component: () => import("@/views/storage/replenishment/vbaseproductInfo-storagelower-replenishment"),
            name: "vbaseproductInfo-storagelower-replenishment",
            meta: {
              title: "vbaseproductInfo-storagelower-replenishment",
              icon: "",
              menu_Id: 203,
              menuName: "库存下限转补货单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/replenishment/vsale-orderlist-replenishment",
            component: () => import("@/views/storage/replenishment/vsale-orderlist-replenishment"),
            name: "vsale-orderlist-replenishment",
            meta: {
              title: "vsale-orderlist-replenishment",
              icon: "",
              menu_Id: 204,
              menuName: "缺货订单转补货单",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/replenishment/storage-replenishment",
            component: () => import("@/views/storage/replenishment/storage-replenishment"),
            name: "storage-replenishment",
            meta: {
              title: "storage-replenishment",
              icon: "",
              menu_Id: 205,
              menuName: "补货单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/scan/replenishment-scan",
            component: () => import("@/views/storage/scan/replenishment-scan"),
            name: "replenishment-scan",
            meta: {
              title: "replenishment-scan",
              icon: "",
              menu_Id: 206,
              menuName: "补货单扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/replenishment/storage-replenishment-scan",
            component: () => import("@/views/storage/replenishment/storage-replenishment-scan"),
            name: "storage-replenishment-scan",
            meta: {
              title: "storage-replenishment-scan",
              icon: "",
              menu_Id: 207,
              menuName: "补货单扫描记录",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/replenishment/storage-replenishment-sortingrule",
            component: () => import("@/views/storage/replenishment/storage-replenishment-sortingrule"),
            name: "storage-replenishment-sortingrule",
            meta: {
              title: "storage-replenishment-sortingrule",
              icon: "",
              menu_Id: 227,
              menuName: "补货分拣规则",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "allocate",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "allocate",
        alwaysShow: true,
        meta: {
          title: "allocate",
          icon: "",
          menu_Id: 138,
          menuName: "调拨管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "apply",
            component: () => import("@/views/storage/allocate/apply"),
            name: "storage-allocate-apply",
            meta: {
              title: "storage-allocate-apply",
              icon: "",
              menu_Id: 136,
              menuName: "调拨申请单",
              isOpenWindow: false
            }
          },
          {
            path: "enter",
            component: () => import("@/views/storage/allocate/enter"),
            name: "storage-allocate-enter",
            meta: {
              title: "storage-allocate-enter",
              icon: "",
              menu_Id: 137,
              menuName: "调拨入库单",
              isOpenWindow: false
            }
          },
          {
            path: "apply-detail",
            component: () => import("@/views/storage/allocate/apply-detail"),
            name: "storage-allocate-apply-detail",
            meta: {
              title: "storage-allocate-apply-detail",
              icon: "",
              menu_Id: 153,
              menuName: "商品调拨统计",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "borrow",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "borrow",
        alwaysShow: true,
        meta: {
          title: "borrow",
          icon: "",
          menu_Id: 146,
          menuName: "借欠管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "outer",
            component: () => import("@/views/storage/borrow/outer"),
            name: "storage-borrow-outer",
            meta: {
              title: "storage-borrow-outer",
              icon: "",
              menu_Id: 106,
              menuName: "借出单",
              isOpenWindow: false
            }
          },
          {
            path: "outerreturn",
            component: () => import("@/views/storage/borrow/outerreturn"),
            name: "storage-borrow-outerreturn",
            meta: {
              title: "storage-borrow-outerreturn",
              icon: "",
              menu_Id: 118,
              menuName: "借出归还单",
              isOpenWindow: false
            }
          },
          {
            path: "enter",
            component: () => import("@/views/storage/borrow/enter"),
            name: "storage-borrow-enter",
            meta: {
              title: "storage-borrow-enter",
              icon: "",
              menu_Id: 163,
              menuName: "借入单",
              isOpenWindow: false
            }
          },
          {
            path: "enterreturn",
            component: () => import("@/views/storage/borrow/enterreturn"),
            name: "storage-borrow-enterreturn",
            meta: {
              title: "storage-borrow-enterreturn",
              icon: "",
              menu_Id: 166,
              menuName: "借入归还单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/produce",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "produce",
        alwaysShow: true,
        meta: {
          title: "produce",
          icon: "menu-icon el-icon-yrt-cheliangyingxiangshezhi",
          menu_Id: 471,
          menuName: "生产加工",
          isOpenWindow: false
        },
        children: [
          {
            path: "/produce/purchase/order",
            component: () => import("@/views/produce/purchase/order"),
            name: "produce-purchase-order",
            meta: {
              title: "produce-purchase-order",
              icon: "",
              menu_Id: 473,
              menuName: "加工入库单",
              isOpenWindow: false
            }
          },
          {
            path: "/produce/sale/order-stock",
            component: () => import("@/views/produce/sale/order-stock"),
            name: "produce-sale-order-stock",
            meta: {
              title: "produce-sale-order-stock",
              icon: "",
              menu_Id: 1762,
              menuName: "加工出库单",
              isOpenWindow: false
            }
          },
          {
            path: "/produce/produce-day",
            component: () => import("@/views/produce/produce-day"),
            name: "produce-produce-day",
            meta: {
              title: "produce-produce-day",
              icon: "",
              menu_Id: 1845,
              menuName: "加工日报表",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "plate",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "plate",
        alwaysShow: true,
        meta: {
          title: "plate",
          icon: "",
          menu_Id: 195,
          menuName: "容器管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/plate/plate-client",
            component: () => import("@/views/storage/plate/plate-client"),
            name: "storage-plate-plate-client",
            meta: {
              title: "storage-plate-plate-client",
              icon: "",
              menu_Id: 2206,
              menuName: "客户容器管理",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/plate/plate-flow",
            component: () => import("@/views/storage/plate/plate-flow"),
            name: "storage-plate-plate-flow",
            meta: {
              title: "storage-plate-plate-flow",
              icon: "",
              menu_Id: 2207,
              menuName: "容器流水记录",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/plate/plate-out",
            component: () => import("@/views/storage/plate/plate-out"),
            name: "storage-plate-plate-out",
            meta: {
              title: "storage-plate-plate-out",
              icon: "",
              menu_Id: 2208,
              menuName: "容器借出",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/plate/plate-in",
            component: () => import("@/views/storage/plate/plate-in"),
            name: "storage-plate-plate-in",
            meta: {
              title: "storage-plate-plate-in",
              icon: "",
              menu_Id: 2209,
              menuName: "容器归还",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/plate/plate-adjust",
            component: () => import("@/views/storage/plate/plate-adjust"),
            name: "storage-plate-plate-adjust",
            meta: {
              title: "storage-plate-plate-adjust",
              icon: "",
              menu_Id: 2210,
              menuName: "容器调整",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "setting",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "setting-allocate",
        alwaysShow: true,
        meta: {
          title: "setting-allocate",
          icon: "",
          menu_Id: 244,
          menuName: "基础设置",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/base/wharf",
            component: () => import("@/views/storage/base/wharf"),
            name: "storage-base-wharf",
            meta: {
              title: "storage-base-wharf",
              icon: "",
              menu_Id: 431,
              menuName: "码头管理",
              isOpenWindow: false
            }
          },
          {
            path: "storage",
            component: () => import("@/views/storage/setting/storage"),
            name: "storage-setting-storage",
            meta: {
              title: "storage-setting-storage",
              icon: "",
              menu_Id: 258,
              menuName: "仓库信息",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/storage-virtual",
            component: () => import("@/views/storage/setting/storage-virtual"),
            name: "storage-setting-storage-virtual",
            meta: {
              title: "storage-setting-storage-virtual",
              icon: "",
              menu_Id: 2240,
              menuName: "虚拟仓库信息",
              isOpenWindow: false
            }
          },
          {
            path: "storage-area",
            component: () => import("@/views/storage/setting/storage-area"),
            name: "storage-setting-storage-area",
            meta: {
              title: "storage-setting-storage-area",
              icon: "",
              menu_Id: 1561,
              menuName: "库区管理",
              isOpenWindow: false
            }
          },
          {
            path: "position",
            component: () => import("@/views/storage/setting/position"),
            name: "storage-setting-position",
            meta: {
              title: "storage-setting-position",
              icon: "",
              menu_Id: 259,
              menuName: "货位管理",
              isOpenWindow: false
            }
          },
          {
            path: "position-config",
            component: () => import("@/views/storage/setting/position-config"),
            name: "position-config",
            meta: {
              title: "position-config",
              icon: "",
              menu_Id: 1560,
              menuName: "货位定义",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev-tools/x6-designer",
            component: () => import("@/views/sys/dev-tools/x6-designer"),
            name: "sys-dev-tools-x6-designer",
            meta: {
              title: "sys-dev-tools-x6-designer",
              icon: "",
              menu_Id: 433,
              menuName: "库位图设计器",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/plate",
            component: () => import("@/views/storage/setting/plate"),
            name: "storage-setting-plate",
            meta: {
              title: "storage-setting-plate",
              icon: "",
              menu_Id: 1559,
              menuName: "容器管理",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/plate-history",
            component: () => import("@/views/storage/base/plate-history"),
            name: "storage-base-plate-history",
            meta: {
              title: "storage-base-plate-history",
              icon: "",
              menu_Id: 1562,
              menuName: "容器使用轨迹",
              isOpenWindow: false
            }
          },
          {
            path: "shelve-regular",
            component: () => import("@/views/storage/setting/shelve-regular"),
            name: "storage-setting-shelve-regular",
            meta: {
              title: "storage-setting-shelve-regular",
              icon: "",
              menu_Id: 1572,
              menuName: "商品入库上架策略",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "preserve",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "preserve",
        alwaysShow: true,
        meta: {
          title: "preserve",
          icon: "",
          menu_Id: 2163,
          menuName: "养护管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/storage/operation/storage-preserve",
            component: () => import("@/views/storage/operation/storage-preserve"),
            name: "storage-operation-storage-preserve",
            meta: {
              title: "storage-operation-storage-preserve",
              icon: "",
              menu_Id: 2164,
              menuName: "养护单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/vbase-productposition-preserve",
            component: () => import("@/views/storage/operation/vbase-productposition-preserve"),
            name: "storage-operation-vbase-productposition-preserve",
            meta: {
              title: "storage-operation-vbase-productposition-preserve",
              icon: "",
              menu_Id: 2165,
              menuName: "生成养护单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/storage/inventory",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "storage-inventory",
        alwaysShow: true,
        meta: {
          title: "storage-inventory",
          icon: "",
          menu_Id: 2212,
          menuName: "仓储基本信息",
          isOpenWindow: false
        },
        children: [
          {
            path: "/basicInfo/inventory/base_organization",
            component: () => import("@/views/basicInfo/inventory/base_organization"),
            name: "basicInfo-inventory-base_organization",
            meta: {
              title: "basicInfo-inventory-base_organization",
              icon: "",
              menu_Id: 2213,
              menuName: "仓储机构管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/inventory/base_producer",
            component: () => import("@/views/basicInfo/inventory/base_producer"),
            name: "basicInfo-inventory-base_producer",
            meta: {
              title: "basicInfo-inventory-base_producer",
              icon: "",
              menu_Id: 2214,
              menuName: "生产商管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/inventory/base_contract",
            component: () => import("@/views/basicInfo/inventory/base_contract"),
            name: "basicInfo-inventory-base_contract",
            meta: {
              title: "basicInfo-inventory-base_contract",
              icon: "",
              menu_Id: 2215,
              menuName: "合同管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/inventory/base_project",
            component: () => import("@/views/basicInfo/inventory/base_project"),
            name: "basicInfo-inventory-base_project",
            meta: {
              title: "basicInfo-inventory-base_project",
              icon: "",
              menu_Id: 2216,
              menuName: "项目信息管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/inventory/base_outsource",
            component: () => import("@/views/basicInfo/inventory/base_outsource"),
            name: "basicInfo-inventory-base_outsource",
            meta: {
              title: "basicInfo-inventory-base_outsource",
              icon: "",
              menu_Id: 2217,
              menuName: "外包管理",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/cptms",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "cptms",
    alwaysShow: true,
    meta: {
      title: "cptms",
      icon: "menu-icon el-icon-yrt-wuliuguanliicon",
      menu_Id: 126,
      menuName: "TMS",
      isOpenWindow: false
    },
    children: [
      {
        path: "cptms-basic",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "cptms-basic",
        alwaysShow: true,
        meta: {
          title: "cptms-basic",
          icon: "",
          menu_Id: 1766,
          menuName: "基础信息",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/site/tms-line",
            component: () => import("@/views/tms/site/tms-line"),
            name: "tms-site-tms-line",
            meta: {
              title: "tms-site-tms-line",
              icon: "",
              menu_Id: 1799,
              menuName: "线路管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/site/tms-client-line",
            component: () => import("@/views/tms/site/tms-client-line"),
            name: "tms-site-tms-client-line",
            meta: {
              title: "tms-site-tms-client-line",
              icon: "",
              menu_Id: 1813,
              menuName: "客户线路规则",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/consignor/base-consignorStore",
            component: () => import("@/views/basicInfo/consignor/base-consignorStore"),
            name: "basicInfo-consignor-base-consignorStore",
            meta: {
              title: "basicInfo-consignor-base-consignorStore",
              icon: "",
              menu_Id: 1776,
              menuName: "客户门店管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/consignor/base-consignorContract",
            component: () => import("@/views/basicInfo/consignor/base-consignorContract"),
            name: "basicInfo-consignor-base-consignorContract",
            meta: {
              title: "basicInfo-consignor-base-consignorContract",
              icon: "",
              menu_Id: 1626,
              menuName: "客户合同管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/provider/base-providerContract",
            component: () => import("@/views/basicInfo/provider/base-providerContract"),
            name: "basicInfo-provider-base-providerContract",
            meta: {
              title: "basicInfo-provider-base-providerContract",
              icon: "",
              menu_Id: 1783,
              menuName: "供应商合同管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/site/tms-site",
            component: () => import("@/views/tms/site/tms-site"),
            name: "tms-site-tms-site",
            meta: {
              title: "tms-site-tms-site",
              icon: "",
              menu_Id: 1798,
              menuName: "网点管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/consignor/base-consignorStoreAddress",
            component: () => import("@/views/basicInfo/consignor/base-consignorStoreAddress"),
            name: "basicInfo-consignor-base-consignorStoreAddress",
            meta: {
              title: "basicInfo-consignor-base-consignorStoreAddress",
              icon: "",
              menu_Id: 1781,
              menuName: "收货人管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/consignor/base-consignorAddress",
            component: () => import("@/views/basicInfo/consignor/base-consignorAddress"),
            name: "basicInfo-consignor-base-consignorAddress",
            meta: {
              title: "basicInfo-consignor-base-consignorAddress",
              icon: "",
              menu_Id: 1789,
              menuName: "发货人管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/consignor/carrier-contract",
            component: () => import("@/views/basicInfo/consignor/carrier-contract"),
            name: "basicInfo-consignor-carrier-contract",
            meta: {
              title: "basicInfo-consignor-carrier-contract",
              icon: "",
              menu_Id: 1681,
              menuName: "承运商合同管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-carrier",
            component: () => import("@/views/basicInfo/tms/base-carrier"),
            name: "basicInfo-tms-base-carrier",
            meta: {
              title: "basicInfo-tms-base-carrier",
              icon: "",
              menu_Id: 1764,
              menuName: "承运商管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/tms-servicePart",
            component: () => import("@/views/tms/service/tms-servicePart"),
            name: "tms-service-tms-servicePart",
            meta: {
              title: "tms-service-tms-servicePart",
              icon: "",
              menu_Id: 1774,
              menuName: "维修配件管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/base-park",
            component: () => import("@/views/basicInfo/base/base-park"),
            name: "basicInfo-base-base-park",
            meta: {
              title: "basicInfo-base-base-park",
              icon: "",
              menu_Id: 1778,
              menuName: "物流园区",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/storage/base-platform",
            component: () => import("@/views/basicInfo/storage/base-platform"),
            name: "basicInfo-storage-base-platform",
            meta: {
              title: "basicInfo-storage-base-platform",
              icon: "",
              menu_Id: 1779,
              menuName: "月台管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/storage/base-storageLease",
            component: () => import("@/views/basicInfo/storage/base-storageLease"),
            name: "basicInfo-storage-base-storageLease",
            meta: {
              title: "basicInfo-storage-base-storageLease",
              icon: "",
              menu_Id: 1780,
              menuName: "租赁管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "cptms-car",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "cptms-car",
        alwaysShow: true,
        meta: {
          title: "cptms-car",
          icon: "",
          menu_Id: 1768,
          menuName: "司机&车辆",
          isOpenWindow: false
        },
        children: [
          {
            path: "/basicInfo/tms/base-vehicleGroup",
            component: () => import("@/views/basicInfo/tms/base-vehicleGroup"),
            name: "basicInfo-tms-base-vehicleGroup",
            meta: {
              title: "basicInfo-tms-base-vehicleGroup",
              icon: "",
              menu_Id: 193,
              menuName: "车队管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-driver",
            component: () => import("@/views/basicInfo/tms/base-driver"),
            name: "basicInfo-tms-base-driver",
            meta: {
              title: "basicInfo-tms-base-driver",
              icon: "",
              menu_Id: 1627,
              menuName: "司机管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-driverContract",
            component: () => import("@/views/basicInfo/tms/base-driverContract"),
            name: "basicInfo-tms-base-driverContract",
            meta: {
              title: "basicInfo-tms-base-driverContract",
              icon: "",
              menu_Id: 1628,
              menuName: "司机合同管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-vehicle",
            component: () => import("@/views/basicInfo/tms/base-vehicle"),
            name: "basicInfo-tms-base-vehicle",
            meta: {
              title: "basicInfo-tms-base-vehicle",
              icon: "",
              menu_Id: 1629,
              menuName: "车辆管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-driverVehicle",
            component: () => import("@/views/basicInfo/tms/base-driverVehicle"),
            name: "basiclnfo-tms-base-driverVehicle",
            meta: {
              title: "basiclnfo-tms-base-driverVehicle",
              icon: "",
              menu_Id: 1630,
              menuName: "司机车辆绑定",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-trailer",
            component: () => import("@/views/basicInfo/tms/base-trailer"),
            name: "basicInfo-tms-base-trailer",
            meta: {
              title: "basicInfo-tms-base-trailer",
              icon: "",
              menu_Id: 1765,
              menuName: "挂车管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/fee/tms-subsidy",
            component: () => import("@/views/tms/fee/tms-subsidy"),
            name: "tms-fee-tms-subsidy",
            meta: {
              title: "tms-fee-tms-subsidy",
              icon: "",
              menu_Id: 1772,
              menuName: "车辆补贴管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/tms-feedback",
            component: () => import("@/views/tms/service/tms-feedback"),
            name: "tms-service-tms-feedback",
            meta: {
              title: "tms-service-tms-feedback",
              icon: "",
              menu_Id: 1773,
              menuName: "司机反馈",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/tms-vehicleaccess",
            component: () => import("@/views/tms/service/tms-vehicleaccess"),
            name: "tms-service-tms-vehicleaccess",
            meta: {
              title: "tms-service-tms-vehicleaccess",
              icon: "",
              menu_Id: 2150,
              menuName: "车辆出入信息管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-fence",
            component: () => import("@/views/tms/biz/tms-fence"),
            name: "tms-biz-tms-fence",
            meta: {
              title: "tms-biz-tms-fence",
              icon: "",
              menu_Id: 2237,
              menuName: "围栏管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "waybill-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "waybill-base",
        alwaysShow: true,
        meta: {
          title: "waybill-base",
          icon: "",
          menu_Id: 497,
          menuName: "运单中心",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/way/bill",
            component: () => import("@/views/tms/way/bill"),
            name: "tms-way-bill",
            meta: {
              title: "tms-way-bill",
              icon: "",
              menu_Id: 499,
              menuName: "运单管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/vtms-bill-list",
            component: () => import("@/views/tms/way/vtms-bill-list"),
            name: "tms-way-vtms-bill-list",
            meta: {
              title: "tms-way-vtms-bill-list",
              icon: "",
              menu_Id: 298,
              menuName: "运单明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/package-import",
            component: () => import("@/views/tms/way/package-import"),
            name: "tms-way-package-import",
            meta: {
              title: "tms-way-package-import",
              icon: "",
              menu_Id: 1756,
              menuName: "运单更新导入",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/routertask",
            component: () => import("@/views/tms/way/routertask"),
            name: "tms-way-routertask",
            meta: {
              title: "tms-way-routertask",
              icon: "",
              menu_Id: 446,
              menuName: "轨迹作业任务",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-tracking",
            component: () => import("@/views/tms/way/bill-tracking"),
            name: "tms-way-bill-tracking",
            meta: {
              title: "tms-way-bill-tracking",
              icon: "",
              menu_Id: 498,
              menuName: "运单追踪",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/voyage",
            component: () => import("@/views/tms/way/voyage"),
            name: "tms-way-voyage",
            meta: {
              title: "tms-way-voyage",
              icon: "",
              menu_Id: 504,
              menuName: "航单主单号",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/platformInquiry",
            component: () => import("@/views/tms/way/platformInquiry"),
            name: "tms-way-platformInquiry",
            meta: {
              title: "tms-way-platformInquiry",
              icon: "",
              menu_Id: 1784,
              menuName: "月台实时查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "business-tms",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "business-tms",
        alwaysShow: true,
        meta: {
          title: "business-tms",
          icon: "",
          menu_Id: 1797,
          menuName: "提货管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/way/bill-pickup",
            component: () => import("@/views/tms/way/bill-pickup"),
            name: "tms-way-bill-pickup",
            meta: {
              title: "tms-way-bill-pickup",
              icon: "",
              menu_Id: 1733,
              menuName: "提货任务",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-pickup",
            component: () => import("@/views/tms/biz/tms-pickup"),
            name: "tms-biz-tms-pickup",
            meta: {
              title: "tms-biz-tms-pickup",
              icon: "",
              menu_Id: 1800,
              menuName: "提货派车单",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/vtms-bill-list-pickup",
            component: () => import("@/views/tms/way/vtms-bill-list-pickup"),
            name: "tms-way-vtms-bill-list-pickup",
            meta: {
              title: "tms-way-vtms-bill-list-pickup",
              icon: "",
              menu_Id: 1579,
              menuName: "提货按明细派车",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "transport-tms",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "transport-tms",
        alwaysShow: true,
        meta: {
          title: "transport-tms",
          icon: "",
          menu_Id: 242,
          menuName: "干线运输管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/way/bill-shipment",
            component: () => import("@/views/tms/way/bill-shipment"),
            name: "tms-way-bill-shipment",
            meta: {
              title: "tms-way-bill-shipment",
              icon: "",
              menu_Id: 1734,
              menuName: "发货库存",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/vtms-bill-list-shipment",
            component: () => import("@/views/tms/way/vtms-bill-list-shipment"),
            name: "tms-way-vtms-bill-list-shipment",
            meta: {
              title: "tms-way-vtms-bill-list-shipment",
              icon: "",
              menu_Id: 1584,
              menuName: "发货库存明细载配",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-fleet",
            component: () => import("@/views/tms/way/bill-fleet"),
            name: "tms-way-bill-fleet",
            meta: {
              title: "tms-way-bill-fleet",
              icon: "",
              menu_Id: 189,
              menuName: "车队运输任务",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-arrival",
            component: () => import("@/views/tms/way/bill-arrival"),
            name: "tms-way-bill-arrival",
            meta: {
              title: "tms-way-bill-arrival",
              icon: "",
              menu_Id: 1732,
              menuName: "到货库存",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-transport",
            component: () => import("@/views/tms/biz/tms-transport"),
            name: "tms-biz-tms-transport",
            meta: {
              title: "tms-biz-tms-transport",
              icon: "",
              menu_Id: 1801,
              menuName: "干线运输单",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/logistics-calculation",
            component: () => import("@/views/tms/biz/logistics-calculation"),
            name: "tms-biz-logistics-calculation",
            meta: {
              title: "tms-biz-logistics-calculation",
              icon: "",
              menu_Id: 1842,
              menuName: "物流测算单",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/vtms-bill-list-first-delivery",
            component: () => import("@/views/tms/way/vtms-bill-list-first-delivery"),
            name: "tms-way-vtms-bill-list-first-delivery",
            meta: {
              title: "tms-way-vtms-bill-list-first-delivery",
              icon: "",
              menu_Id: 2051,
              menuName: "头程到货明细",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/vtms-transport-detail",
            component: () => import("@/views/tms/biz/vtms-transport-detail"),
            name: "tms-biz-vtms-transport-detail",
            meta: {
              title: "tms-biz-vtms-transport-detail",
              icon: "",
              menu_Id: 2298,
              menuName: "干线运输单明细",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "distribution-biz",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "distribution-biz",
        alwaysShow: true,
        meta: {
          title: "distribution-biz",
          icon: "",
          menu_Id: 1683,
          menuName: "城配管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/way/bill-distribution",
            component: () => import("@/views/tms/way/bill-distribution"),
            name: "tms-way-bill-distribution",
            meta: {
              title: "tms-way-bill-distribution",
              icon: "",
              menu_Id: 230,
              menuName: "配送任务",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-distribution",
            component: () => import("@/views/tms/biz/tms-distribution"),
            name: "tms-biz-tms-distribution",
            meta: {
              title: "tms-biz-tms-distribution",
              icon: "",
              menu_Id: 1802,
              menuName: "配送派车单",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-distribution-account",
            component: () => import("@/views/tms/biz/tms-distribution-account"),
            name: "tms-biz-tms-distribution-account",
            meta: {
              title: "tms-biz-tms-distribution-account",
              icon: "",
              menu_Id: 2166,
              menuName: "回车报账",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/vtms-bill-list-distribution",
            component: () => import("@/views/tms/way/vtms-bill-list-distribution"),
            name: "tms-way-vtms-bill-list-distribution",
            meta: {
              title: "tms-way-vtms-bill-list-distribution",
              icon: "",
              menu_Id: 374,
              menuName: "配送任务明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/scan/send-scan",
            component: () => import("@/views/tms/way/scan/send-scan"),
            name: "tms-way-send-scan",
            meta: {
              title: "tms-way-send-scan",
              icon: "",
              menu_Id: 1818,
              menuName: "配送装车扫描",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/logistics-cost-section",
            component: () => import("@/views/tms/biz/logistics-cost-section"),
            name: "tms-biz-logistics-cost-section",
            meta: {
              title: "tms-biz-logistics-cost-section",
              icon: "",
              menu_Id: 1809,
              menuName: "物流费用明细",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/vtms-waybilllist-detail",
            component: () => import("@/views/tms/biz/vtms-waybilllist-detail"),
            name: "tms-biz-vtms-waybilllist-detail",
            meta: {
              title: "tms-biz-vtms-waybilllist-detail",
              icon: "",
              menu_Id: 1838,
              menuName: "配送明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-scheduling",
            component: () => import("@/views/tms/vehicle/tms-scheduling"),
            name: "tms-vehicle-tms-scheduling",
            meta: {
              title: "tms-vehicle-tms-scheduling",
              icon: "",
              menu_Id: 2224,
              menuName: "配送派车排班",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/vtms-distributiondetail",
            component: () => import("@/views/tms/biz/vtms-distributiondetail"),
            name: "tms-biz-vtms-distributiondetail",
            meta: {
              title: "tms-biz-vtms-distributiondetail",
              icon: "",
              menu_Id: 2276,
              menuName: "运单配送查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "tms-distribution",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "tms-distribution",
        alwaysShow: true,
        meta: {
          title: "tms-distribution",
          icon: "",
          menu_Id: 2246,
          menuName: "应收结算",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/biz/tms-distribution-account-unreceivable",
            component: () => import("@/views/tms/biz/tms-distribution-account-unreceivable"),
            name: "tms-biz-tms-distribution-account-unreceivable",
            meta: {
              title: "tms-biz-tms-distribution-account-unreceivable",
              icon: "",
              menu_Id: 2247,
              menuName: "应收未结算",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-distribution-account-receivable",
            component: () => import("@/views/tms/biz/tms-distribution-account-receivable"),
            name: "tms-biz-tms-distribution-account-receivable",
            meta: {
              title: "tms-biz-tms-distribution-account-receivable",
              icon: "",
              menu_Id: 2248,
              menuName: "应收已结算",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/finance-tmsreceive-statement",
            component: () => import("@/views/tms/biz/finance-tmsreceive-statement"),
            name: "tms-biz-finance-tmsreceive-statement",
            meta: {
              title: "tms-biz-finance-tmsreceive-statement",
              icon: "",
              menu_Id: 2252,
              menuName: "收款核销",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "tms-distribution-p",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "tms-distribution-p",
        alwaysShow: true,
        meta: {
          title: "tms-distribution-p",
          icon: "",
          menu_Id: 2249,
          menuName: "应付结算",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/biz/tms-distribution-account-unpayable",
            component: () => import("@/views/tms/biz/tms-distribution-account-unpayable"),
            name: "tms-biz-tms-distribution-account-unpayable",
            meta: {
              title: "tms-biz-tms-distribution-account-unpayable",
              icon: "",
              menu_Id: 2250,
              menuName: "应付未结算",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-distribution-account-payable",
            component: () => import("@/views/tms/biz/tms-distribution-account-payable"),
            name: "tms-biz-tms-distribution-account-payable",
            meta: {
              title: "tms-biz-tms-distribution-account-payable",
              icon: "",
              menu_Id: 2251,
              menuName: "应付已结算",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/finance-tmspay-statement",
            component: () => import("@/views/tms/biz/finance-tmspay-statement"),
            name: "tms-biz-finance-tmspay-statement",
            meta: {
              title: "tms-biz-finance-tmspay-statement",
              icon: "",
              menu_Id: 2253,
              menuName: "付款核销",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "oil-tms",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "oil-tms",
        alwaysShow: true,
        meta: {
          title: "oil-tms",
          icon: "",
          menu_Id: 280,
          menuName: "油卡管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/oil/tms-oilcard",
            component: () => import("@/views/tms/oil/tms-oilcard"),
            name: "tms-oil-tms-oilcard",
            meta: {
              title: "tms-oil-tms-oilcard",
              icon: "",
              menu_Id: 375,
              menuName: "油卡管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/oil/tms-oilrecharge",
            component: () => import("@/views/tms/oil/tms-oilrecharge"),
            name: "tms-oil-tms-oilrecharge",
            meta: {
              title: "tms-oil-tms-oilrecharge",
              icon: "",
              menu_Id: 376,
              menuName: "油卡充值",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/oil/tms-oilconsume",
            component: () => import("@/views/tms/oil/tms-oilconsume"),
            name: "tms-oil-tms-oilconsume",
            meta: {
              title: "tms-oil-tms-oilconsume",
              icon: "",
              menu_Id: 377,
              menuName: "油卡消费",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/oil/tms-oilsetup",
            component: () => import("@/views/tms/oil/tms-oilsetup"),
            name: "tms-oil-tms-oilsetup",
            meta: {
              title: "tms-oil-tms-oilsetup",
              icon: "",
              menu_Id: 1805,
              menuName: "油卡设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/oil/tms-collectionrecord",
            component: () => import("@/views/tms/oil/tms-collectionrecord"),
            name: "tms-oil-tms-collectionrecord",
            meta: {
              title: "tms-oil-tms-collectionrecord",
              icon: "",
              menu_Id: 1806,
              menuName: "油卡领用记录",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "tms-vehicle",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "tms-vehicle",
        alwaysShow: true,
        meta: {
          title: "tms-vehicle",
          icon: "",
          menu_Id: 2167,
          menuName: "车辆保养维修",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/vehicle/tms-order",
            component: () => import("@/views/tms/vehicle/tms-order"),
            name: "tms-vehicle-tms-order",
            meta: {
              title: "tms-vehicle-tms-order",
              icon: "",
              menu_Id: 2168,
              menuName: "配件入库",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-repair",
            component: () => import("@/views/tms/vehicle/tms-repair"),
            name: "tms-vehicle-tms-repair",
            meta: {
              title: "tms-vehicle-tms-repair",
              icon: "",
              menu_Id: 2171,
              menuName: "维修登记",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-preserve",
            component: () => import("@/views/tms/vehicle/tms-preserve"),
            name: "tms-vehicle-tms-preserve",
            meta: {
              title: "tms-vehicle-tms-preserve",
              icon: "",
              menu_Id: 2172,
              menuName: "保养登记",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-accident",
            component: () => import("@/views/tms/vehicle/tms-accident"),
            name: "tms-vehicle-tms-accident",
            meta: {
              title: "tms-vehicle-tms-accident",
              icon: "",
              menu_Id: 2173,
              menuName: "事故登记",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-accidentamounttransaction",
            component: () => import("@/views/tms/vehicle/tms-accidentamounttransaction"),
            name: "tms-vehicle-tms-accidentamounttransaction",
            meta: {
              title: "tms-vehicle-tms-accidentamounttransaction",
              icon: "",
              menu_Id: 2174,
              menuName: "事故金额往来",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-violation",
            component: () => import("@/views/tms/vehicle/tms-violation"),
            name: "tms-vehicle-tms-violation",
            meta: {
              title: "tms-vehicle-tms-violation",
              icon: "",
              menu_Id: 2175,
              menuName: "违章登记",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-routineexpenses",
            component: () => import("@/views/tms/vehicle/tms-routineexpenses"),
            name: "tms-vehicle-tms-routineexpenses",
            meta: {
              title: "tms-vehicle-tms-routineexpenses",
              icon: "",
              menu_Id: 2176,
              menuName: "常规费用登记",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-preservePlan",
            component: () => import("@/views/tms/vehicle/tms-preservePlan"),
            name: "tms-vehicle-tms-preservePlan",
            meta: {
              title: "tms-vehicle-tms-preservePlan",
              icon: "",
              menu_Id: 2220,
              menuName: "保养计划",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/vehicle/tms-insurance",
            component: () => import("@/views/tms/vehicle/tms-insurance"),
            name: "tms-vehicle-tms-insurance",
            meta: {
              title: "tms-vehicle-tms-insurance",
              icon: "",
              menu_Id: 2223,
              menuName: "保险管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "location-biz",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "location-biz",
        alwaysShow: true,
        meta: {
          title: "location-biz",
          icon: "",
          menu_Id: 1819,
          menuName: "轨迹管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/tmsTrack/tms-alarm",
            component: () => import("@/views/tms/tmsTrack/tms-alarm"),
            name: "tms-tmsTrack-tms-alarm",
            meta: {
              title: "tms-tmsTrack-tms-alarm",
              icon: "",
              menu_Id: 336,
              menuName: "报警管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/map/car-location-map",
            component: () => import("@/views/tms/way/map/car-location-map"),
            name: "car-location-map",
            meta: {
              title: "car-location-map",
              icon: "",
              menu_Id: 1820,
              menuName: "实时定位",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/map/car-trail-map",
            component: () => import("@/views/tms/way/map/car-trail-map"),
            name: "car-trail-map",
            meta: {
              title: "car-trail-map",
              icon: "",
              menu_Id: 1821,
              menuName: "轨迹回放",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/car-trail",
            component: () => import("@/views/tms/way/car-trail"),
            name: "car-trail",
            meta: {
              title: "car-trail",
              icon: "",
              menu_Id: 1826,
              menuName: "轨迹列表",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev-tools/bi-viewer?isviewer=true&id=76",
            component: () => import("@/views/sys/dev-tools/bi-designer/viewer"),
            name: "sys-dev-tools-bi-designer-viewer",
            meta: {
              title: "sys-dev-tools-bi-designer-viewer",
              icon: "",
              menu_Id: 341,
              menuName: "历程统计",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "tms-service-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "tms-service-base",
        alwaysShow: true,
        meta: {
          title: "tms-service-base",
          icon: "",
          menu_Id: 502,
          menuName: "客服管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/oa/sms",
            component: () => import("@/views/tms/oa/sms"),
            name: "tms-oa-sms",
            meta: {
              title: "tms-oa-sms",
              icon: "",
              menu_Id: 464,
              menuName: "短信管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/tms/way-bill-exception",
            component: () => import("@/views/tms/tms/way-bill-exception"),
            name: "tms-tms-way-bill-exception",
            meta: {
              title: "tms-tms-way-bill-exception",
              icon: "",
              menu_Id: 1730,
              menuName: "异常处理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/claim",
            component: () => import("@/views/tms/service/claim"),
            name: "tms-service-claim",
            meta: {
              title: "tms-service-claim",
              icon: "",
              menu_Id: 1731,
              menuName: "理赔处理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "parcelpanel-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "parcelpanel-base",
        alwaysShow: true,
        meta: {
          title: "parcelpanel-base",
          icon: "",
          menu_Id: 239,
          menuName: "包裹组板",
          isOpenWindow: false
        },
        children: [
          {
            path: "/views/tms/panel/sorting-printer",
            component: () => import("@/views/tms/panel/sorting-printer"),
            name: "tms-panel-sorting-printer",
            meta: {
              title: "tms-panel-sorting-printer",
              icon: "",
              menu_Id: 299,
              menuName: "分拣机打印",
              isOpenWindow: false
            }
          },
          {
            path: "/views/tms/panel/sorting-printer-pda",
            component: () => import("@/views/tms/panel/sorting-printer-pda"),
            name: "tms-panel-sorting-printer-pda",
            meta: {
              title: "tms-panel-sorting-printer-pda",
              icon: "",
              menu_Id: 306,
              menuName: "PDA打印机",
              isOpenWindow: false
            }
          },
          {
            path: "/views/tms/panel/pcpanel",
            component: () => import("@/views/tms/panel/pcpanel"),
            name: "tms-panel-pcpanel",
            meta: {
              title: "tms-panel-pcpanel",
              icon: "",
              menu_Id: 1747,
              menuName: "PC组板",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/panel/goodsregion",
            component: () => import("@/views/tms/panel/goodsregion"),
            name: "tms-panel-goodsregion",
            meta: {
              title: "tms-panel-goodsregion",
              icon: "",
              menu_Id: 1748,
              menuName: "落件格口设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/panel/panel",
            component: () => import("@/views/tms/panel/panel"),
            name: "tms-panel-panel",
            meta: {
              title: "tms-panel-panel",
              icon: "",
              menu_Id: 1749,
              menuName: "组板查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/panel/sortingpanellog",
            component: () => import("@/views/tms/panel/sortingpanellog"),
            name: "tms-panel-sortingpanellog",
            meta: {
              title: "tms-panel-sortingpanellog",
              icon: "",
              menu_Id: 1750,
              menuName: "流水线校验结果",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "bill-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "bill-base",
        alwaysShow: true,
        meta: {
          title: "bill-base",
          icon: "",
          menu_Id: 489,
          menuName: "面单管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/way/billother",
            component: () => import("@/views/tms/way/billother"),
            name: "tms-way-billother",
            meta: {
              title: "tms-way-billother",
              icon: "",
              menu_Id: 1757,
              menuName: "报关面单",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-receive",
            component: () => import("@/views/tms/way/bill-receive"),
            name: "tms-way-bill-receive",
            meta: {
              title: "tms-way-bill-receive",
              icon: "",
              menu_Id: 492,
              menuName: "揽收查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/upload",
            component: () => import("@/views/tms/way/upload"),
            name: "tms-way-upload",
            meta: {
              title: "tms-way-upload",
              icon: "",
              menu_Id: 493,
              menuName: "面单上传",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/upload-search",
            component: () => import("@/views/tms/way/upload-search"),
            name: "tms-way-upload-search",
            meta: {
              title: "tms-way-upload-search",
              icon: "tms-way-query-service",
              menu_Id: 191,
              menuName: "面单上传查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-editor",
            component: () => import("@/views/tms/way/bill-editor"),
            name: "tms-way-bill-editor",
            meta: {
              title: "tms-way-bill-editor",
              icon: "",
              menu_Id: 494,
              menuName: "面单录入",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/query-service",
            component: () => import("@/views/tms/way/query-service"),
            name: "tms-way-query-service",
            meta: {
              title: "tms-way-query-service",
              icon: "",
              menu_Id: 1758,
              menuName: "客服录入统计",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "tms-basic",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "tms-basic",
        alwaysShow: true,
        meta: {
          title: "tms-basic",
          icon: "",
          menu_Id: 1737,
          menuName: "跨境基础设置",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/basic/idcard",
            component: () => import("@/views/tms/basic/idcard"),
            name: "tms-basic-idcard",
            meta: {
              title: "tms-basic-idcard",
              icon: "",
              menu_Id: 1736,
              menuName: "身份证库",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/task",
            component: () => import("@/views/tms/basic/task"),
            name: "tms-basic-task",
            meta: {
              title: "tms-basic-task",
              icon: "",
              menu_Id: 503,
              menuName: "作业任务",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/fee-item",
            component: () => import("@/views/tms/basic/fee-item"),
            name: "tms-basic-fee-item",
            meta: {
              title: "tms-basic-fee-item",
              icon: "",
              menu_Id: 1743,
              menuName: "收费项目基础",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/fee-template",
            component: () => import("@/views/tms/basic/fee-template"),
            name: "tms-basic-fee-template",
            meta: {
              title: "tms-basic-fee-template",
              icon: "",
              menu_Id: 1744,
              menuName: "收费模板查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/fee-template-setting",
            component: () => import("@/views/tms/basic/fee-template-setting"),
            name: "tms-basic-fee-template-setting",
            meta: {
              title: "tms-basic-fee-template-setting",
              icon: "",
              menu_Id: 168,
              menuName: "收费模板设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/freight-price",
            component: () => import("@/views/tms/basic/freight-price"),
            name: "tms-basic-freight-price",
            meta: {
              title: "tms-basic-freight-price",
              icon: "",
              menu_Id: 1745,
              menuName: "运费模板查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/freight-price-setting",
            component: () => import("@/views/tms/basic/freight-price-setting"),
            name: "tms-basic-freight-price-setting",
            meta: {
              title: "tms-basic-freight-price-setting",
              icon: "",
              menu_Id: 171,
              menuName: "运费模板设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/abnormal",
            component: () => import("@/views/tms/service/abnormal"),
            name: "tms-service-abnormal",
            meta: {
              title: "tms-service-abnormal",
              icon: "",
              menu_Id: 1738,
              menuName: "异常件维护",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/print-sorting",
            component: () => import("@/views/tms/basic/print-sorting"),
            name: "tms-basic-print-sorting",
            meta: {
              title: "tms-basic-print-sorting",
              icon: "",
              menu_Id: 243,
              menuName: "分拣机打印机设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/pda-print",
            component: () => import("@/views/tms/basic/pda-print"),
            name: "tms-basic-pda-print",
            meta: {
              title: "tms-basic-pda-print",
              icon: "",
              menu_Id: 297,
              menuName: "PDA打印机设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/port-template",
            component: () => import("@/views/tms/basic/port-template"),
            name: "tms-basic-port-template",
            meta: {
              title: "tms-basic-port-template",
              icon: "",
              menu_Id: 1753,
              menuName: "口岸打印模板设置",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/api",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "api",
    alwaysShow: true,
    meta: {
      title: "api",
      icon: "menu-icon el-icon-yrt-huakuai",
      menu_Id: 274,
      menuName: "接口",
      isOpenWindow: false
    },
    children: [
      {
        path: "/wdt",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "layout-base-wdt",
        alwaysShow: true,
        meta: {
          title: "layout-base-wdt",
          icon: "",
          menu_Id: 1660,
          menuName: "旺店通",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/order-wdt",
            component: () => import("@/views/inbound/purchase/order-wdt"),
            name: "inbound-purchase-order-wdt",
            meta: {
              title: "inbound-purchase-order-wdt",
              icon: "",
              menu_Id: 1650,
              menuName: "预到货单",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/enter-wdt",
            component: () => import("@/views/inbound/purchase/enter-wdt"),
            name: "inbound-purchase-enter-wdt",
            meta: {
              title: "inbound-purchase-enter-wdt",
              icon: "",
              menu_Id: 1651,
              menuName: "入库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-wdt",
            component: () => import("@/views/outbound/sale/order-wdt"),
            name: "outbound-sale-order-wdt",
            meta: {
              title: "outbound-sale-order-wdt",
              icon: "",
              menu_Id: 1652,
              menuName: "出库单推送",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/jingdong",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "jingdong",
        alwaysShow: true,
        meta: {
          title: "jingdong",
          icon: "",
          menu_Id: 262,
          menuName: "京东",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/jingdong/storeInfo",
            component: () => import("@/views/api/jingdong/storeInfo"),
            name: "api-jingdong-storeInfo",
            meta: {
              title: "api-jingdong-storeInfo",
              icon: "",
              menu_Id: 263,
              menuName: "京东店铺设置",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "amazon",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "amazon-base",
        alwaysShow: true,
        meta: {
          title: "amazon-base",
          icon: "",
          menu_Id: 388,
          menuName: "Amazon",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/amazon/storeInfo",
            component: () => import("@/views/api/amazon/storeInfo"),
            name: "api-amazon-storeInfo",
            meta: {
              title: "api-amazon-storeInfo",
              icon: "",
              menu_Id: 389,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/product",
            component: () => import("@/views/api/amazon/product"),
            name: "api-amazon-product",
            meta: {
              title: "api-amazon-product",
              icon: "",
              menu_Id: 356,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/saleOrder",
            component: () => import("@views/api/amazon/saleOrder"),
            name: "api-amazon-saleOrder",
            meta: {
              title: "api-amazon-saleOrder",
              icon: "",
              menu_Id: 357,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/return-order",
            component: () => import("@/views/api/amazon/return-order"),
            name: "api-amazon-return-order",
            meta: {
              title: "api-amazon-return-order",
              icon: "",
              menu_Id: 2211,
              menuName: "退货映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/settlement",
            component: () => import("@/views/api/amazon/settlement"),
            name: "api-amazon-settlement",
            meta: {
              title: "api-amazon-settlement",
              icon: "",
              menu_Id: 2225,
              menuName: "结算信息",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/shipment-detail",
            component: () => import("@/views/api/amazon/shipment-detail"),
            name: "api-amazon-shipment-detail",
            meta: {
              title: "api-amazon-shipment-detail",
              icon: "",
              menu_Id: 2231,
              menuName: "移除货件详情",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/suggestion",
            component: () => import("@/views/api/amazon/suggestion"),
            name: "api-amazon-suggestion",
            meta: {
              title: "api-amazon-suggestion",
              icon: "",
              menu_Id: 2232,
              menuName: "建议移除",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/abandoned",
            component: () => import("@/views/api/amazon/abandoned"),
            name: "api-amazon-abandoned",
            meta: {
              title: "api-amazon-abandoned",
              icon: "",
              menu_Id: 2233,
              menuName: "移除订单详情",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/vat-settlement",
            component: () => import("@/views/api/amazon/vat-settlement"),
            name: "api-amazon-vat-settlement",
            meta: {
              title: "api-amazon-vat-settlement",
              icon: "",
              menu_Id: 2236,
              menuName: "VAT结算报告",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/financial",
            component: () => import("@/views/api/amazon/financial"),
            name: "api-amazon-financial",
            meta: {
              title: "api-amazon-financial",
              icon: "",
              menu_Id: 2244,
              menuName: "财务交易报告信息",
              isOpenWindow: false
            }
          },
          {
            path: "/api/amazon/shipment",
            component: () => import("@/views/api/amazon/shipment"),
            name: "api-amazon-shipment",
            meta: {
              title: "api-amazon-shipment",
              icon: "",
              menu_Id: 1619,
              menuName: "装运管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "ebay",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "ebay-base",
        alwaysShow: true,
        meta: {
          title: "ebay-base",
          icon: "",
          menu_Id: 175,
          menuName: "eBay",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/ebay/storeInfo",
            component: () => import("@views/api/ebay/storeInfo"),
            name: "api-ebay-storeInfo",
            meta: {
              title: "api-ebay-storeInfo",
              icon: "",
              menu_Id: 181,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/sku-relation",
            component: () => import("@views/api/ebay/sku-relation"),
            name: "api-ebay-sku-relation",
            meta: {
              title: "api-ebay-sku-relation",
              icon: "",
              menu_Id: 176,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/order-relation",
            component: () => import("@views/api/ebay/order-relation"),
            name: "api-ebay-order-relation",
            meta: {
              title: "api-ebay-order-relation",
              icon: "",
              menu_Id: 279,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/sale-return",
            component: () => import("@/views/api/ebay/sale-return"),
            name: "api-ebay-sale-return",
            meta: {
              title: "api-ebay-sale-return",
              icon: "",
              menu_Id: 2205,
              menuName: "退货映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/settlement",
            component: () => import("@/views/api/ebay/settlement"),
            name: "api-ebay-settlement",
            meta: {
              title: "api-ebay-settlement",
              icon: "",
              menu_Id: 2222,
              menuName: "结算信息",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/member-message",
            component: () => import("@/views/api/ebay/member-message"),
            name: "api-ebay-member-message",
            meta: {
              title: "api-ebay-member-message",
              icon: "",
              menu_Id: 2254,
              menuName: "站内信",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/tidings-model",
            component: () => import("@/views/api/ebay/tidings-model"),
            name: "api-ebay-tidings-model",
            meta: {
              title: "api-ebay-tidings-model",
              icon: "",
              menu_Id: 2255,
              menuName: "消息模板",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/cancellation",
            component: () => import("@/views/api/ebay/cancellation"),
            name: "api-ebay-cancellation",
            meta: {
              title: "api-ebay-cancellation",
              icon: "",
              menu_Id: 2304,
              menuName: "取消交易",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/refund-return",
            component: () => import("@/views/api/ebay/refund-return"),
            name: "api-ebay-refund-return",
            meta: {
              title: "api-ebay-refund-return",
              icon: "",
              menu_Id: 2306,
              menuName: "退货退款",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/inquiry",
            component: () => import("@/views/api/ebay/inquiry"),
            name: "api-ebay-inquiry",
            meta: {
              title: "api-ebay-inquiry",
              icon: "",
              menu_Id: 2307,
              menuName: "未收到物品",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ebay/tidings-model-message",
            component: () => import("@/views/api/ebay/tidings-model-message"),
            name: "api-ebay-tidings-model-message",
            meta: {
              title: "api-ebay-tidings-model-message",
              icon: "",
              menu_Id: 2308,
              menuName: "回评消息模板",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "aliexpress",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "aliexpress-base",
        alwaysShow: true,
        meta: {
          title: "aliexpress-base",
          icon: "",
          menu_Id: 174,
          menuName: "速卖通",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/aliexpress/storeInfo",
            component: () => import("@views/api/aliexpress/storeInfo"),
            name: "api-aliexpress-storeInfo",
            meta: {
              title: "api-aliexpress-storeInfo",
              icon: "",
              menu_Id: 310,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/aliexpress/product",
            component: () => import("@views/api/aliexpress/product"),
            name: "api-aliexpress-product",
            meta: {
              title: "api-aliexpress-product",
              icon: "",
              menu_Id: 332,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/aliexpress/saleOrder",
            component: () => import("@views/api/aliexpress/saleOrder"),
            name: "api-aliexpress-saleOrder",
            meta: {
              title: "api-aliexpress-saleOrder",
              icon: "",
              menu_Id: 338,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/aliexpress/sale-return",
            component: () => import("@/views/api/aliexpress/sale-return"),
            name: "api-aliexpress-sale-return",
            meta: {
              title: "api-aliexpress-sale-return",
              icon: "",
              menu_Id: 2200,
              menuName: "退货映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/aliexpress/settlementInfo",
            component: () => import("@/views/api/aliexpress/settlementInfo"),
            name: "api-aliexpress-settlementInfo",
            meta: {
              title: "api-aliexpress-settlementInfo",
              icon: "",
              menu_Id: 2228,
              menuName: "结算信息",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "kingdee",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "kingdee",
        alwaysShow: true,
        meta: {
          title: "kingdee",
          icon: "",
          menu_Id: 2257,
          menuName: "金蝶",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/dev/param-type/product-storage-group",
            component: () => import("@/views/sys/dev/param-type/product-storage-group"),
            name: "sys-dev-param-type-product-storage-group",
            meta: {
              title: "sys-dev-param-type-product-storage-group",
              icon: "",
              menu_Id: 2258,
              menuName: "仓库分组",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/product-client",
            component: () => import("@/views/sys/dev/param-type/product-client"),
            name: "sys-dev-param-type-product-client",
            meta: {
              title: "sys-dev-param-type-product-client",
              icon: "",
              menu_Id: 2259,
              menuName: "客户分组",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/product-client-type",
            component: () => import("@/views/sys/dev/param-type/product-client-type"),
            name: "sys-dev-param-type-product-client-type",
            meta: {
              title: "sys-dev-param-type-product-client-type",
              icon: "",
              menu_Id: 2260,
              menuName: "客户类别",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "eleven",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "eleven-base",
        alwaysShow: true,
        meta: {
          title: "eleven-base",
          icon: "",
          menu_Id: 1538,
          menuName: "11Street",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/eleven/storeInfo",
            component: () => import("@/views/api/eleven/storeInfo"),
            name: "api-eleven-storeInfo",
            meta: {
              title: "api-eleven-storeInfo",
              icon: "",
              menu_Id: 1542,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/eleven/product",
            component: () => import("@/views/api/eleven/product"),
            name: "api-eleven-product",
            meta: {
              title: "api-eleven-product",
              icon: "",
              menu_Id: 1547,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/eleven/sale-order",
            component: () => import("@/views/api/eleven/sale-order"),
            name: "api-eleven-sale-order",
            meta: {
              title: "api-eleven-sale-order",
              icon: "",
              menu_Id: 1661,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "internal",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "api-internal",
        alwaysShow: true,
        meta: {
          title: "api-internal",
          icon: "",
          menu_Id: 289,
          menuName: "国内物流",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/internal/express-order-route",
            component: () => import("@/views/api/internal/express-order-route"),
            name: "api-internal-express-order-route",
            meta: {
              title: "api-internal-express-order-route",
              icon: "",
              menu_Id: 270,
              menuName: "快递路由查询",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/yt-base-info",
            component: () => import("@/views/api/internal/yt-base-info"),
            name: "api-internal-yt-base-info",
            meta: {
              title: "api-internal-yt-base-info",
              icon: "",
              menu_Id: 1557,
              menuName: "圆通账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/yt-order",
            component: () => import("@/views/api/internal/yt-order"),
            name: "api-internal-yt-order",
            meta: {
              title: "api-internal-yt-order",
              icon: "",
              menu_Id: 1545,
              menuName: "圆通单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/zt-base-info",
            component: () => import("@/views/api/internal/yt-base-info"),
            name: "api-internal-zt-base-info",
            meta: {
              title: "api-internal-zt-base-info",
              icon: "",
              menu_Id: 1558,
              menuName: "中通账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/zt-order",
            component: () => import("@/views/api/internal/zt-order"),
            name: "/api/internal/zt-order",
            meta: {
              title: "/api/internal/zt-order",
              icon: "",
              menu_Id: 1543,
              menuName: "中通单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/sf-base-info",
            component: () => import("@/views/api/internal/sf-base-info"),
            name: "api-internal-sf-base-info",
            meta: {
              title: "api-internal-sf-base-info",
              icon: "",
              menu_Id: 1556,
              menuName: "顺丰账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/sf-order",
            component: () => import("@/views/api/internal/sf-order"),
            name: "api-internal-sf-order",
            meta: {
              title: "api-internal-sf-order",
              icon: "",
              menu_Id: 1526,
              menuName: "顺丰单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/sf-order-filter",
            component: () => import("@/views/api/internal/sf-order-filter"),
            name: "api-internal-sf-order-filter",
            meta: {
              title: "api-internal-sf-order-filter",
              icon: "",
              menu_Id: 1544,
              menuName: "顺丰人工筛单",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/kd100-order-route",
            component: () => import("@/views/api/internal/kd100-order-route"),
            name: "api-internal-kd100-order-route",
            meta: {
              title: "api-internal-kd100-order-route",
              icon: "",
              menu_Id: 1575,
              menuName: "快递100",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/sto-base-info",
            component: () => import("@/views/api/internal/sto-order"),
            name: "api-internal-sto-base-info",
            meta: {
              title: "api-internal-sto-base-info",
              icon: "",
              menu_Id: 220,
              menuName: "申通账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/sto-order",
            component: () => import("@/views/api/internal/sto-order"),
            name: "api-internal-sto-order",
            meta: {
              title: "api-internal-sto-order",
              icon: "",
              menu_Id: 221,
              menuName: "申通订单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/jd-base-info",
            component: () => import("@/views/api/internal/jd-base-info"),
            name: "api-internal-jd-base-info",
            meta: {
              title: "api-internal-jd-base-info",
              icon: "",
              menu_Id: 1891,
              menuName: "京东账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/jd-order",
            component: () => import("@/views/api/internal/jd-order"),
            name: "api-internal-jd-order",
            meta: {
              title: "api-internal-jd-order",
              icon: "",
              menu_Id: 1892,
              menuName: "京东运单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/cn-base-info",
            component: () => import("@/views/api/internal/cn-base-info"),
            name: "api-internal-cn-base-info",
            meta: {
              title: "api-internal-cn-base-info",
              icon: "",
              menu_Id: 470,
              menuName: "菜鸟账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/cn-order",
            component: () => import("@/views/api/internal/cn-order"),
            name: "api-internal-cn-order",
            meta: {
              title: "api-internal-cn-order",
              icon: "",
              menu_Id: 468,
              menuName: "菜鸟运单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/kdniao-order",
            component: () => import("@/views/api/internal/kdniao-order"),
            name: "api-internal-kdniao-order",
            meta: {
              title: "api-internal-kdniao-order",
              icon: "",
              menu_Id: 1899,
              menuName: "快递鸟运单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/kdniao-base-info",
            component: () => import("@/views/api/internal/kdniao-base-info"),
            name: "api-internal-kdniao-base-info",
            meta: {
              title: "api-internal-kdniao-base-info",
              icon: "",
              menu_Id: 1900,
              menuName: "快递鸟快递账号",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/bsht-base-info",
            component: () => import("@/views/api/internal/bsht-base-info"),
            name: "api-internal-bsht-base-info",
            meta: {
              title: "api-internal-bsht-base-info",
              icon: "",
              menu_Id: 1675,
              menuName: "百世账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/bsht-order",
            component: () => import("@/views/api/internal/bsht-order"),
            name: "api-internal-bsht-order",
            meta: {
              title: "api-internal-bsht-order",
              icon: "",
              menu_Id: 1676,
              menuName: "百世单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/ems-order",
            component: () => import("@/views/api/internal/ems-order"),
            name: "api-internal-ems-order",
            meta: {
              title: "api-internal-ems-order",
              icon: "",
              menu_Id: 1755,
              menuName: "EMS单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/aliExpress-base-info",
            component: () => import("@/views/api/internal/aliExpress-base-info"),
            name: "api-internal-aliExpress-base-info",
            meta: {
              title: "api-internal-aliExpress-base-info",
              icon: "",
              menu_Id: 2229,
              menuName: "无忧账号设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/aliExpress-order",
            component: () => import("@/views/api/internal/aliExpress-order"),
            name: "api-internal-aliExpress-order",
            meta: {
              title: "api-internal-aliExpress-order",
              icon: "",
              menu_Id: 2230,
              menuName: "无忧单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/ldg-order",
            component: () => import("@/views/api/internal/ldg-order"),
            name: "api-internal-ldg-order",
            meta: {
              title: "api-internal-ldg-order",
              icon: "",
              menu_Id: 1771,
              menuName: "泉州陆地港单号获取",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/nextsls-base-info",
            component: () => import("@/views/api/internal/nextsls-base-info"),
            name: "api-internal-nextsls-base-info",
            meta: {
              title: "api-internal-nextsls-base-info",
              icon: "",
              menu_Id: 2158,
              menuName: "新智慧账户设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/internal/nextsls-order",
            component: () => import("@/views/api/internal/nextsls-order"),
            name: "api-internal-nextsls-order",
            meta: {
              title: "api-internal-nextsls-order",
              icon: "",
              menu_Id: 2159,
              menuName: "新智慧订单推送",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Otto",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Otto-base",
        alwaysShow: true,
        meta: {
          title: "Otto-base",
          icon: "",
          menu_Id: 402,
          menuName: "Otto",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/otto/storeInfo",
            component: () => import("@/views/api/otto/storeInfo"),
            name: "api-otto-storeInfo",
            meta: {
              title: "api-otto-storeInfo",
              icon: "",
              menu_Id: 405,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/product",
            component: () => import("@/views/api/otto/product"),
            name: "api-otto-product",
            meta: {
              title: "api-otto-product",
              icon: "",
              menu_Id: 403,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/sale-order",
            component: () => import("@/views/api/otto/sale-order"),
            name: "api-otto-sale-order",
            meta: {
              title: "api-otto-sale-order",
              icon: "",
              menu_Id: 404,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/sale-return",
            component: () => import("@/views/api/otto/sale-return"),
            name: "api-otto-sale-return",
            meta: {
              title: "api-otto-sale-return",
              icon: "",
              menu_Id: 2196,
              menuName: "退货映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/settlement",
            component: () => import("@/views/api/otto/settlement"),
            name: "api-otto-settlement",
            meta: {
              title: "api-otto-settlement",
              icon: "",
              menu_Id: 2234,
              menuName: "结算(Ratepay)",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/payment",
            component: () => import("@/views/api/otto/payment"),
            name: "api-otto-payment",
            meta: {
              title: "api-otto-payment",
              icon: "",
              menu_Id: 2241,
              menuName: "结算(Payment)",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/kundenbelege",
            component: () => import("@/views/api/otto/kundenbelege"),
            name: "api-otto-kundenbelege",
            meta: {
              title: "api-otto-kundenbelege",
              icon: "",
              menu_Id: 2242,
              menuName: "开票(kundenbelege)",
              isOpenWindow: false
            }
          },
          {
            path: "/api/otto/postbox",
            component: () => import("@/views/api/otto/postbox"),
            name: "api-otto-postbox",
            meta: {
              title: "api-otto-postbox",
              icon: "",
              menu_Id: 2243,
              menuName: "邮箱结算",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "dhgate",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "dhgate-base",
        alwaysShow: true,
        meta: {
          title: "dhgate-base",
          icon: "",
          menu_Id: 284,
          menuName: "敦煌",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/dhgate/storeInfo",
            component: () => import("@views/api/dhgate/storeInfo"),
            name: "api-dhgate-storeInfo",
            meta: {
              title: "api-dhgate-storeInfo",
              icon: "",
              menu_Id: 361,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/dhgate/product",
            component: () => import("@views/api/dhgate/product"),
            name: "api-dhgate-product",
            meta: {
              title: "api-dhgate-product",
              icon: "",
              menu_Id: 364,
              menuName: "商品SKU映射",
              isOpenWindow: false
            }
          },
          {
            path: "/api/dhgate/sale-order",
            component: () => import("@views/api/dhgate/sale-order"),
            name: "api-dhgate-sale-order",
            meta: {
              title: "api-dhgate-sale-order",
              icon: "",
              menu_Id: 382,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "third",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "third-base",
        alwaysShow: true,
        meta: {
          title: "third-base",
          icon: "",
          menu_Id: 355,
          menuName: "第三方仓",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/third/baseInfo",
            component: () => import("@/views/api/third/baseInfo"),
            name: "api-third-baseInfo",
            meta: {
              title: "api-third-baseInfo",
              icon: "",
              menu_Id: 358,
              menuName: "第三方仓设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-info-third-sku",
            component: () => import("@/views/sys/product/product-info-third-sku"),
            name: "sys-product-product-info-third-sku",
            meta: {
              title: "sys-product-product-info-third-sku",
              icon: "",
              menu_Id: 359,
              menuName: "SKU推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/third/product-plat",
            component: () => import("@/views/api/third/product-plat"),
            name: "api-third-product-plat",
            meta: {
              title: "api-third-product-plat",
              icon: "",
              menu_Id: 2221,
              menuName: "第三方SKU映射关系表",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/enter-third",
            component: () => import("@/views/inbound/purchase/enter-third"),
            name: "inbound-purchase-enter-third",
            meta: {
              title: "inbound-purchase-enter-third",
              icon: "",
              menu_Id: 362,
              menuName: "入库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-third",
            component: () => import("@/views/outbound/sale/order-third"),
            name: "outbound-sale-order-third",
            meta: {
              title: "outbound-sale-order-third",
              icon: "",
              menu_Id: 363,
              menuName: "出库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/api/third/inventory",
            component: () => import("@/views/api/third/inventory"),
            name: "api-third-inventory",
            meta: {
              title: "api-third-inventory",
              icon: "",
              menu_Id: 365,
              menuName: "库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/api/third/mailingfee",
            component: () => import("@/views/api/third/mailingfee"),
            name: "api-third-mailingfee",
            meta: {
              title: "api-third-mailingfee",
              icon: "",
              menu_Id: 367,
              menuName: "邮寄费用",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Lazada",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Lazada-base",
        alwaysShow: true,
        meta: {
          title: "Lazada-base",
          icon: "",
          menu_Id: 383,
          menuName: "Lazada",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/lazada/storeInfo",
            component: () => import("@/views/api/lazada/storeInfo"),
            name: "api-lazada-storeInfo",
            meta: {
              title: "api-lazada-storeInfo",
              icon: "",
              menu_Id: 420,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/lazada/product",
            component: () => import("@/views/api/lazada/product"),
            name: "api-lazada-product",
            meta: {
              title: "api-lazada-product",
              icon: "",
              menu_Id: 422,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/lazada/sale-order",
            component: () => import("@/views/api/lazada/sale-order"),
            name: "api-lazada-sale-order",
            meta: {
              title: "api-lazada-sale-order",
              icon: "",
              menu_Id: 421,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/cdiscount",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "cdiscount",
        alwaysShow: true,
        meta: {
          title: "cdiscount",
          icon: "",
          menu_Id: 1901,
          menuName: "Cdiscount",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/cdiscount/storeInfo",
            component: () => import("@/views/api/cdiscount/storeInfo"),
            name: "api-cdiscount-storeInfo",
            meta: {
              title: "api-cdiscount-storeInfo",
              icon: "",
              menu_Id: 1902,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/cdiscount/product",
            component: () => import("@/views/api/cdiscount/product"),
            name: "api-cdiscount-product",
            meta: {
              title: "api-cdiscount-product",
              icon: "",
              menu_Id: 1903,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/cdiscount/sale-order",
            component: () => import("@/views/api/cdiscount/sale-order"),
            name: "api-cdiscount-sale-order",
            meta: {
              title: "api-cdiscount-sale-order",
              icon: "",
              menu_Id: 1904,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/cdiscount/settlement",
            component: () => import("@/views/api/cdiscount/settlement"),
            name: "api-cdiscount-settlement",
            meta: {
              title: "api-cdiscount-settlement",
              icon: "",
              menu_Id: 2263,
              menuName: "账单信息",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "weidian",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "weidian",
        alwaysShow: true,
        meta: {
          title: "weidian",
          icon: "",
          menu_Id: 177,
          menuName: "微店",
          isOpenWindow: false
        },
        children: [
          {
            path: "/interface/weidian/basicInfo",
            component: () => import("@/views/interface/weidian/basicInfo"),
            name: "interface-weidian-basicInfo",
            meta: {
              title: "interface-weidian-basicInfo",
              icon: "",
              menu_Id: 1787,
              menuName: "接口配置",
              isOpenWindow: false
            }
          },
          {
            path: "/interface/weidian/product",
            component: () => import("@/views/interface/weidian/product"),
            name: "interface-weidian-product",
            meta: {
              title: "interface-weidian-product",
              icon: "",
              menu_Id: 277,
              menuName: "商品信息",
              isOpenWindow: false
            }
          },
          {
            path: "/interface/weidian/order",
            component: () => import("@/views/interface/weidian/order"),
            name: "interface-weidian-order",
            meta: {
              title: "interface-weidian-order",
              icon: "",
              menu_Id: 278,
              menuName: "订单信息",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "fnac",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "fnac",
        alwaysShow: true,
        meta: {
          title: "fnac",
          icon: "",
          menu_Id: 1909,
          menuName: "Fanc",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/fnac/storeInfo",
            component: () => import("@/views/api/fnac/storeInfo"),
            name: "api-fnac-storeInfo",
            meta: {
              title: "api-fnac-storeInfo",
              icon: "",
              menu_Id: 1910,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/fnac/product",
            component: () => import("@/views/api/fnac/product"),
            name: "api-fnac-product",
            meta: {
              title: "api-fnac-product",
              icon: "",
              menu_Id: 1912,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/fnac/sale-order",
            component: () => import("@/views/api/fnac/sale-order"),
            name: "api-fnac-sale-order",
            meta: {
              title: "api-fnac-sale-order",
              icon: "",
              menu_Id: 1913,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "guanjiapo",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "guanjiapo",
        alwaysShow: true,
        meta: {
          title: "guanjiapo",
          icon: "",
          menu_Id: 209,
          menuName: "管家婆",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/order-gjp",
            component: () => import("@/views/inbound/purchase/order-gjp"),
            name: "inbound-purchase-order-gjp",
            meta: {
              title: "inbound-purchase-order-gjp",
              icon: "",
              menu_Id: 210,
              menuName: "收货入库推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-gjp",
            component: () => import("@/views/outbound/sale/order-gjp"),
            name: "outbound-sale-order-gjp",
            meta: {
              title: "outbound-sale-order-gjp",
              icon: "",
              menu_Id: 211,
              menuName: "出库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/enter-gjp",
            component: () => import("@/views/inbound/purchase/enter-gjp"),
            name: "inbound-purchase-enter-gjp",
            meta: {
              title: "inbound-purchase-enter-gjp",
              icon: "",
              menu_Id: 214,
              menuName: "入库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/outer-gjp",
            component: () => import("@/views/outbound/sale/outer-gjp"),
            name: "outbound-sale-outer-gjp",
            meta: {
              title: "outbound-sale-outer-gjp",
              icon: "",
              menu_Id: 208,
              menuName: "打包单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-gjp-llbill",
            component: () => import("@/views/outbound/sale/order-gjp-llbill"),
            name: "outbound-sale-order-gjp-llbill",
            meta: {
              title: "outbound-sale-order-gjp-llbill",
              icon: "",
              menu_Id: 410,
              menuName: "领料单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/order-gjp-tlbill",
            component: () => import("@/views/inbound/purchase/order-gjp-tlbill"),
            name: "inbound-purchase-order-gjp-tlbill",
            meta: {
              title: "inbound-purchase-order-gjp-tlbill",
              icon: "",
              menu_Id: 415,
              menuName: "退料单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/allocate/enter-push",
            component: () => import("@/views/storage/allocate/enter-push"),
            name: "storage-allocate-enter-push",
            meta: {
              title: "storage-allocate-enter-push",
              icon: "",
              menu_Id: 1911,
              menuName: "调拨单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/order-gjp-wgysbill",
            component: () => import("@/views/inbound/purchase/order-gjp-wgysbill"),
            name: "inbound-purchase-order-gjp-wgysbill",
            meta: {
              title: "inbound-purchase-order-gjp-wgysbill",
              icon: "",
              menu_Id: 419,
              menuName: "完工验收单",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/return-gjp",
            component: () => import("@/views/inbound/purchase/return-gjp"),
            name: "inbound-purchase-return-gjp",
            meta: {
              title: "inbound-purchase-return-gjp",
              icon: "",
              menu_Id: 417,
              menuName: "预到货退货推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/exchange-gjp",
            component: () => import("@/views/outbound/service/exchange-gjp"),
            name: "outbound-service-exchange-gjp",
            meta: {
              title: "outbound-service-exchange-gjp",
              icon: "",
              menu_Id: 1689,
              menuName: "销售换货推送",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/profit-loss-gjp",
            component: () => import("@/views/storage/check/profit-loss-gjp"),
            name: "storage-check-profit-loss-gjp",
            meta: {
              title: "storage-check-profit-loss-gjp",
              icon: "",
              menu_Id: 1701,
              menuName: "盘点盈亏单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/operation/storage-adjust-gjp",
            component: () => import("@/views/storage/operation/storage-adjust-gjp"),
            name: "storage-operation-storage-adjust-gjp",
            meta: {
              title: "storage-operation-storage-adjust-gjp",
              icon: "",
              menu_Id: 1705,
              menuName: "库存调整单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/service/return-gjp",
            component: () => import("@/views/outbound/service/return-gjp"),
            name: "/outbound/service/return-gjp",
            meta: {
              title: "/outbound/service/return-gjp",
              icon: "",
              menu_Id: 1706,
              menuName: "销售退货推送",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/assemble-gjp",
            component: () => import("@/views/storage/check/assemble-gjp"),
            name: "storage-check-assemble-gjp",
            meta: {
              title: "storage-check-assemble-gjp",
              icon: "",
              menu_Id: 1893,
              menuName: "商品拆装单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/way/bill-gjp",
            component: () => import("@/views/tms/way/bill-gjp"),
            name: "tms-way-bill-gjp",
            meta: {
              title: "tms-way-bill-gjp",
              icon: "",
              menu_Id: 1897,
              menuName: "运单已签收推送",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/orderAllocation-gjp",
            component: () => import("@/views/inbound/purchase/orderAllocation-gjp"),
            name: "inbound-purchase-orderAllocation-gjp",
            meta: {
              title: "inbound-purchase-orderAllocation-gjp",
              icon: "",
              menu_Id: 1906,
              menuName: "分支结构调拨单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/transferCallBack-gjp",
            component: () => import("@/views/outbound/sale/transferCallBack-gjp"),
            name: "outbound-sale-transferCallBack-gjp",
            meta: {
              title: "outbound-sale-transferCallBack-gjp",
              icon: "",
              menu_Id: 1907,
              menuName: "回写调拨单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/orderSaleAllocation-gjp",
            component: () => import("@/views/outbound/sale/orderSaleAllocation-gjp"),
            name: "outbound-sale-orderSaleAllocation-gjp",
            meta: {
              title: "outbound-sale-orderSaleAllocation-gjp",
              icon: "",
              menu_Id: 1927,
              menuName: "分支机构调拨出库推送",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Walmart",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Walmart",
        alwaysShow: true,
        meta: {
          title: "Walmart",
          icon: "",
          menu_Id: 224,
          menuName: "Walmart",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/walmart/storeInfo",
            component: () => import("@/views/api/walmart/storeInfo"),
            name: "api-walmart-storeInfo",
            meta: {
              title: "api-walmart-storeInfo",
              icon: "",
              menu_Id: 349,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/walmart/sale-order",
            component: () => import("@/views/api/walmart/sale-order"),
            name: "api-walmart-sale-order",
            meta: {
              title: "api-walmart-sale-order",
              icon: "",
              menu_Id: 351,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Wayfair",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Wayfair",
        alwaysShow: true,
        meta: {
          title: "Wayfair",
          icon: "",
          menu_Id: 266,
          menuName: "Wayfair",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/wayfair/storeInfo",
            component: () => import("@/views/api/wayfair/storeInfo"),
            name: "api-wayfair-storeInfo",
            meta: {
              title: "api-wayfair-storeInfo",
              icon: "",
              menu_Id: 291,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/wayfair/product",
            component: () => import("@/views/api/wayfair/product"),
            name: "api-wayfair-product",
            meta: {
              title: "api-wayfair-product",
              icon: "",
              menu_Id: 293,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/wayfair/sale-order",
            component: () => import("@/views/api/wayfair/sale-order"),
            name: "api-wayfair-sale-order",
            meta: {
              title: "api-wayfair-sale-order",
              icon: "",
              menu_Id: 323,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Coupang",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Coupang",
        alwaysShow: true,
        meta: {
          title: "Coupang",
          icon: "",
          menu_Id: 458,
          menuName: "Coupang",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/coupang/storeInfo",
            component: () => import("@/views/api/coupang/storeInfo"),
            name: "api-coupang-storeInfo",
            meta: {
              title: "api-coupang-storeInfo",
              icon: "",
              menu_Id: 475,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/coupang/product",
            component: () => import("@/views/api/coupang/product"),
            name: "api-coupang-product",
            meta: {
              title: "api-coupang-product",
              icon: "",
              menu_Id: 476,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/coupang/sale-order",
            component: () => import("@/views/api/coupang/sale-order"),
            name: "api-coupang-sale-order",
            meta: {
              title: "api-coupang-sale-order",
              icon: "",
              menu_Id: 483,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/api/shopee/storeInfo",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "api-shopee-storeInfo",
        alwaysShow: true,
        meta: {
          title: "api-shopee-storeInfo",
          icon: "",
          menu_Id: 1653,
          menuName: "shopee",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/shopee/storeInfo",
            component: () => import("@/views/api/shopee/storeInfo"),
            name: "api-shopee-storeInfo",
            meta: {
              title: "api-shopee-storeInfo",
              icon: "",
              menu_Id: 1654,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/shopee/product",
            component: () => import("@/views/api/shopee/product"),
            name: "api-shopee-product",
            meta: {
              title: "api-shopee-product",
              icon: "",
              menu_Id: 1655,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/shopee/sale-order",
            component: () => import("@/views/api/shopee/sale-order"),
            name: "api-shopee-sale-order",
            meta: {
              title: "api-shopee-sale-order",
              icon: "",
              menu_Id: 1656,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "wulian",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "wulian",
        alwaysShow: true,
        meta: {
          title: "wulian",
          icon: "",
          menu_Id: 1770,
          menuName: "浙江物联",
          isOpenWindow: false
        },
        children: [
          {
            path: "/inbound/purchase/enter-wulian",
            component: () => import("@/views/inbound/purchase/enter-wulian"),
            name: "inbound-purchase-enter-wulian",
            meta: {
              title: "inbound-purchase-enter-wulian",
              icon: "",
              menu_Id: 1775,
              menuName: "入库单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/check/profit-loss-wulian",
            component: () => import("@/views/storage/check/profit-loss-wulian"),
            name: "storage-check-profitlosswulian",
            meta: {
              title: "storage-check-profitlosswulian",
              icon: "",
              menu_Id: 1847,
              menuName: "盘点盈亏单推送",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/outer-wulian",
            component: () => import("@/views/outbound/sale/outer-wulian"),
            name: "outbound-sale-outer-wulian",
            meta: {
              title: "outbound-sale-outer-wulian",
              icon: "",
              menu_Id: 1862,
              menuName: "出库单推送",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "pinduoduo",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "pinduoduo-base",
        alwaysShow: true,
        meta: {
          title: "pinduoduo-base",
          icon: "",
          menu_Id: 1894,
          menuName: "拼多多",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/pdd/storeInfo",
            component: () => import("@/views/api/pdd/storeInfo"),
            name: "api-pdd-storeInfo",
            meta: {
              title: "api-pdd-storeInfo",
              icon: "",
              menu_Id: 1541,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/pdd/product",
            component: () => import("@/views/api/pdd/product"),
            name: "api-pdd-product",
            meta: {
              title: "api-pdd-product",
              icon: "",
              menu_Id: 1594,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/pdd/sale-order",
            component: () => import("@/views/api/pdd/sale-order"),
            name: "api-pdd-sale-order",
            meta: {
              title: "api-pdd-sale-order",
              icon: "",
              menu_Id: 1540,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Kaufland",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Kaufland-base",
        alwaysShow: true,
        meta: {
          title: "Kaufland-base",
          icon: "",
          menu_Id: 178,
          menuName: "Kaufland",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/kaufland/storeInfo",
            component: () => import("@/views/api/kaufland/storeInfo"),
            name: "api-kaufland-storeInfo",
            meta: {
              title: "api-kaufland-storeInfo",
              icon: "",
              menu_Id: 264,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/kaufland/product",
            component: () => import("@/views/api/kaufland/product"),
            name: "api-kaufland-product",
            meta: {
              title: "api-kaufland-product",
              icon: "",
              menu_Id: 182,
              menuName: "商品SKU映射",
              isOpenWindow: false
            }
          },
          {
            path: "/api/kaufland/sale-order",
            component: () => import("@/views/api/kaufland/sale-order"),
            name: "api-kaufland-sale-order",
            meta: {
              title: "api-kaufland-sale-order",
              icon: "",
              menu_Id: 222,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/kaufland/return-order",
            component: () => import("@/views/api/kaufland/return-order"),
            name: "api-kaufland-return-order",
            meta: {
              title: "api-kaufland-return-order",
              icon: "",
              menu_Id: 2264,
              menuName: "退货映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/kaufland/settlement",
            component: () => import("@/views/api/kaufland/settlement"),
            name: "api-kaufland-settlement",
            meta: {
              title: "api-kaufland-settlement",
              icon: "",
              menu_Id: 2265,
              menuName: "结算信息",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Fxiaoke",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Fxiaoke",
        alwaysShow: true,
        meta: {
          title: "Fxiaoke",
          icon: "",
          menu_Id: 1922,
          menuName: "纷享销客",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/fenxiang/storeInfo",
            component: () => import("@/views/api/fenxiang/storeInfo"),
            name: "api-fenxiang-storeInfo",
            meta: {
              title: "api-fenxiang-storeInfo",
              icon: "",
              menu_Id: 1924,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-info-fxxk",
            component: () => import("@/views/sys/product/product-info-fxxk"),
            name: "sys-product-product-info-fxxk",
            meta: {
              title: "sys-product-product-info-fxxk",
              icon: "",
              menu_Id: 1923,
              menuName: "商品信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-fxiaoke",
            component: () => import("@/views/crm/base/client-fxiaoke"),
            name: "crm-base-client-fxiaoke",
            meta: {
              title: "crm-base-client-fxiaoke",
              icon: "",
              menu_Id: 1925,
              menuName: "客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/linker-fxiaoke",
            component: () => import("@/views/crm/base/linker-fxiaoke"),
            name: "crm-base-linker-fxiaoke",
            meta: {
              title: "crm-base-linker-fxiaoke",
              icon: "",
              menu_Id: 1926,
              menuName: "联系人管理",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/order-tob-fxiaoke",
            component: () => import("@/views/erp/sale/order-tob-fxiaoke"),
            name: "erp-sale-order-tob-fxiaoke",
            meta: {
              title: "erp-sale-order-tob-fxiaoke",
              icon: "",
              menu_Id: 1937,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/outer-fxiaoke",
            component: () => import("@/views/outbound/sale/outer-fxiaoke"),
            name: "outbound-sale-outer-fxiaoke",
            meta: {
              title: "outbound-sale-outer-fxiaoke",
              icon: "",
              menu_Id: 2031,
              menuName: "打包校验",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "yjj",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "yjj",
        alwaysShow: true,
        meta: {
          title: "yjj",
          icon: "",
          menu_Id: 2041,
          menuName: "上海应急局",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/product/product-info-yjj",
            component: () => import("@/views/sys/product/product-info-yjj"),
            name: "sys-product-product-info-yjj",
            meta: {
              title: "sys-product-product-info-yjj",
              icon: "",
              menu_Id: 2042,
              menuName: "商品信息",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/storage-yjj",
            component: () => import("@/views/storage/setting/storage-yjj"),
            name: "storage-setting-storage-yjj",
            meta: {
              title: "storage-setting-storage-yjj",
              icon: "",
              menu_Id: 2043,
              menuName: "仓库信息同步",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/setting/tank-yjj",
            component: () => import("@/views/storage/setting/tank-yjj"),
            name: "storage-setting-tank-yjj",
            meta: {
              title: "storage-setting-tank-yjj",
              icon: "",
              menu_Id: 2044,
              menuName: "储罐信息同步",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/base/product-position-yjj",
            component: () => import("@/views/storage/base/product-position-yjj"),
            name: "storage-base-product-position-yjj",
            meta: {
              title: "storage-base-product-position-yjj",
              icon: "",
              menu_Id: 2045,
              menuName: "库存仓库信息同步",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/enter-yjj",
            component: () => import("@/views/inbound/purchase/enter-yjj"),
            name: "inbound-purchase-enter-yjj",
            meta: {
              title: "inbound-purchase-enter-yjj",
              icon: "",
              menu_Id: 2046,
              menuName: "入库上报",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-yjj",
            component: () => import("@/views/outbound/sale/order-yjj"),
            name: "outbound-sale-order-yjj",
            meta: {
              title: "outbound-sale-order-yjj",
              icon: "",
              menu_Id: 2047,
              menuName: "出库上报",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/position-transfer-yjj",
            component: () => import("@/views/storage/transfer/position-transfer-yjj"),
            name: "storage-transfer-position-transfer-yjj",
            meta: {
              title: "storage-transfer-position-transfer-yjj",
              icon: "",
              menu_Id: 2048,
              menuName: "移库上报",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/position-transfer-TankLevel-yjj",
            component: () => import("@/views/storage/transfer/position-transfer-TankLevel-yjj"),
            name: "storage-transfer-position-transfer-TankLevel-yjj",
            meta: {
              title: "storage-transfer-position-transfer-TankLevel-yjj",
              icon: "",
              menu_Id: 2049,
              menuName: "储罐液位上报",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/tms-vehicleaccess-yjj",
            component: () => import("@/views/tms/service/tms-vehicleaccess-yjj"),
            name: "tms-service-tms-vehicleaccess-yjj",
            meta: {
              title: "tms-service-tms-vehicleaccess-yjj",
              icon: "",
              menu_Id: 2050,
              menuName: "车辆信息上报",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Ozon",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Ozon-base",
        alwaysShow: true,
        meta: {
          title: "Ozon-base",
          icon: "",
          menu_Id: 2266,
          menuName: "Ozon",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/ozon/storeInfo",
            component: () => import("@/views/api/ozon/storeInfo"),
            name: "api-ozon-storeInfo",
            meta: {
              title: "api-ozon-storeInfo",
              icon: "",
              menu_Id: 2267,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ozon/product",
            component: () => import("@/views/api/ozon/product"),
            name: "api-ozon-product",
            meta: {
              title: "api-ozon-product",
              icon: "",
              menu_Id: 2268,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ozon/fbo-order",
            component: () => import("@/views/api/ozon/fbo-order"),
            name: "api-ozon-fbo-order",
            meta: {
              title: "api-ozon-fbo-order",
              icon: "",
              menu_Id: 2269,
              menuName: "FBO订单",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ozon/fbs-order",
            component: () => import("@/views/api/ozon/fbs-order"),
            name: "api-ozon-fbs-order",
            meta: {
              title: "api-ozon-fbs-order",
              icon: "",
              menu_Id: 2270,
              menuName: "FBS订单",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ozon/return-order",
            component: () => import("@/views/api/ozon/return-order"),
            name: "api-ozon-return-order",
            meta: {
              title: "api-ozon-return-order",
              icon: "",
              menu_Id: 2271,
              menuName: "退货订单",
              isOpenWindow: false
            }
          },
          {
            path: "/api/ozon/settlement",
            component: () => import("@/views/api/ozon/settlement"),
            name: "api-ozon-settlement",
            meta: {
              title: "api-ozon-settlement",
              icon: "",
              menu_Id: 2274,
              menuName: "结算账单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "Allegro",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "Allegro",
        alwaysShow: true,
        meta: {
          title: "Allegro",
          icon: "",
          menu_Id: 2280,
          menuName: "Allegro",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/allegro/storeInfo",
            component: () => import("@/views/api/allegro/storeInfo"),
            name: "api-allegro-storeInfo",
            meta: {
              title: "api-allegro-storeInfo",
              icon: "",
              menu_Id: 2281,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/allegro/product",
            component: () => import("@/views/api/allegro/product"),
            name: "api-allegro-product",
            meta: {
              title: "api-allegro-product",
              icon: "",
              menu_Id: 2282,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/allegro/saleOrder",
            component: () => import("@/views/api/allegro/saleOrder"),
            name: "api-allegro-saleOrder",
            meta: {
              title: "api-allegro-saleOrder",
              icon: "",
              menu_Id: 2283,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/allegro/return-order",
            component: () => import("@/views/api/allegro/return-order"),
            name: "api-allegro-return-order",
            meta: {
              title: "api-allegro-return-order",
              icon: "",
              menu_Id: 2284,
              menuName: "退货订单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "ManoMano",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "ManoMano",
        alwaysShow: true,
        meta: {
          title: "ManoMano",
          icon: "",
          menu_Id: 2285,
          menuName: "ManoMano",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/manoMano/storeInfo",
            component: () => import("@/views/api/manoMano/storeInfo"),
            name: "api-manoMano-storeInfo",
            meta: {
              title: "api-manoMano-storeInfo",
              icon: "",
              menu_Id: 2286,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/manoMano/prodcut",
            component: () => import("@/views/api/manoMano/prodcut"),
            name: "api-manoMano-prodcut",
            meta: {
              title: "api-manoMano-prodcut",
              icon: "",
              menu_Id: 2287,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/manoMano/order",
            component: () => import("@/views/api/manoMano/order"),
            name: "api-manoMano-order",
            meta: {
              title: "api-manoMano-order",
              icon: "",
              menu_Id: 2288,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/manoMano/return-order",
            component: () => import("@/views/api/manoMano/return-order"),
            name: "api-manoMano-return-order",
            meta: {
              title: "api-manoMano-return-order",
              icon: "",
              menu_Id: 2289,
              menuName: "退货订单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "BOL",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "BOL",
        alwaysShow: true,
        meta: {
          title: "BOL",
          icon: "",
          menu_Id: 2296,
          menuName: "BOL",
          isOpenWindow: false
        },
        children: [
          {
            path: "/api/bol/storeInfo",
            component: () => import("@/views/api/bol/storeInfo"),
            name: "api-bol-storeInfo",
            meta: {
              title: "api-bol-storeInfo",
              icon: "",
              menu_Id: 2297,
              menuName: "店铺设置",
              isOpenWindow: false
            }
          },
          {
            path: "/api/bol/product",
            component: () => import("@/views/api/bol/product"),
            name: "api-bol-product",
            meta: {
              title: "api-bol-product",
              icon: "",
              menu_Id: 2299,
              menuName: "SKU映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/bol/order",
            component: () => import("@/views/api/bol/order"),
            name: "api-bol-order",
            meta: {
              title: "api-bol-order",
              icon: "",
              menu_Id: 2300,
              menuName: "订单映射关系",
              isOpenWindow: false
            }
          },
          {
            path: "/api/bol/return-order",
            component: () => import("@/views/api/bol/return-order"),
            name: "api-bol-return-order",
            meta: {
              title: "api-bol-return-order",
              icon: "",
              menu_Id: 2301,
              menuName: "退货订单",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/crm",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "crm",
    alwaysShow: true,
    meta: {
      title: "crm",
      icon: "menu-icon el-icon-yrt-ai-connection",
      menu_Id: 294,
      menuName: "CRM",
      isOpenWindow: false
    },
    children: [
      {
        path: "client",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "crm-client",
        alwaysShow: true,
        meta: {
          title: "crm-client",
          icon: "",
          menu_Id: 167,
          menuName: "客户管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/crm/base/corperation",
            component: () => import("@/views/crm/base/corperation"),
            name: "crm-base-corperation",
            meta: {
              title: "crm-base-corperation",
              icon: "",
              menu_Id: 1582,
              menuName: "公司管理",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client",
            component: () => import("@/views/crm/base/client"),
            name: "crm-base-client",
            meta: {
              title: "crm-base-client",
              icon: "",
              menu_Id: 255,
              menuName: "客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/linker",
            component: () => import("@/views/crm/base/linker"),
            name: "crm-base-linker",
            meta: {
              title: "crm-base-linker",
              icon: "",
              menu_Id: 296,
              menuName: "联系人管理",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/address",
            component: () => import("@/views/crm/base/address"),
            name: "crm-base-address",
            meta: {
              title: "crm-base-address",
              icon: "",
              menu_Id: 390,
              menuName: "客户地址管理",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-coupon",
            component: () => import("@/views/crm/base/client-coupon"),
            name: "crm-base-client-coupon",
            meta: {
              title: "crm-base-client-coupon",
              icon: "",
              menu_Id: 392,
              menuName: "客户优惠券",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-tob",
            component: () => import("@/views/crm/base/client-tob"),
            name: "crm-base-client-tob",
            meta: {
              title: "crm-base-client-tob",
              icon: "",
              menu_Id: 1865,
              menuName: "TOB客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-b",
            component: () => import("@/views/crm/base/client-b"),
            name: "crm-base-client-b",
            meta: {
              title: "crm-base-client-b",
              icon: "",
              menu_Id: 1866,
              menuName: "小B客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-toc",
            component: () => import("@/views/crm/base/client-toc"),
            name: "crm-base-client-toc",
            meta: {
              title: "crm-base-client-toc",
              icon: "",
              menu_Id: 1867,
              menuName: "TOC客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/client-sub",
            component: () => import("@/views/crm/base/client-sub"),
            name: "crm-base-client-sub",
            meta: {
              title: "crm-base-client-sub",
              icon: "",
              menu_Id: 2313,
              menuName: "子客户信息",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/base/feedback",
            component: () => import("@/views/crm/base/feedback"),
            name: "crm-base-feedback",
            meta: {
              title: "crm-base-feedback",
              icon: "",
              menu_Id: 391,
              menuName: "意见反馈",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "app",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "crm-app",
        alwaysShow: true,
        meta: {
          title: "crm-app",
          icon: "",
          menu_Id: 1790,
          menuName: "App",
          isOpenWindow: false
        },
        children: [
          {
            path: "/crm/app/productApply",
            component: () => import("@/views/crm/app/productApply"),
            name: "crm-app-productApply",
            meta: {
              title: "crm-app-productApply",
              icon: "menu-icon el-icon-yrt-ai-connection",
              menu_Id: 1791,
              menuName: "商品申请审核",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/app/feedacbk",
            component: () => import("@/views/crm/app/feedacbk"),
            name: "crm-app-feedacbk",
            meta: {
              title: "crm-app-feedacbk",
              icon: "menu-icon el-icon-yrt-ai-connection",
              menu_Id: 1792,
              menuName: "客户端反馈",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/app/goodsView",
            component: () => import("@/views/crm/app/goodsView"),
            name: "crm-app-goodsView",
            meta: {
              title: "crm-app-goodsView",
              icon: "menu-icon el-icon-yrt-ai-connection",
              menu_Id: 1793,
              menuName: "仓库实物巡检",
              isOpenWindow: false
            }
          },
          {
            path: "/crm/app/address",
            component: () => import("@/views/crm/app/address"),
            name: "crm-app-address",
            meta: {
              title: "crm-app-address",
              icon: "menu-icon el-icon-yrt-ai-connection",
              menu_Id: 1794,
              menuName: "地址",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/stat",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "stat",
    alwaysShow: true,
    meta: {
      title: "stat",
      icon: "menu-icon el-icon-yrt-tongji",
      menu_Id: 252,
      menuName: "统计",
      isOpenWindow: false
    },
    children: [
      {
        path: "stat-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-base",
        alwaysShow: true,
        meta: {
          title: "stat-base",
          icon: "",
          menu_Id: 1566,
          menuName: "数据监控",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/inventory/early",
            component: () => import("@/views/stat/inventory/early"),
            name: "v-shelflife-early-warning",
            meta: {
              title: "v-shelflife-early-warning",
              icon: "",
              menu_Id: 1570,
              menuName: "保质期预警",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/inventory/warning",
            component: () => import("@/views/stat/inventory/warning"),
            name: "v-old-library-early-warning",
            meta: {
              title: "v-old-library-early-warning",
              icon: "",
              menu_Id: 1571,
              menuName: "库龄预警",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/inventory/lower",
            component: () => import("@/views/stat/inventory/lower"),
            name: "v-base-product-position-lower",
            meta: {
              title: "v-base-product-position-lower",
              icon: "",
              menu_Id: 1720,
              menuName: "货位最低库存预警",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-query",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-query",
        alwaysShow: true,
        meta: {
          title: "stat-query",
          icon: "",
          menu_Id: 1827,
          menuName: "查询统计",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/dev-tools/bi-viewer",
            component: () => import("@/views/sys/dev-tools/bi-designer/viewer"),
            name: "sys-dev-tools-bi-viewer",
            meta: {
              title: "sys-dev-tools-bi-viewer",
              icon: "",
              menu_Id: 353,
              menuName: "BI报表",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/summary/typesummary",
            component: () => import("@/views/stat/summary/typesummary"),
            name: "stat-summary-typesummary",
            meta: {
              title: "stat-summary-typesummary",
              icon: "",
              menu_Id: 1815,
              menuName: "类别明细汇总统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/query/typeclassifcount",
            component: () => import("@/views/stat/query/typeclassifcount"),
            name: "stat-query-typeclassifcount",
            meta: {
              title: "stat-query-typeclassifcount",
              icon: "",
              menu_Id: 1828,
              menuName: "分类统计柱状图",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/stat/stat-manage",
            component: () => import("@/views/storage/stat/stat-manage"),
            name: "storage-stat-stat-manage",
            meta: {
              title: "storage-stat-stat-manage",
              icon: "",
              menu_Id: 2262,
              menuName: "项目管理(报表)",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-dailyquery",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-dailyquery",
        alwaysShow: true,
        meta: {
          title: "stat-dailyquery",
          icon: "",
          menu_Id: 1829,
          menuName: "每日分类查询统计",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/dailyquery/dailyinventoryclassifi",
            component: () => import("@/views/stat/dailyquery/dailyinventoryclassifi"),
            name: "stat-dailyquery-dailyinventoryclassifi",
            meta: {
              title: "stat-dailyquery-dailyinventoryclassifi",
              icon: "",
              menu_Id: 1830,
              menuName: "每日库存分类统计图",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/dailyquery/dailyoutstorageclassifi",
            component: () => import("@/views/stat/dailyquery/dailyoutstorageclassifi"),
            name: "stat-dailyquery-dailyoutstorageclassifi",
            meta: {
              title: "stat-dailyquery-dailyoutstorageclassifi",
              icon: "",
              menu_Id: 1831,
              menuName: "每日出库分类统计图",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/dailyquery/dailystorageclassifi",
            component: () => import("@/views/stat/dailyquery/dailystorageclassifi"),
            name: "stat-dailyquery-dailystorageclassifi",
            meta: {
              title: "stat-dailyquery-dailystorageclassifi",
              icon: "",
              menu_Id: 1832,
              menuName: "每日入库分类统计图",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-receive-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-receive-base",
        alwaysShow: true,
        meta: {
          title: "stat-receive-base",
          icon: "",
          menu_Id: 108,
          menuName: "收货查询",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/receive/vpurchase-order-list",
            component: () => import("@/views/stat/receive/vpurchase-order-list"),
            name: "vpurchase-order-list",
            meta: {
              title: "vpurchase-order-list",
              icon: "",
              menu_Id: 12,
              menuName: "预到货明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-enter-list",
            component: () => import("@/views/stat/receive/vpurchase-enter-list"),
            name: "vpurchase-enter-list",
            meta: {
              title: "vpurchase-enter-list",
              icon: "",
              menu_Id: 107,
              menuName: "入库明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vtms-quotation-list",
            component: () => import("@/views/stat/receive/vtms-quotation-list"),
            name: "vtms-quotation-list",
            meta: {
              title: "vtms-quotation-list",
              icon: "",
              menu_Id: 448,
              menuName: "入库计划明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-shelve-list",
            component: () => import("@/views/stat/receive/vpurchase-shelve-list"),
            name: "stat-receive-vpurchase-shelve-list",
            meta: {
              title: "stat-receive-vpurchase-shelve-list",
              icon: "",
              menu_Id: 1602,
              menuName: "上架明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-enterlist-provider",
            component: () => import("@/views/stat/receive/vpurchase-enterlist-provider"),
            name: "vpurchase-enterlist-provider",
            meta: {
              title: "vpurchase-enterlist-provider",
              icon: "",
              menu_Id: 164,
              menuName: "供应商进货统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-enterlist-product",
            component: () => import("@/views/stat/receive/vpurchase-enterlist-product"),
            name: "vpurchase-enterlist-product",
            meta: {
              title: "vpurchase-enterlist-product",
              icon: "",
              menu_Id: 109,
              menuName: "商品进货统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-order-arrived",
            component: () => import("@/views/stat/receive/vpurchase-order-arrived"),
            name: "stat-receive-vpurchase-order-arrived",
            meta: {
              title: "stat-receive-vpurchase-order-arrived",
              icon: "",
              menu_Id: 165,
              menuName: "预到货单到期报警",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-enterList-report",
            component: () => import("@/views/stat/receive/vpurchase-enterList-report"),
            name: "vpurchase-enterList-report",
            meta: {
              title: "vpurchase-enterList-report",
              icon: "",
              menu_Id: 478,
              menuName: "收货入库报表",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-orderList-report",
            component: () => import("@/views/stat/receive/vpurchase-orderList-report"),
            name: "stat-receive-vpurchase-orderList-report",
            meta: {
              title: "stat-receive-vpurchase-orderList-report",
              icon: "",
              menu_Id: 480,
              menuName: "预到货单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/vpurchase-return-list",
            component: () => import("@/views/stat/receive/vpurchase-return-list"),
            name: "vpurchase-return-list",
            meta: {
              title: "vpurchase-return-list",
              icon: "",
              menu_Id: 1548,
              menuName: "收货退货查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/receive/sn-in",
            component: () => import("@/views/stat/receive/sn-in"),
            name: "stat-receive-sn-in",
            meta: {
              title: "stat-receive-sn-in",
              icon: "",
              menu_Id: 2294,
              menuName: "入库SN查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-out-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-out-base",
        alwaysShow: true,
        meta: {
          title: "stat-out-base",
          icon: "",
          menu_Id: 120,
          menuName: "出库查询",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/out/vsale-outer-list",
            component: () => import("@/views/stat/out/vsale-outer-list"),
            name: "vsale-outer-list",
            meta: {
              title: "vsale-outer-list",
              icon: "",
              menu_Id: 122,
              menuName: "出库打包明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/sn-out",
            component: () => import("@/views/stat/out/sn-out"),
            name: "stat-out-sn-out",
            meta: {
              title: "stat-out-sn-out",
              icon: "",
              menu_Id: 2295,
              menuName: "出库SN查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-outer-case",
            component: () => import("@/views/stat/out/vsale-outer-case"),
            name: "vsale-outer-case",
            meta: {
              title: "vsale-outer-case",
              icon: "",
              menu_Id: 123,
              menuName: "打包装箱查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-orderlist-client",
            component: () => import("@/views/stat/out/vsale-orderlist-client"),
            name: "vsale-orderlist-client",
            meta: {
              title: "vsale-orderlist-client",
              icon: "",
              menu_Id: 348,
              menuName: "客户销售查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-order-list",
            component: () => import("@/views/stat/out/vsale-order-list"),
            name: "vsale-order-list",
            meta: {
              title: "vsale-order-list",
              icon: "",
              menu_Id: 121,
              menuName: "出库订单明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-sendlist-report",
            component: () => import("@/views/stat/out/vsale-sendlist-report"),
            name: "vsale-sendlist-report",
            meta: {
              title: "vsale-sendlist-report",
              icon: "",
              menu_Id: 481,
              menuName: "出货报表",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-order-plan-detail",
            component: () => import("@/views/stat/out/vsale-order-plan-detail"),
            name: "vsale-order-plan-detail",
            meta: {
              title: "vsale-order-plan-detail",
              icon: "",
              menu_Id: 482,
              menuName: "出库计划明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-return-list",
            component: () => import("@/views/stat/out/vsale-return-list"),
            name: "vsale-return-list",
            meta: {
              title: "vsale-return-list",
              icon: "",
              menu_Id: 1551,
              menuName: "出库单退货查询",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/require/goods-details",
            component: () => import("@/views/outbound/require/goods-details"),
            name: "outbound-require-goods-details",
            meta: {
              title: "outbound-require-goods-details",
              icon: "",
              menu_Id: 1555,
              menuName: "要货明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/require/allocation-details",
            component: () => import("@/views/outbound/require/allocation-details"),
            name: "outbound-require-allocation-details",
            meta: {
              title: "outbound-require-allocation-details",
              icon: "",
              menu_Id: 183,
              menuName: "要货调拨明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/out/vsale-orderpickinglist",
            component: () => import("@/views/stat/out/vsale-orderpickinglist"),
            name: "stat-out-vsale-orderpickinglist",
            meta: {
              title: "stat-out-vsale-orderpickinglist",
              icon: "",
              menu_Id: 2036,
              menuName: "拣货下架明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-storage-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-storage-base",
        alwaysShow: true,
        meta: {
          title: "stat-storage-base",
          icon: "",
          menu_Id: 1533,
          menuName: "库存统计分析",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/storage/xstat-storageday",
            component: () => import("@/views/stat/storage/xstat-storageday"),
            name: "xstat-storageday",
            meta: {
              title: "xstat-storageday",
              icon: "",
              menu_Id: 216,
              menuName: "库存统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/stat-storageday",
            component: () => import("@/views/stat/storage/stat-storageday"),
            name: "stat-storageday",
            meta: {
              title: "stat-storageday",
              icon: "",
              menu_Id: 213,
              menuName: "每日商品库存查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/stat-storageday-details",
            component: () => import("@/views/stat/storage/stat-storageday-details"),
            name: "stat-storageday-details",
            meta: {
              title: "stat-storageday-details",
              icon: "",
              menu_Id: 215,
              menuName: "每日库存明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/vstorage-outer-list",
            component: () => import("@/views/stat/storage/vstorage-outer-list"),
            name: "vstorage-outer-list",
            meta: {
              title: "vstorage-outer-list",
              icon: "",
              menu_Id: 1535,
              menuName: "其他出库明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/vstorage-enter-list",
            component: () => import("@/views/stat/storage/vstorage-enter-list"),
            name: "vstorage-enter-list",
            meta: {
              title: "vstorage-enter-list",
              icon: "",
              menu_Id: 1536,
              menuName: "其他入库明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/xstat-storagemonth",
            component: () => import("@/views/stat/storage/xstat-storagemonth"),
            name: "xstat-storagemonth",
            meta: {
              title: "xstat-storagemonth",
              icon: "",
              menu_Id: 1812,
              menuName: "上个月与本月报表",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/transfer/position-transfer-list",
            component: () => import("@/views/storage/transfer/position-transfer-list"),
            name: "storage-transfer-position-transfer-list",
            meta: {
              title: "storage-transfer-position-transfer-list",
              icon: "",
              menu_Id: 2290,
              menuName: "货位转移明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-report-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-report-base",
        alwaysShow: true,
        meta: {
          title: "stat-report-base",
          icon: "",
          menu_Id: 1604,
          menuName: "收货统计报表",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/report/vpurchase-enter-ranking",
            component: () => import("@/views/stat/report/vpurchase-enter-ranking"),
            name: "vpurchase-enter-ranking",
            meta: {
              title: "vpurchase-enter-ranking",
              icon: "",
              menu_Id: 1606,
              menuName: "商品入库排名报表(年)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report/vpurchase-enter-rankbymonth",
            component: () => import("@/views/stat/report/vpurchase-enter-rankbymonth"),
            name: "vpurchase-enter-rankbymonth",
            meta: {
              title: "vpurchase-enter-rankbymonth",
              icon: "",
              menu_Id: 1610,
              menuName: "商品入库排名报表(月)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report/vpurchase-enter-rankbyweek",
            component: () => import("@/views/stat/report/vpurchase-enter-rankbyweek"),
            name: "vpurchase-enter-rankbyweek",
            meta: {
              title: "vpurchase-enter-rankbyweek",
              icon: "",
              menu_Id: 1613,
              menuName: "商品入库排名报表(周)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report/vpurchase-enter-rankbyday",
            component: () => import("@/views/stat/report/vpurchase-enter-rankbyday"),
            name: "vpurchase-enter-rankbyday",
            meta: {
              title: "vpurchase-enter-rankbyday",
              icon: "",
              menu_Id: 1614,
              menuName: "商品入库排名报表(天)",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "stat-out-report-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "stat-out-report-base",
        alwaysShow: true,
        meta: {
          title: "stat-out-report-base",
          icon: "",
          menu_Id: 1605,
          menuName: "出库统计报表",
          isOpenWindow: false
        },
        children: [
          {
            path: "/stat/report-out/vsale-ranking",
            component: () => import("@/views/stat/report-out/vsale-ranking"),
            name: "vsale-ranking",
            meta: {
              title: "vsale-ranking",
              icon: "",
              menu_Id: 1607,
              menuName: "商品出库排名报表(年)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report-out/vsale-rankbymonth",
            component: () => import("@/views/stat/report-out/vsale-rankbymonth"),
            name: "vsale-rankbymonth",
            meta: {
              title: "vsale-rankbymonth",
              icon: "",
              menu_Id: 1611,
              menuName: "商品出库排名报表(月)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report-out/vsale-rankbyweek",
            component: () => import("@/views/stat/report-out/vsale-rankbyweek"),
            name: "vsale-rankbyweek",
            meta: {
              title: "vsale-rankbyweek",
              icon: "",
              menu_Id: 1612,
              menuName: "商品出库排名报表(周)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report-out/vsale-rankbyday",
            component: () => import("@/views/stat/report-out/vsale-rankbyday"),
            name: "vsale-rankbyday",
            meta: {
              title: "vsale-rankbyday",
              icon: "",
              menu_Id: 1615,
              menuName: "商品出库排名报表(天)",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report-out/vsale-orderpicking-workloadstatistics",
            component: () => import("@/views/stat/report-out/vsale-orderpicking-workloadstatistics"),
            name: "vsale-orderpicking-workloadstatistics",
            meta: {
              title: "vsale-orderpicking-workloadstatistics",
              icon: "",
              menu_Id: 1718,
              menuName: "拣货人员工作量统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/report-out/vsale-orderpicking-list",
            component: () => import("@/views/stat/report-out/vsale-orderpicking-list"),
            name: "vsale-orderpicking-list",
            meta: {
              title: "vsale-orderpicking-list",
              icon: "",
              menu_Id: 1719,
              menuName: "拣货人员统计",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/stat-commodity",
            component: () => import("@/views/stat/storage/stat-commodity"),
            name: "stat-storage-stat-commodity",
            meta: {
              title: "stat-storage-stat-commodity",
              icon: "",
              menu_Id: 1840,
              menuName: "商品出库环比",
              isOpenWindow: false
            }
          },
          {
            path: "/stat/storage/stat-commodityon",
            component: () => import("@/views/stat/storage/stat-commodityon"),
            name: "stat-storage-stat-commodityon",
            meta: {
              title: "stat-storage-stat-commodityon",
              icon: "",
              menu_Id: 1841,
              menuName: "商品出库同比",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "erp-count-up",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-count-up",
        alwaysShow: true,
        meta: {
          title: "erp-count-up",
          icon: "",
          menu_Id: 2185,
          menuName: "ERP统计模块",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/sale/order-detail-item",
            component: () => import("@/views/erp/sale/order-detail-item"),
            name: "erp-sale-order-detail-item",
            meta: {
              title: "erp-sale-order-detail-item",
              icon: "",
              menu_Id: 2186,
              menuName: "产品销售明细表",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/sale/purchase-return-detail",
            component: () => import("@/views/erp/sale/purchase-return-detail"),
            name: "erp-sale-purchase-return-detail",
            meta: {
              title: "erp-sale-purchase-return-detail",
              icon: "",
              menu_Id: 2187,
              menuName: "采购退货明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/consignor/assemble-outer-list",
            component: () => import("@/views/storage/consignor/assemble-outer-list"),
            name: "storage-consignor-assemble-outer-list",
            meta: {
              title: "storage-consignor-assemble-outer-list",
              icon: "",
              menu_Id: 2189,
              menuName: "组装出库明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/storage/consignor/assemble-enter-list",
            component: () => import("@/views/storage/consignor/assemble-enter-list"),
            name: "storage-consignor-assemble-enter-list",
            meta: {
              title: "storage-consignor-assemble-enter-list",
              icon: "",
              menu_Id: 2190,
              menuName: "组装入库明细查询",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/clearing-ettlement",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "clearing-ettlement",
    alwaysShow: true,
    meta: {
      title: "clearing-ettlement",
      icon: "menu-icon el-icon-yrt-tongji",
      menu_Id: 2314,
      menuName: "清分结算",
      isOpenWindow: false
    },
    children: [
      {
        path: "/budget",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "budget",
        alwaysShow: true,
        meta: {
          title: "budget",
          icon: "menu-icon el-icon-yrt-tongji",
          menu_Id: 2316,
          menuName: "预算管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/biz/tms-distribution-budget",
            component: () => import("@/views/tms/biz/tms-distribution-budget"),
            name: "tms-biz-tms-distribution-budget",
            meta: {
              title: "tms-biz-tms-distribution-budget",
              icon: "menu-icon el-icon-yrt-tongji",
              menu_Id: 2323,
              menuName: "运输成本预算",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/biz/tms-vehicle-budget",
            component: () => import("@/views/tms/biz/tms-vehicle-budget"),
            name: "tms-biz-tms-vehicle-budget",
            meta: {
              title: "tms-biz-tms-vehicle-budget",
              icon: "menu-icon el-icon-yrt-tongji",
              menu_Id: 2327,
              menuName: "车辆预算管理",
              isOpenWindow: false
            }
          },
          {
            path: "/dashboard/index-trend",
            component: () => import("@/views/dashboard/index-trend"),
            name: "dashboard-index-trend",
            meta: {
              title: "dashboard-index-trend",
              icon: "menu-icon el-icon-yrt-tongji",
              menu_Id: 2345,
              menuName: "业务趋势图",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/operating-decisions",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "operating-decisions",
    alwaysShow: true,
    meta: {
      title: "operating-decisions",
      icon: "menu-icon el-icon-yrt-tongji",
      menu_Id: 2315,
      menuName: "经营决策",
      isOpenWindow: false
    },
    children: [
      {
        path: "/quality ",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "quality ",
        alwaysShow: true,
        meta: {
          title: "quality ",
          icon: "menu-icon el-icon-yrt-tongji",
          menu_Id: 2320,
          menuName: "质量分析",
          isOpenWindow: false
        },
        children: [
          {
            path: "/oa/service/feedback",
            component: () => import("@/views/oa/service/feedback"),
            name: "oa-service-feedback",
            meta: {
              title: "oa-service-feedback",
              icon: "menu-icon el-icon-yrt-tongji",
              menu_Id: 2343,
              menuName: "服务质量反馈",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/finance",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "finance",
    alwaysShow: true,
    meta: {
      title: "finance",
      icon: "menu-icon  el-icon-yrt-youxianzijin",
      menu_Id: 1692,
      menuName: "财务",
      isOpenWindow: false
    },
    children: [
      {
        path: "finance-basic",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "finance-basic",
        alwaysShow: true,
        meta: {
          title: "finance-basic",
          icon: "",
          menu_Id: 1693,
          menuName: "基础信息",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/base/finance-bankaccount",
            component: () => import("@/views/finance/base/finance-bankaccount"),
            name: "finance-base-finance-bankaccount",
            meta: {
              title: "finance-base-finance-bankaccount",
              icon: "",
              menu_Id: 1696,
              menuName: "银行账户",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/biz/finance-bankbiz",
            component: () => import("@/views/finance/biz/finance-bankbiz"),
            name: "finance-biz-finance-bankbiz",
            meta: {
              title: "finance-biz-finance-bankbiz",
              icon: "",
              menu_Id: 1697,
              menuName: "账户间存取款",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/finance-flow",
            component: () => import("@/views/finance/base/finance-flow"),
            name: "finance-base-finance-flow",
            meta: {
              title: "finance-base-finance-flow",
              icon: "",
              menu_Id: 1698,
              menuName: "账户流水",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/biz/finance-accountcheck",
            component: () => import("@/views/finance/biz/finance-accountcheck"),
            name: "finance-biz-finance-accountcheck",
            meta: {
              title: "finance-biz-finance-accountcheck",
              icon: "",
              menu_Id: 1699,
              menuName: "账户调整",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/other-receipt",
            component: () => import("@/views/finance/base/other-receipt"),
            name: "finance-base-other-receipt",
            meta: {
              title: "finance-base-other-receipt",
              icon: "",
              menu_Id: 1930,
              menuName: "其它收款",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/other-payment",
            component: () => import("@/views/finance/base/other-payment"),
            name: "finance-base-other-payment",
            meta: {
              title: "finance-base-other-payment",
              icon: "",
              menu_Id: 1931,
              menuName: "其它付款",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/account-item",
            component: () => import("@/views/finance/base/account-item"),
            name: "finance-base-account-item",
            meta: {
              title: "finance-base-account-item",
              icon: "",
              menu_Id: 1932,
              menuName: "科目管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "finance-invoice",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "finance-invoice",
        alwaysShow: true,
        meta: {
          title: "finance-invoice",
          icon: "",
          menu_Id: 1933,
          menuName: "发票管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/invoice/finance_stay_invoice",
            component: () => import("@/views/finance/invoice/finance_stay_invoice"),
            name: "finance-invoice-finance_stay_invoice",
            meta: {
              title: "finance-invoice-finance_stay_invoice",
              icon: "",
              menu_Id: 1938,
              menuName: "待开票",
              isOpenWindow: false
            }
          },
          {
            path: "/outbound/sale/order-invoice",
            component: () => import("@/views/outbound/sale/order-invoice"),
            name: "outbound-sale-order-invoice",
            meta: {
              title: "outbound-sale-order-invoice",
              icon: "",
              menu_Id: 2197,
              menuName: "待开票列表",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/invoice/finance_invoice",
            component: () => import("@/views/finance/invoice/finance_invoice"),
            name: "finance-invoice-finance_invoice",
            meta: {
              title: "finance-invoice-finance_invoice",
              icon: "",
              menu_Id: 1934,
              menuName: "开票管理",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/invoice/finance_invoice_info",
            component: () => import("@/views/finance/invoice/finance_invoice_info"),
            name: "finance-invoice-finance_invoice_info",
            meta: {
              title: "finance-invoice-finance_invoice_info",
              icon: "",
              menu_Id: 1935,
              menuName: "开票信息",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/invoice/finance_invoice_mailaddress",
            component: () => import("@/views/finance/invoice/finance_invoice_mailaddress"),
            name: "finance-invoice-finance_invoice_mailaddress",
            meta: {
              title: "finance-invoice-finance_invoice_mailaddress",
              icon: "",
              menu_Id: 1936,
              menuName: "邮寄地址",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "finance_receipt",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "finance-receipt",
        alwaysShow: true,
        meta: {
          title: "finance-receipt",
          icon: "",
          menu_Id: 2028,
          menuName: "收票管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/invoice/finance_stay_receipt",
            component: () => import("@/views/finance/invoice/finance_stay_receipt"),
            name: "finance-invoice-finance_stay_receipt",
            meta: {
              title: "finance-invoice-finance_stay_receipt",
              icon: "",
              menu_Id: 2029,
              menuName: "待收票管理",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/invoice/finance_receipt",
            component: () => import("@/views/finance/invoice/finance_receipt"),
            name: "finance-invoice-finance_receipt",
            meta: {
              title: "finance-invoice-finance_receipt",
              icon: "",
              menu_Id: 2030,
              menuName: "收票管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/finance",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-finance-base",
        alwaysShow: true,
        meta: {
          title: "erp-finance-base",
          icon: "",
          menu_Id: 131,
          menuName: "应收应付",
          isOpenWindow: false
        },
        children: [
          {
            path: "/erp/finance/receivable-tob",
            component: () => import("@/views/erp/finance/receivable-tob"),
            name: "erp-finance-receivable-tob",
            meta: {
              title: "erp-finance-receivable-tob",
              icon: "",
              menu_Id: 132,
              menuName: "TOB销售应收",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/finance/receivable-b",
            component: () => import("@/views/erp/finance/receivable-b"),
            name: "erp-finance-receivable-b",
            meta: {
              title: "erp-finance-receivable-b",
              icon: "",
              menu_Id: 1670,
              menuName: "小B销售应收",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/finance/receivable-toc",
            component: () => import("@/views/erp/finance/receivable-toc"),
            name: "erp-finance-receivable-toc",
            meta: {
              title: "erp-finance-receivable-toc",
              icon: "",
              menu_Id: 1672,
              menuName: "TOC销售应收",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/purchase/order-pay",
            component: () => import("@/views/erp/purchase/order-pay"),
            name: "erp-purchase-order-pay",
            meta: {
              title: "erp-purchase-order-pay",
              icon: "",
              menu_Id: 283,
              menuName: "采购应付",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/pay/apply",
            component: () => import("@/views/erp/pay/apply"),
            name: "erp-pay-apply",
            meta: {
              title: "erp-pay-apply",
              icon: "",
              menu_Id: 157,
              menuName: "付款申请单",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/base/payment-payApplyDetail",
            component: () => import("@/views/erp/base/payment-payApplyDetail"),
            name: "erp-base-payment-payApplyDetail",
            meta: {
              title: "erp-base-payment-payApplyDetail",
              icon: "",
              menu_Id: 2039,
              menuName: "采购付款记录",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/apply-put",
            component: () => import("@/views/erp/allocate/apply-put"),
            name: "erp-allocate-apply-put",
            meta: {
              title: "erp-allocate-apply-put",
              icon: "",
              menu_Id: 158,
              menuName: "调拨应收",
              isOpenWindow: false
            }
          },
          {
            path: "/erp/allocate/apply-pay",
            component: () => import("@/views/erp/allocate/apply-pay"),
            name: "erp-allocate-apply-pay",
            meta: {
              title: "erp-allocate-apply-pay",
              icon: "",
              menu_Id: 159,
              menuName: "调拨应付",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "performance",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "performance",
        alwaysShow: true,
        meta: {
          title: "performance",
          icon: "",
          menu_Id: 1618,
          menuName: "效绩考核",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/performance/item",
            component: () => import("@/views/finance/performance/item"),
            name: "finance-performance-item",
            meta: {
              title: "finance-performance-item",
              icon: "",
              menu_Id: 1620,
              menuName: "考核科目",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/performance/template",
            component: () => import("@/views/finance/performance/template"),
            name: "finance-performance-template",
            meta: {
              title: "finance-performance-template",
              icon: "",
              menu_Id: 1621,
              menuName: "考核模板",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/performance/flow",
            component: () => import("@/views/finance/performance/flow"),
            name: "finance-performance-flow",
            meta: {
              title: "finance-performance-flow",
              icon: "",
              menu_Id: 1767,
              menuName: "人员绩效流水",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/oa-scheduling",
            component: () => import("@/views/tms/service/oa-scheduling"),
            name: "tms-service-oa-scheduling",
            meta: {
              title: "tms-service-oa-scheduling",
              icon: "",
              menu_Id: 1817,
              menuName: "考勤管理",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/service/oa-scheduling-derive",
            component: () => import("@/views/tms/service/oa-scheduling-derive"),
            name: "tms-service-oa-scheduling-derive",
            meta: {
              title: "tms-service-oa-scheduling-derive",
              icon: "",
              menu_Id: 2279,
              menuName: "考勤导出",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/performance/vpurchase-sale-order",
            component: () => import("@/views/finance/performance/vpurchase-sale-order"),
            name: "finance-performance-vpurchase-sale-order",
            meta: {
              title: "finance-performance-vpurchase-sale-order",
              icon: "",
              menu_Id: 2292,
              menuName: "装卸签到",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "collection",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "collection",
        alwaysShow: true,
        meta: {
          title: "collection",
          icon: "",
          menu_Id: 172,
          menuName: "仓储费收",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/base/storagefee",
            component: () => import("@/views/finance/base/storagefee"),
            name: "finance-fee-base-storagefee",
            meta: {
              title: "finance-fee-base-storagefee",
              icon: "",
              menu_Id: 1616,
              menuName: "仓储费设置",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/tmsfee",
            component: () => import("@/views/finance/base/tmsfee"),
            name: "finance-base-tmsfee",
            meta: {
              title: "finance-base-tmsfee",
              icon: "",
              menu_Id: 1852,
              menuName: "运输费设置",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/onecharge",
            component: () => import("@/views/finance/base/onecharge"),
            name: "finance-fee-base-onecharge",
            meta: {
              title: "finance-fee-base-onecharge",
              icon: "",
              menu_Id: 1617,
              menuName: "一次性收费项设置",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/feeItem",
            component: () => import("@/views/finance/fee/feeItem"),
            name: "finance-fee-feeItem",
            meta: {
              title: "finance-fee-feeItem",
              icon: "",
              menu_Id: 1638,
              menuName: "科目管理",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/payablebill",
            component: () => import("@/views/finance/fee/payablebill"),
            name: "finance-fee-payablebill",
            meta: {
              title: "finance-fee-payablebill",
              icon: "",
              menu_Id: 1785,
              menuName: "账单结算",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/statement",
            component: () => import("@/views/finance/fee/statement"),
            name: "finance-fee-statement",
            meta: {
              title: "finance-fee-statement",
              icon: "",
              menu_Id: 1786,
              menuName: "对账单查询",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/payablebill-sel",
            component: () => import("@/views/finance/fee/payablebill-sel"),
            name: "finance-fee-payablebill-sel",
            meta: {
              title: "finance-fee-payablebill-sel",
              icon: "",
              menu_Id: 1844,
              menuName: "账单结算报表",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/fee-settlementBill",
            component: () => import("@/views/finance/fee/fee-settlementBill"),
            name: "finance-fee-fee-settlementBill",
            meta: {
              title: "finance-fee-fee-settlementBill",
              icon: "",
              menu_Id: 2032,
              menuName: "账单结算设置",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/payablebill-everyday",
            component: () => import("@/views/finance/fee/payablebill-everyday"),
            name: "finance-fee-payablebill-everyday",
            meta: {
              title: "finance-fee-payablebill-everyday",
              icon: "",
              menu_Id: 251,
              menuName: "每日仓储费查询",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/collection-register",
            component: () => import("@/views/finance/fee/collection-register"),
            name: "finance-fee-collection-register",
            meta: {
              title: "finance-fee-collection-register",
              icon: "",
              menu_Id: 1807,
              menuName: "提货单位收款登记",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/vfee-collection-register-list",
            component: () => import("@/views/finance/fee/vfee-collection-register-list"),
            name: "finance-vfee-collection-register-list",
            meta: {
              title: "finance-vfee-collection-register-list",
              icon: "",
              menu_Id: 1822,
              menuName: "提货单位收款明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/tmspayablebill",
            component: () => import("@/views/finance/fee/tmspayablebill"),
            name: "finance-fee-tmspayablebill",
            meta: {
              title: "finance-fee-tmspayablebill",
              icon: "",
              menu_Id: 1839,
              menuName: "车辆费用账单",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/collection-customer",
            component: () => import("@/views/finance/fee/collection-customer"),
            name: "finance-fee-collection-customer",
            meta: {
              title: "finance-fee-collection-customer",
              icon: "",
              menu_Id: 1808,
              menuName: "客户收款登记",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/fee/vfee-collection-customer-detail",
            component: () => import("@/views/finance/fee/vfee-collection-customer-detail"),
            name: "finance-fee-vfee-collection-customer-detail",
            meta: {
              title: "finance-fee-vfee-collection-customer-detail",
              icon: "",
              menu_Id: 1810,
              menuName: "客户收款登明细查询",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "finance-stat",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "finance-stat",
        alwaysShow: true,
        meta: {
          title: "finance-stat",
          icon: "",
          menu_Id: 1583,
          menuName: "统计报表",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/base/vsale-pay-record",
            component: () => import("@/views/finance/base/vsale-pay-record"),
            name: "finance-base-vsale-pay-record",
            meta: {
              title: "finance-base-vsale-pay-record",
              icon: "",
              menu_Id: 2038,
              menuName: "收款记录",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/erp/finance-m",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "erp-finance-m",
        alwaysShow: true,
        meta: {
          title: "erp-finance-m",
          icon: "",
          menu_Id: 1645,
          menuName: "应收应付",
          isOpenWindow: false
        },
        children: [
          {
            path: "/outbound/sale/order-receivables",
            component: () => import("@/views/outbound/sale/order-receivables"),
            name: "outbound-sale-order-receivables",
            meta: {
              title: "outbound-sale-order-receivables",
              icon: "",
              menu_Id: 1647,
              menuName: "跨组织销售应收",
              isOpenWindow: false
            }
          },
          {
            path: "/inbound/purchase/order-payable",
            component: () => import("@/views/inbound/purchase/order-payable"),
            name: "inbound-purchase-order-payable",
            meta: {
              title: "inbound-purchase-order-payable",
              icon: "",
              menu_Id: 1648,
              menuName: "跨组织销售应付",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "finance-receive-payout",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "finance-receive-payout",
        alwaysShow: true,
        meta: {
          title: "finance-receive-payout",
          icon: "",
          menu_Id: 1694,
          menuName: "收付款",
          isOpenWindow: false
        },
        children: [
          {
            path: "/finance/pay/finance-payout",
            component: () => import("@/views/finance/pay/finance-payout"),
            name: "finance-pay-finance-payout",
            meta: {
              title: "finance-pay-finance-payout",
              icon: "",
              menu_Id: 1703,
              menuName: "付款单",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/receive/finance-receive",
            component: () => import("@/views/finance/receive/finance-receive"),
            name: "finance-receive-finance-receive",
            meta: {
              title: "finance-receive-finance-receive",
              icon: "",
              menu_Id: 1704,
              menuName: "收款单",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/pay/finance-pay-return",
            component: () => import("@/views/finance/pay/finance-pay-return"),
            name: "finance-pay-finance-pay-return",
            meta: {
              title: "finance-pay-finance-pay-return",
              icon: "",
              menu_Id: 2202,
              menuName: "退款单",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "fee",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "fee",
        alwaysShow: true,
        meta: {
          title: "fee",
          icon: "",
          menu_Id: 1769,
          menuName: "TMS绩效考核",
          isOpenWindow: false
        },
        children: [
          {
            path: "/tms/fee/vtms-waybillfee",
            component: () => import("@/views/tms/fee/vtms-waybillfee"),
            name: "tms-fee-vwaybillfee",
            meta: {
              title: "tms-fee-vwaybillfee",
              icon: "",
              menu_Id: 1623,
              menuName: "TMS费用明细查询",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/fee/ratetemplate",
            component: () => import("@/views/tms/fee/ratetemplate"),
            name: "tms-fee-ratetemplate",
            meta: {
              title: "tms-fee-ratetemplate",
              icon: "",
              menu_Id: 1622,
              menuName: "费率模板",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/fee/rateItem",
            component: () => import("@/views/tms/fee/rateItem"),
            name: "tms-fee-rateItem",
            meta: {
              title: "tms-fee-rateItem",
              icon: "",
              menu_Id: 1804,
              menuName: "费率科目",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "asset",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "asset",
    alwaysShow: true,
    meta: {
      title: "asset",
      icon: "menu-icon  el-icon-yrt-zijinlicai",
      menu_Id: 472,
      menuName: "资产管理",
      isOpenWindow: false
    },
    children: [
      {
        path: "asset-base",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "asset-base",
        alwaysShow: true,
        meta: {
          title: "asset-base",
          icon: "",
          menu_Id: 495,
          menuName: "资产管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/asset/base/asset-info",
            component: () => import("@/views/asset/base/asset-info"),
            name: "asset-base-asset-info",
            meta: {
              title: "asset-base-asset-info",
              icon: "",
              menu_Id: 1662,
              menuName: "资产入库",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/base/vasset-track",
            component: () => import("@/views/asset/base/vasset-track"),
            name: "asset-base-vasset-track",
            meta: {
              title: "asset-base-vasset-track",
              icon: "",
              menu_Id: 1695,
              menuName: "资产追踪",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/biz/receive",
            component: () => import("@/views/asset/biz/receive"),
            name: "asset-biz-receive",
            meta: {
              title: "asset-biz-receive",
              icon: "",
              menu_Id: 1708,
              menuName: "资产领用",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/biz/borrow",
            component: () => import("@/views/asset/biz/borrow"),
            name: "asset-biz-borrow",
            meta: {
              title: "asset-biz-borrow",
              icon: "",
              menu_Id: 1709,
              menuName: "资产借用",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/allocate",
            component: () => import("@/views/asset/service/allocate"),
            name: "asset-service-allocate",
            meta: {
              title: "asset-service-allocate",
              icon: "",
              menu_Id: 1710,
              menuName: "资产调拨",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/biz/preserve",
            component: () => import("@/views/asset/biz/preserve"),
            name: "asset-biz-preserve",
            meta: {
              title: "asset-biz-preserve",
              icon: "",
              menu_Id: 1896,
              menuName: "资产保养",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/biz/polling",
            component: () => import("@/views/asset/biz/polling"),
            name: "asset-biz-polling",
            meta: {
              title: "asset-biz-polling",
              icon: "",
              menu_Id: 1905,
              menuName: "资产巡检",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/service",
            component: () => import("@/views/asset/service/service"),
            name: "asset-service-service",
            meta: {
              title: "asset-service-service",
              icon: "",
              menu_Id: 1914,
              menuName: "资产维修",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/stop",
            component: () => import("@/views/asset/service/stop"),
            name: "asset-service-stop",
            meta: {
              title: "asset-service-stop",
              icon: "",
              menu_Id: 1915,
              menuName: "资产报停",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/scrap",
            component: () => import("@/views/asset/service/scrap"),
            name: "asset-service-scrap",
            meta: {
              title: "asset-service-scrap",
              icon: "",
              menu_Id: 1916,
              menuName: "资产报废",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/dispose",
            component: () => import("@/views/asset/service/dispose"),
            name: "asset-service-dispose",
            meta: {
              title: "asset-service-dispose",
              icon: "",
              menu_Id: 1917,
              menuName: "资产处置",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/check",
            component: () => import("@/views/asset/service/check"),
            name: "asset-service-check",
            meta: {
              title: "asset-service-check",
              icon: "",
              menu_Id: 1918,
              menuName: "资产盘点",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/service/transfer",
            component: () => import("@/views/asset/service/transfer"),
            name: "asset-service-transfer",
            meta: {
              title: "asset-service-transfer",
              icon: "",
              menu_Id: 1919,
              menuName: "资产过户",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/base/asset-type",
            component: () => import("@/views/asset/base/asset-type"),
            name: "asset-base-asset-type",
            meta: {
              title: "asset-base-asset-type",
              icon: "",
              menu_Id: 1921,
              menuName: "资产类别",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "asset-search",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "asset-search",
        alwaysShow: true,
        meta: {
          title: "asset-search",
          icon: "",
          menu_Id: 1649,
          menuName: "资产查询",
          isOpenWindow: false
        },
        children: [
          {
            path: "/asset/search/vscrap",
            component: () => import("@/views/asset/search/vscrap"),
            name: "asset-search-vscrap",
            meta: {
              title: "asset-search-vscrap",
              icon: "",
              menu_Id: 1663,
              menuName: "资产报废查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vborrow",
            component: () => import("@/views/asset/search/vborrow"),
            name: "asset-search-vborrow",
            meta: {
              title: "asset-search-vborrow",
              icon: "",
              menu_Id: 1664,
              menuName: "资产借用查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vpolling",
            component: () => import("@/views/asset/search/vpolling"),
            name: "asset-search-vpolling",
            meta: {
              title: "asset-search-vpolling",
              icon: "",
              menu_Id: 1665,
              menuName: "资产巡检查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vpreserve",
            component: () => import("@/views/asset/search/vpreserve"),
            name: "asset-search-vpreserve",
            meta: {
              title: "asset-search-vpreserve",
              icon: "",
              menu_Id: 1666,
              menuName: "资产保养查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vstop",
            component: () => import("@/views/asset/search/vstop"),
            name: "asset-search-vstop",
            meta: {
              title: "asset-search-vstop",
              icon: "",
              menu_Id: 1667,
              menuName: "资产报停查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vdispose",
            component: () => import("@/views/asset/search/vdispose"),
            name: "asset-search-vdispose",
            meta: {
              title: "asset-search-vdispose",
              icon: "",
              menu_Id: 1668,
              menuName: "资产处置查询",
              isOpenWindow: false
            }
          },
          {
            path: "/asset/search/vcheck",
            component: () => import("@/views/asset/search/vcheck"),
            name: "asset-search-vcheck",
            meta: {
              title: "asset-search-vcheck",
              icon: "",
              menu_Id: 1920,
              menuName: "资产盘点查询",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "/sys",
    component: () => import("@/layout/layout-base"),
    redirect: "/",
    name: "sys",
    alwaysShow: true,
    meta: {
      title: "sys",
      icon: "menu-icon el-icon-yrt-shezhi",
      menu_Id: 1,
      menuName: "系统",
      isOpenWindow: false
    },
    children: [
      {
        path: "/sys/sys-config",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "sys-sys-config",
        alwaysShow: true,
        meta: {
          title: "sys-sys-config",
          icon: "",
          menu_Id: 2201,
          menuName: "系统配置",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/base/settings",
            component: () => import("@/views/sys/basic/settings"),
            name: "sys-basic-settings",
            meta: {
              title: "sys-basic-settings",
              icon: "",
              menu_Id: 245,
              menuName: "系统参数设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/basic/code-regular",
            component: () => import("@/views/sys/basic/code-regular"),
            name: "sys-basic-code-regular",
            meta: {
              title: "sys-basic-code-regular",
              icon: "",
              menu_Id: 8,
              menuName: "单据编码规则",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/basic/sys-print-bar-code",
            component: () => import("@/views/sys/basic/sys-print-bar-code"),
            name: "sys-print-bar-code",
            meta: {
              title: "sys-print-bar-code",
              icon: "",
              menu_Id: 127,
              menuName: "条码打印工具",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev-tools/ui-designer-user",
            component: () => import("@/views/sys/dev-tools/ui-designer"),
            name: "sys-dev-tools-ui-designer-user",
            meta: {
              title: "sys-dev-tools-ui-designer-user",
              icon: "",
              menu_Id: 186,
              menuName: "UI设计",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/task-config",
            component: () => import("@/views/basicInfo/base/task-config"),
            name: "basicInfo-base-task-config",
            meta: {
              title: "basicInfo-base-task-config",
              icon: "",
              menu_Id: 2147,
              menuName: "任务配置表",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/task-push",
            component: () => import("@/views/basicInfo/base/task-push"),
            name: "basicInfo-base-task-push",
            meta: {
              title: "basicInfo-base-task-push",
              icon: "",
              menu_Id: 2193,
              menuName: "任务推送",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/task-auto",
            component: () => import("@/views/basicInfo/base/task-auto"),
            name: "basicInfo-base-task-auto",
            meta: {
              title: "basicInfo-base-task-auto",
              icon: "",
              menu_Id: 2293,
              menuName: "任务自动化设置",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/task-log",
            component: () => import("@/views/basicInfo/base/task-log"),
            name: "basicInfo-base-task-log",
            meta: {
              title: "basicInfo-base-task-log",
              icon: "",
              menu_Id: 2177,
              menuName: "任务推送日志",
              isOpenWindow: false
            }
          },
          {
            path: "/finance/base/storagefeehistory",
            component: () => import("@/views/finance/base/storagefeehistory"),
            name: "finance-fee-base-storagefeehistory",
            meta: {
              title: "finance-fee-base-storagefeehistory",
              icon: "",
              menu_Id: 1854,
              menuName: "数据修改日志",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/mongodb-log",
            component: () => import("@/views/sys/dev/mongodb-log"),
            name: "sys-dev-mongodb-log",
            meta: {
              title: "sys-dev-mongodb-log",
              icon: "",
              menu_Id: 1713,
              menuName: "mongoDB日志",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/flow/flow-data",
            component: () => import("@/views/sys/flow/flow-data"),
            name: "sys-flow-flow-data",
            meta: {
              title: "sys-flow-flow-data",
              icon: "",
              menu_Id: 2235,
              menuName: "待办事项",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/flow/flowdata-wechat",
            component: () => import("@/views/sys/flow/flowdata-wechat"),
            name: "sys-flow-flowdata-wechat",
            meta: {
              title: "sys-flow-flowdata-wechat",
              icon: "",
              menu_Id: 2256,
              menuName: "微信消息配置",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "/sys/basic-info",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "sys-basic-info",
        alwaysShow: true,
        meta: {
          title: "sys-basic-info",
          icon: "",
          menu_Id: 253,
          menuName: "基础信息",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/basic/expresscorp",
            component: () => import("@/views/sys/basic/expresscorp"),
            name: "sys-basic-expresscorp",
            meta: {
              title: "sys-basic-expresscorp",
              icon: "",
              menu_Id: 232,
              menuName: "快递管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/basic/consignor",
            component: () => import("@/views/sys/basic/consignor"),
            name: "sys-basic-consignor",
            meta: {
              title: "sys-basic-consignor",
              icon: "",
              menu_Id: 501,
              menuName: "货主信息",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/port",
            component: () => import("@/views/tms/basic/port"),
            name: "tms-basic-port",
            meta: {
              title: "tms-basic-port",
              icon: "",
              menu_Id: 1740,
              menuName: "口岸管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/basic/lang",
            component: () => import("@/views/sys/basic/lang"),
            name: "sys-basic-lang",
            meta: {
              title: "sys-basic-lang",
              icon: "",
              menu_Id: 1788,
              menuName: "语言包设置",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/account/address",
            component: () => import("@/views/tms/account/address"),
            name: "tms-account-address",
            meta: {
              title: "tms-account-address",
              icon: "",
              menu_Id: 238,
              menuName: "货主收货地址",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/picker",
            component: () => import("@/views/basicInfo/base/picker"),
            name: "basicInfo-base-picker",
            meta: {
              title: "basicInfo-base-picker",
              icon: "",
              menu_Id: 1588,
              menuName: "提货单位",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/user-content",
            component: () => import("@/views/basicInfo/base/user-content"),
            name: "basicInfo-base-user-content",
            meta: {
              title: "basicInfo-base-user-content",
              icon: "",
              menu_Id: 1702,
              menuName: "消息管理",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/base/template",
            component: () => import("@/views/basicInfo/base/template"),
            name: "basicInfo-base-template",
            meta: {
              title: "basicInfo-base-template",
              icon: "",
              menu_Id: 1707,
              menuName: "消息模板设置",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/equip/base-equip",
            component: () => import("@/views/basicInfo/equip/base-equip"),
            name: "basicInfo-equip-base-equip",
            meta: {
              title: "basicInfo-equip-base-equip",
              icon: "",
              menu_Id: 2218,
              menuName: "设备基础信息",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/equip/base-equipWork",
            component: () => import("@/views/basicInfo/equip/base-equipWork"),
            name: "basicInfo-equip-base-equipWork",
            meta: {
              title: "basicInfo-equip-base-equipWork",
              icon: "",
              menu_Id: 2219,
              menuName: "设备监控数据",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "product",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "product",
        alwaysShow: true,
        meta: {
          title: "product",
          icon: "",
          menu_Id: 312,
          menuName: "商品信息维护",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/product/product-info",
            component: () => import("@/views/sys/product/product-info"),
            name: "sys-product-product-info",
            meta: {
              title: "sys-product-product-info",
              icon: "",
              menu_Id: 254,
              menuName: "商品信息",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-mortgage",
            component: () => import("@/views/sys/product/product-mortgage"),
            name: "sys-product-product-mortgage",
            meta: {
              title: "sys-product-product-mortgage",
              icon: "",
              menu_Id: 2277,
              menuName: "货主商品抵押",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-info-sku",
            component: () => import("@/views/sys/product/product-info-sku"),
            name: "sys-product-product-info-sku",
            meta: {
              title: "sys-product-product-info-sku",
              icon: "",
              menu_Id: 1511,
              menuName: "SKU商品信息",
              isOpenWindow: false
            }
          },
          {
            path: "/tms/basic/product-info-port",
            component: () => import("@/views/tms/basic/product-info-port"),
            name: "tms-basic-product-info-port",
            meta: {
              title: "tms-basic-product-info-port",
              icon: "",
              menu_Id: 201,
              menuName: "口岸商品",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-set",
            component: () => import("@/views/sys/product/product-set"),
            name: "/sys/product/product-set",
            meta: {
              title: "/sys/product/product-set",
              icon: "",
              menu_Id: 378,
              menuName: "套装组合设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/brand",
            component: () => import("@/views/sys/product/brand"),
            name: "sys-product-brand",
            meta: {
              title: "sys-product-brand",
              icon: "",
              menu_Id: 260,
              menuName: "品牌管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/provider",
            component: () => import("@/views/sys/product/provider"),
            name: "sys-product-provider",
            meta: {
              title: "sys-product-provider",
              icon: "",
              menu_Id: 256,
              menuName: "供应商信息",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/type",
            component: () => import("@/views/sys/product/type"),
            name: "sys-product-type",
            meta: {
              title: "sys-product-type",
              icon: "",
              menu_Id: 261,
              menuName: "商品类别",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/price-set",
            component: () => import("@/views/sys/product/price-set"),
            name: "sys-product-price-set",
            meta: {
              title: "sys-product-price-set",
              icon: "",
              menu_Id: 409,
              menuName: "商品阶梯价维护",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-price",
            component: () => import("@/views/sys/product/product-price"),
            name: "sys-product-product-price",
            meta: {
              title: "sys-product-product-price",
              icon: "",
              menu_Id: 1856,
              menuName: "商品价格设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/product-model",
            component: () => import("@/views/sys/dev/param-type/product-model"),
            name: "sys-dev-param-type-product-model",
            meta: {
              title: "sys-dev-param-type-product-model",
              icon: "",
              menu_Id: 1646,
              menuName: "产品型号维护",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/product-version",
            component: () => import("@/views/sys/dev/param-type/product-version"),
            name: "sys-dev-param-type-product-version",
            meta: {
              title: "sys-dev-param-type-product-version",
              icon: "",
              menu_Id: 2182,
              menuName: "商品版本维护",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/product-security",
            component: () => import("@/views/sys/product/product-security"),
            name: "sys-product-product-security",
            meta: {
              title: "sys-product-product-security",
              icon: "",
              menu_Id: 2309,
              menuName: "防伪标签管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/product/vproduct-security-detail",
            component: () => import("@/views/sys/product/vproduct-security-detail"),
            name: "sys-product-vproduct-security-detail",
            meta: {
              title: "sys-product-vproduct-security-detail",
              icon: "",
              menu_Id: 2311,
              menuName: "防伪码列表",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "logistics",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "logistics",
        alwaysShow: true,
        meta: {
          title: "logistics",
          icon: "",
          menu_Id: 1857,
          menuName: "物流管理",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/basic/logistics",
            component: () => import("@/views/sys/basic/logistics"),
            name: "sys-basic-logistics",
            meta: {
              title: "sys-basic-logistics",
              icon: "",
              menu_Id: 1858,
              menuName: "物流公司",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/param-type-origin",
            component: () => import("@views/sys/dev/param-type/param-type-origin"),
            name: "sys-dev-param-type-param-type-origin",
            meta: {
              title: "sys-dev-param-type-param-type-origin",
              icon: "",
              menu_Id: 1712,
              menuName: "出发地管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/param-type-destination",
            component: () => import("@views/sys/dev/param-type/param-type-destination"),
            name: "sys-dev-param-type-param-type-destination",
            meta: {
              title: "sys-dev-param-type-param-type-destination",
              icon: "",
              menu_Id: 1711,
              menuName: "目的地管理",
              isOpenWindow: false
            }
          },
          {
            path: "/base/freight-first",
            component: () => import("@/views/base/freight-first"),
            name: "base-freight-first",
            meta: {
              title: "base-freight-first",
              icon: "",
              menu_Id: 1859,
              menuName: "运价模板（头程）",
              isOpenWindow: false
            }
          },
          {
            path: "/base/freight-tail",
            component: () => import("@/views/base/freight-tail"),
            name: "base-freight-tail",
            meta: {
              title: "base-freight-tail",
              icon: "",
              menu_Id: 1860,
              menuName: "运价模板（尾程）",
              isOpenWindow: false
            }
          },
          {
            path: "/basicInfo/tms/base-carrier-freight",
            component: () => import("@/views/basicInfo/tms/base-carrier-freight"),
            name: "basicInfo-tms-base-carrier-freight",
            meta: {
              title: "basicInfo-tms-base-carrier-freight",
              icon: "",
              menu_Id: 1908,
              menuName: "货代管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type/vehicle-type",
            component: () => import("@/views/sys/dev/param-type/vehicle-type"),
            name: "sys-dev-param-type-vehicle-type",
            meta: {
              title: "sys-dev-param-type-vehicle-type",
              icon: "",
              menu_Id: 2183,
              menuName: "车型管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "cms",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "cms",
        alwaysShow: true,
        meta: {
          title: "cms",
          icon: "",
          menu_Id: 236,
          menuName: "CMS",
          isOpenWindow: false
        },
        children: [
          {
            path: "/sys/cms/module",
            component: () => import("@/views/sys/cms/module"),
            name: "sys-cms-module",
            meta: {
              title: "sys-cms-module",
              icon: "",
              menu_Id: 300,
              menuName: "模块管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/cms/content",
            component: () => import("@/views/sys/cms/content"),
            name: "sys-cms-content",
            meta: {
              title: "sys-cms-content",
              icon: "",
              menu_Id: 237,
              menuName: "模块内容",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "message-queue",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "message-queue",
        alwaysShow: true,
        meta: {
          title: "message-queue",
          icon: "menu-icon el-icon-yrt-wuliuguanliicon",
          menu_Id: 1849,
          menuName: "消息队列",
          isOpenWindow: false
        },
        children: [
          {
            path: "/wcs/task/queue",
            component: () => import("@/views/wcs/task/queue"),
            name: "wcs-task-queue",
            meta: {
              title: "wcs-task-queue",
              icon: "menu-icon el-icon-yrt-wuliuguanliicon",
              menu_Id: 1850,
              menuName: "任务管理",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "permission",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "permission",
        alwaysShow: true,
        meta: {
          title: "permission",
          icon: "",
          menu_Id: 2,
          menuName: "用户及权限",
          isOpenWindow: false
        },
        children: [
          {
            path: "user",
            component: () => import("@/views/sys/permission/user"),
            name: "sys-permission-user",
            meta: {
              title: "sys-permission-user",
              icon: "",
              menu_Id: 4,
              menuName: "用户管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/permission/user-consignor",
            component: () => import("@/views/sys/permission/user-consignor"),
            name: "sys-permission-user-consignor",
            meta: {
              title: "sys-permission-user-consignor",
              icon: "",
              menu_Id: 2275,
              menuName: "销售员",
              isOpenWindow: false
            }
          },
          {
            path: "dept",
            component: () => import("@/views/sys/permission/dept"),
            name: "sys-permission-dept",
            meta: {
              title: "sys-permission-dept",
              icon: "",
              menu_Id: 285,
              menuName: "部门管理",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/permission/kingdee-dept",
            component: () => import("@/views/sys/permission/kingdee-dept"),
            name: "sys-permission-kingdee-dept",
            meta: {
              title: "sys-permission-kingdee-dept",
              icon: "",
              menu_Id: 2278,
              menuName: "金蝶部门",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/permission/role",
            component: () => import("@/views/sys/permission/role"),
            name: "sys-permission-role",
            meta: {
              title: "sys-permission-role",
              icon: "",
              menu_Id: 18,
              menuName: "角色管理",
              isOpenWindow: false
            }
          },
          {
            path: "role-auth",
            component: () => import("@/views/sys/permission/role-auth"),
            name: "sys-permission-role-auth",
            meta: {
              title: "sys-permission-role-auth",
              icon: "",
              menu_Id: 5,
              menuName: "角色权限设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/menu-auth-module",
            component: () => import("@/views/sys/dev/menu-auth-module"),
            name: "sys-dev-menu-auth-module",
            meta: {
              title: "sys-dev-menu-auth-module",
              icon: "",
              menu_Id: 1524,
              menuName: "数据权限模块",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/permission/user-log",
            component: () => import("@/views/sys/permission/user-log"),
            name: "sys-permission-user-log",
            meta: {
              title: "sys-permission-user-log",
              icon: "",
              menu_Id: 188,
              menuName: "用户日志",
              isOpenWindow: false
            }
          }
        ]
      },
      {
        path: "dev-tools",
        component: () => import("@/layout/layout-base"),
        redirect: "/",
        name: "dev-tools",
        alwaysShow: true,
        meta: {
          title: "dev-tools",
          icon: "",
          menu_Id: 3,
          menuName: "开发工具",
          isOpenWindow: false
        },
        children: [
          {
            path: "ui-designer",
            component: () => import("@/views/sys/dev-tools/ui-designer"),
            name: "sys-dev-tools-ui-designer",
            meta: {
              title: "sys-dev-tools-ui-designer",
              icon: "",
              menu_Id: 486,
              menuName: "VueUI设计器",
              isOpenWindow: false
            }
          },
          {
            path: "print-designer",
            component: () => import("@/views/sys/dev-tools/print-designer"),
            name: "sys-dev-tools-print-designer",
            meta: {
              title: "sys-dev-tools-print-designer",
              icon: "",
              menu_Id: 490,
              menuName: "Vue打印设计器",
              isOpenWindow: false
            }
          },
          {
            path: "layout-designer",
            component: () => import("@/views/sys/dev-tools/layout-designer"),
            name: "sys-dev-tools-layout-designer",
            meta: {
              title: "sys-dev-tools-layout-designer",
              icon: "",
              menu_Id: 342,
              menuName: "首页布局设计器",
              isOpenWindow: false
            }
          },
          {
            path: "flow-designer",
            component: () => import("@/views/sys/dev-tools/flow-designer"),
            name: "sys-dev-tools-flow-designer",
            meta: {
              title: "sys-dev-tools-flow-designer",
              icon: "",
              menu_Id: 1576,
              menuName: "流程设计器",
              isOpenWindow: false
            }
          },
          {
            path: "bi-designer",
            component: () => import("@/views/sys/dev-tools/bi-designer"),
            name: "sys-dev-tools-bi-designer",
            meta: {
              title: "sys-dev-tools-bi-designer",
              icon: "",
              menu_Id: 1577,
              menuName: "报表设计器",
              isOpenWindow: false
            }
          },
          {
            path: "/icons/font",
            component: () => import("@/views/icons/font"),
            name: "icons-font",
            meta: {
              title: "icons-font",
              icon: "",
              menu_Id: 488,
              menuName: "字体图标",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/mvctableinfo",
            component: () => import("@/views/sys/dev/mvctableinfo"),
            name: "sys-dev-mvctableinfo",
            meta: {
              title: "sys-dev-mvctableinfo",
              icon: "",
              menu_Id: 6,
              menuName: "Mvc开发中心",
              isOpenWindow: false
            }
          },
          {
            path: "/icons/svg",
            component: () => import("@/views/icons/svg"),
            name: "icons-svg",
            meta: {
              title: "icons-svg",
              icon: "",
              menu_Id: 487,
              menuName: "SVG图标",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/code-regular",
            component: () => import("@/views/sys/dev/code-regular"),
            name: "sys-dev-code-regular",
            meta: {
              title: "sys-dev-code-regular",
              icon: "",
              menu_Id: 7,
              menuName: "默认单据编码",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/menu",
            component: () => import("@/views/sys/dev/menu"),
            name: "sys-dev-menu",
            meta: {
              title: "sys-dev-menu",
              icon: "",
              menu_Id: 496,
              menuName: "Vue菜单设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/menu-app",
            component: () => import("@/views/sys/dev/menu-app"),
            name: "sys-dev-menu-app",
            meta: {
              title: "sys-dev-menu-app",
              icon: "",
              menu_Id: 1723,
              menuName: "APP菜单设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/import",
            component: () => import("@/views/sys/dev/import"),
            name: "sys-dev-import",
            meta: {
              title: "sys-dev-import",
              icon: "",
              menu_Id: 319,
              menuName: "导入设置",
              isOpenWindow: false
            }
          },
          {
            path: "export-setting",
            component: () => import("@/views/sys/basic/export-setting"),
            name: "sys-basic-export-setting",
            meta: {
              title: "sys-basic-export-setting",
              icon: "",
              menu_Id: 384,
              menuName: "导出设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/basicInfo",
            component: () => import("@/views/sys/dev/basicInfo"),
            name: "sys-dev-basicInfo",
            meta: {
              title: "sys-dev-basicInfo",
              icon: "",
              menu_Id: 1669,
              menuName: "系统设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/dropdown",
            component: () => import("@/views/sys/dev/dropdown"),
            name: "sys-dev-dropdown",
            meta: {
              title: "sys-dev-dropdown",
              icon: "",
              menu_Id: 10,
              menuName: "下拉框设置",
              isOpenWindow: false
            }
          },
          {
            path: "/sys/dev/param-type",
            component: () => import("@/views/sys/dev/param-type"),
            name: "sys-dev-param-type",
            meta: {
              title: "sys-dev-param-type",
              icon: "",
              menu_Id: 11,
              menuName: "下拉框值设置",
              isOpenWindow: false
            }
          }
        ]
      }
    ]
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true
  }
];
